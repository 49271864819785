import React from "react";

export const DialogSeparator = React.memo(DialogSeparatorComponent);

function DialogSeparatorComponent({ className = "my-4" }: DialogSeparatorProps) {
  return <hr className={`h-0 border-t border-sand ${className}`} />;
}

interface DialogSeparatorProps {
  className?: string;
}
