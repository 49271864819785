import React from "react";

export function ViewRecipeIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M11.521 16.4171C12.401 16.4171 13.221 16.1571 13.911 15.7171L16.351 18.1571L17.771 16.7371L15.331 14.3071C15.771 13.6071 16.031 12.7971 16.031 11.9171C16.031 9.42711 14.021 7.41711 11.531 7.41711C9.04101 7.41711 7.03101 9.42711 7.03101 11.9171C7.03101 14.4071 9.04101 16.4171 11.521 16.4171ZM11.531 9.41711C12.911 9.41711 14.031 10.5371 14.031 11.9171C14.031 13.2971 12.911 14.4171 11.531 14.4171C10.151 14.4171 9.03101 13.2971 9.03101 11.9171C9.03101 10.5371 10.151 9.41711 11.531 9.41711ZM20.031 4.41711H4.03101C2.93101 4.41711 2.03101 5.31711 2.03101 6.41711V18.4171C2.03101 19.5171 2.93101 20.4171 4.03101 20.4171H20.031C21.131 20.4171 22.031 19.5171 22.031 18.4171V6.41711C22.031 5.31711 21.131 4.41711 20.031 4.41711ZM20.031 18.4171H4.03101V6.41711H20.031V18.4171Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
