import React from "react";
import classNames from "classnames";

export const Button = React.memo(ButtonComponent);
export const IconButton = React.memo(IconButtonComponent);

function ButtonComponent({ kind = "base", disabled, className: extraClassName = "", ...props }: ButtonProps) {
  const className = classNames(buttonKindStyles[kind], extraClassName);
  return <button disabled={disabled} className={className} {...props} />;
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: ButtonKind;
}

function IconButtonComponent({
  kind = "base",
  spacing = "normal",
  disabled,
  className: extraClassName = "",
  ...props
}: IconButtonProps) {
  const className = classNames(iconButtonKindStyles[kind], iconButtonSpacingStyles[spacing], extraClassName);
  return <button disabled={disabled} className={className} {...props} />;
}

interface IconButtonProps extends ButtonProps {
  spacing?: ButtonSpacing;
}

type ButtonKind = "base" | "default" | "default-transparent" | "primary" | "primary-inverted" | "secondary";

type ButtonSpacing = "tight" | "normal" | "large";

const commonDisabledStyles = "disabled:bg-grey disabled:text-lighter-grey disabled:cursor-not-allowed";
const buttonCommonStyles = `rounded-full px-4 py-2 text-base font-medium ${commonDisabledStyles}`;
const iconButtonCommonStyles = `rounded-full flex items-center fill-current ${commonDisabledStyles}`;

const buttonKindStyles: Record<ButtonKind, string> = {
  base: "",
  default: `${buttonCommonStyles} bg-white text-black hover:bg-black hover:bg-opacity-5`,
  "default-transparent": `${buttonCommonStyles} text-black hover:bg-black hover:bg-opacity-5`,
  primary: `${buttonCommonStyles} bg-green bg-clip-padding border-4 border-transparent text-white hover:bg-light-green ring-green focus:ring-1`,
  "primary-inverted": `${buttonCommonStyles} bg-white bg-clip-padding border-4 border-transparent text-green hover:bg-lightest-grey ring-green focus:ring-1`,
  secondary: `${buttonCommonStyles} bg-sand text-black`,
};

const iconButtonKindStyles: Record<ButtonKind, string> = {
  base: "",
  default: `${iconButtonCommonStyles} bg-white text-black hover:bg-black hover:bg-opacity-5`,
  "default-transparent": `${iconButtonCommonStyles} text-black hover:bg-black hover:bg-opacity-5`,
  primary: `${iconButtonCommonStyles} bg-green text-white hover:bg-light-green`,
  "primary-inverted": `${iconButtonCommonStyles} bg-white text-green hover:bg-lightest-grey`,
  secondary: `${iconButtonCommonStyles} bg-sand text-black`,
};

const iconButtonSpacingStyles: Record<ButtonSpacing, string> = {
  tight: "p-0",
  normal: "p-1",
  large: "p-2",
};
