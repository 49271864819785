import { ApolloError } from "@apollo/client";
import { useMachine } from "@xstate/react";
import { useContext, useEffect, useState } from "react";
import { assign, createMachine, DoneInvokeEvent, send } from "xstate";
import { useLink, useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { MyMealPlansPage, RecipePage } from "../../../pages";
import { DomainKind } from "../../../types/graphql-global-types";
import { Locale } from "../../../types/Locale";
import {
  MealPlanner_ActivateMealPlanMutation,
  MealPlanner_ActivateMealPlanMutationVariables,
} from "../../../types/MealPlanner_ActivateMealPlanMutation";
import {
  MealPlanner_AddRecipeToMealPlanMutation,
  MealPlanner_AddRecipeToMealPlanMutationVariables,
} from "../../../types/MealPlanner_AddRecipeToMealPlanMutation";
import {
  MealPlanner_CopyMealPlanMutation,
  MealPlanner_CopyMealPlanMutationVariables,
} from "../../../types/MealPlanner_CopyMealPlanMutation";
import { MealPlanner_DeactivateMealPlanMutation } from "../../../types/MealPlanner_DeactivateMealPlanMutation";
import {
  MealPlanner_DeleteMealPlanMutation,
  MealPlanner_DeleteMealPlanMutationVariables,
} from "../../../types/MealPlanner_DeleteMealPlanMutation";
import {
  MealPlanner_EditMealPlanMutation,
  MealPlanner_EditMealPlanMutationVariables,
} from "../../../types/MealPlanner_EditMealPlanMutation";
import {
  MealPlanner_GetFavoriteRecipesQuery,
  MealPlanner_GetFavoriteRecipesQueryVariables,
} from "../../../types/MealPlanner_GetFavoriteRecipesQuery";
import {
  MealPlanner_GetMealPlanQuery,
  MealPlanner_GetMealPlanQueryVariables,
} from "../../../types/MealPlanner_GetMealPlanQuery";
import {
  MealPlanner_GetRecipeQuery,
  MealPlanner_GetRecipeQueryVariables,
  MealPlanner_GetRecipeQuery_recipe,
} from "../../../types/MealPlanner_GetRecipeQuery";
import {
  MealPlanner_GetRecipeSuggestionQuery,
  MealPlanner_GetRecipeSuggestionQueryVariables,
  MealPlanner_GetRecipeSuggestionQuery_recipeSuggestions,
} from "../../../types/MealPlanner_GetRecipeSuggestionQuery";
import {
  MealPlanner_GetTagsQuery,
  MealPlanner_GetTagsQuery_listTags_tags,
} from "../../../types/MealPlanner_GetTagsQuery";
import { MealPlanner_MealPlan } from "../../../types/MealPlanner_MealPlan";
import { MealPlanner_MealPlanUpdatedSchedule } from "../../../types/MealPlanner_MealPlanUpdatedSchedule";
import { MealPlanner_MealPlanUpdatedServings } from "../../../types/MealPlanner_MealPlanUpdatedServings";
import { MealPlanner_MealPlanUpdatedTitleDescription } from "../../../types/MealPlanner_MealPlanUpdatedTitleDescription";
import { MealPlanner_MergedIngredient } from "../../../types/MealPlanner_MergedIngredient";
import {
  MealPlanner_MergeIngredientsQuery,
  MealPlanner_MergeIngredientsQueryVariables,
} from "../../../types/MealPlanner_MergeIngredientsQuery";
import { MealPlanner_RecipeWithServings } from "../../../types/MealPlanner_RecipeWithServings";
import {
  MealPlanner_RemoveRecipeFromMealPlanMutation,
  MealPlanner_RemoveRecipeFromMealPlanMutationVariables,
} from "../../../types/MealPlanner_RemoveRecipeFromMealPlanMutation";

import {
  MealPlanner_ReplaceRecipeFromMealPlanMutation,
  MealPlanner_ReplaceRecipeFromMealPlanMutationVariables,
} from "../../../types/MealPlanner_ReplaceRecipeFromMealPlanMutation";
import { MealPlanner_Recipe } from "../../../types/MealPlanner_Recipe";
import {
  MealPlanner_SearchRecipesQuery,
  MealPlanner_SearchRecipesQueryVariables,
  MealPlanner_SearchRecipesQuery_listRecipes,
} from "../../../types/MealPlanner_SearchRecipesQuery";
import {
  MealPlanner_SetFavoriteRecipeMutation,
  MealPlanner_SetFavoriteRecipeMutationVariables,
} from "../../../types/MealPlanner_SetFavoriteRecipeMutation";
import {
  MealPlanner_SetMealPlanSharedFlagMutation,
  MealPlanner_SetMealPlanSharedFlagMutationVariables,
} from "../../../types/MealPlanner_SetMealPlanSharedFlagMutation";
import {
  MealPlanner_UpdateMealPlanDayServingsMutation,
  MealPlanner_UpdateMealPlanDayServingsMutationVariables,
} from "../../../types/MealPlanner_UpdateMealPlanDayServingsMutation";
import {
  MealPlanner_UpdateMealPlanRecipeServingsMutation,
  MealPlanner_UpdateMealPlanRecipeServingsMutationVariables,
} from "../../../types/MealPlanner_UpdateMealPlanRecipeServingsMutation";
import {
  MealPlanner_UpdateMealPlanServingsMutation,
  MealPlanner_UpdateMealPlanServingsMutationVariables,
} from "../../../types/MealPlanner_UpdateMealPlanServingsMutation";
import { client } from "../../../utils/apollo-client";
import MealPlanner_ActivateMealPlan from "./api/ActivateMealPlanMutation.graphql";
import MealPlanner_AddRecipeToMealPlan from "./api/AddRecipeToMealPlanMutation.graphql";
import MealPlanner_CopyMealPlan from "./api/CopyMealPlanMutation.graphql";
import MealPlanner_DeactivateMealPlan from "./api/DeactivateMealPlanMutation.graphql";
import MealPlanner_DeleteMealPlan from "./api/DeleteMealPlanMutation.graphql";
import MealPlanner_EditMealPlan from "./api/EditMealPlanMutation.graphql";
import MealPlanner_GetFavoriteRecipes from "./api/GetFavoriteRecipesQuery.graphql";
import MealPlanner_GetMealPlan from "./api/GetMealPlanQuery.graphql";
import MealPlanner_GetRecipe from "./api/GetRecipeQuery.graphql";
import MealPlanner_GetRecipeSuggestion from "./api/GetRecipeSuggestion.graphql";
import MealPlanner_GetTags from "./api/GetTagsQuery.graphql";
import MealPlanner_MergeIngredients from "./api/MergeIngredientsQuery.graphql";
import MealPlanner_RemoveRecipeFromMealPlan from "./api/RemoveRecipeFromMealPlanMutation.graphql";
import MealPlanner_ReplaceRecipeFromMealPlan from "./api/ReplaceRecipeFromMealPlanMutation.graphql";
import MealPlanner_SearchRecipes from "./api/SearchRecipesQuery.graphql";
import MealPlanner_SetFavoriteRecipe from "./api/SetFavoriteRecipeMutation.graphql";
import MealPlanner_SetMealPlanSharedFlag from "./api/SetMealPlanSharedFlagMutation.graphql";
import MealPlanner_UpdateMealPlanDayServings from "./api/UpdateMealPlanDayServingsMutation.graphql";
import MealPlanner_UpdateMealPlanRecipeServings from "./api/UpdateMealPlanRecipeServingsMutation.graphql";
import MealPlanner_UpdateMealPlanServings from "./api/UpdateMealPlanServingsMutation.graphql";
import { getWeekdayAndDay } from "./components/MealPlanner/utils";
import {
  AddBreakfastEvent,
  AddDinnerEvent,
  AddLunchEvent,
  AddRecipeEvent,
  AddRecipesToShoppingListEvent,
  AddTagEvent,
  ChangeCookTimeLimitEvent,
  ConfirmMealPlanCopyEvent,
  ConfirmMealPlanEditEvent,
  Context,
  CopyToClipboardEvent,
  DDError,
  Events,
  FirebaseShoppingListPayload,
  FirebaseShoppingListRecipe,
  MealPlanMachineSend,
  MealPlannerConfirmationMessage,
  MealPlannerErrorCode,
  PrintTarget,
  RemoveCooktimeRangeTagEvent,
  RemoveRecipeEvent,
  RemoveSatietyRangeTagEvent,
  RemoveTagEvent,
  SearchInputChangeEvent,
  SelectCopyToDayEvent,
  SelectCopyToMealEvent,
  SetIngredientUnitEvent,
  SetSatietyScoreRangeEvent,
  ShareContentEvent,
  ShowRecipeCardNutritionalInforEvent,
  TagChangeEvent,
  ToggleDayMenuEvent,
  ToggleIngredientUnitEvent,
  ToggleRecipeCardMenuEvent,
  ToggleRecipeMenuEvent,
  UpdateMealPlanDayServingsEvent,
  UpdateMealPlanRecipeServingsEvent,
  UpdateMealPlanServingsEvent,
  UserStateChangeEvent,
} from "./types";

import {
  formatShoppingListDataForMealPlan,
  getMealPlanDay,
  getRecipeByIndex,
  isMealPlanCopiable,
  isMealPlanDayServingsUniform,
  isMealPlanModifiable,
  isMealPlanServingsUniform,
} from "./utils";
import { emptyShoppingList } from "../../shopping-list/utils";
import { MealPlanPage } from "../pages";
import { IngredientUnit, MeasurementUnit } from "../../../utils/units";
import { useMeasurementUnit } from "../../../hooks/useMeasurementUnit/useMeasurementUnit";
import { sendEvent } from "../../../utils/gtm";
import { sha256 } from "js-sha256";
import { collection, doc, DocumentData, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import firebaseApp from "../../../utils/firebase";
import { getMinMaxValue } from "../../../utils/utils";

export function useMealPlanMachine(slug: string, isEditRequested: boolean) {
  const navigate = useNavigate();
  const link = useLink();
  const { locale } = useContext(pageContext);

  const canShare = Boolean(typeof navigator !== "undefined" && navigator.share);
  const canCopyToClipboard = Boolean(typeof navigator !== "undefined" && navigator.clipboard?.writeText);

  const machine = useMachine(machineSchema, {
    context: {
      slug,
      navigate,
      link,
      canShare,
      canCopyToClipboard,
      isEditRequested,
      locale,
    },
    devTools: true,
  });

  useUserStateNotifier(machine[1]);
  useIngredientUnitNotifier(machine[1]);

  return machine;
}

function useUserStateNotifier(send: MealPlanMachineSend) {
  const { loading: isUserLoading, premium: isPremium, user, firebaseUserId } = useContext(LoginContext);

  useEffect(() => {
    if (!isUserLoading) {
      send({
        type: "USER_STATE_CHANGE",
        id: user?.userId,
        firebaseUserId: firebaseUserId,
        premium: isPremium,
      });
    }
  }, [isPremium, isUserLoading, user, firebaseUserId, send]);
}

function useIngredientUnitNotifier(send: MealPlanMachineSend) {
  const { locale } = useContext(pageContext);
  const { measurementUnit } = useMeasurementUnit();
  const [ingredientUnit, setIngredientUnit] = useState<IngredientUnit>();

  useEffect(() => {
    if (locale === Locale.SV) {
      setIngredientUnit(IngredientUnit.SWEDISH);
      send({
        type: "SET_INGREDIENT_UNIT",
        ingredientUnit: IngredientUnit.SWEDISH,
      });
    } else if (measurementUnit === MeasurementUnit.IMPERIAL) {
      setIngredientUnit(IngredientUnit.IMPERIAL);
      send({
        type: "SET_INGREDIENT_UNIT",
        ingredientUnit: IngredientUnit.IMPERIAL,
      });
    } else {
      setIngredientUnit(IngredientUnit.METRIC);
      send({
        type: "SET_INGREDIENT_UNIT",
        ingredientUnit: IngredientUnit.METRIC,
      });
    }
  }, [locale, measurementUnit, send]);

  return { ingredientUnit };
}

const machineSchema = createMachine<Context, Events>(
  {
    id: "mealPlan",
    initial: "initializing",
    context: {
      slug: "",
      isPremium: false,
      isMenuOpen: false,
      navigate: () => {},
      link: () => "",
      searchInput: "",
      tagInput: [],
      allTags: [],
      chosenTags: [],
      searchResult: [],
      ingredientUnit: IngredientUnit.METRIC,
      canShare: false,
      canCopyToClipboard: false,
      favoriteRecipes: [],
      isEditRequested: false,
      locale: Locale.EN,
      allowedServings: [1, 2, 3, 4, 5, 6, 7, 8],
      pageInputForSearchRecipes: 1,
      showLoadMoreButton: true,
      recipeSuggestion: [],
      selectedCookTimeLimit: [],
      selectedSatietyRange: [],
    },

    states: {
      initializing: {
        id: "initializing",
        type: "parallel",

        entry: ["removeEditQueryParam"],

        states: {
          loadingIngredientUnit: {
            initial: "waiting",
            states: {
              waiting: {
                on: {
                  SET_INGREDIENT_UNIT: {
                    actions: ["setIngredientUnit"],
                    target: "done",
                  },
                },
              },
              done: {
                type: "final",
              },
            },
          },

          loadingUserStateAndMealPlan: {
            initial: "loadingUserState",
            states: {
              loadingUserState: {
                on: {
                  USER_STATE_CHANGE: [
                    {
                      actions: ["updateUserState"],
                      target: "loadingMealPlan",
                      cond: "hasSlug",
                    },
                    {
                      target: "#error",
                    },
                  ],
                },
              },

              loadingMealPlan: {
                invoke: {
                  src: "fetchMealPlan",
                  onDone: {
                    actions: ["setMealPlan"],
                    target: "loadingFavoriteRecipes",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#error",
                  },
                },
              },

              loadingFavoriteRecipes: {
                invoke: {
                  src: "fetchFavoriteRecipes",
                  onDone: {
                    actions: ["setFavoriteRecipes"],
                    target: "loadingTags",
                  },
                  onError: {
                    target: "loadingTags",
                  },
                },
              },
              loadingTags: {
                invoke: {
                  src: "getTags",
                  onDone: {
                    actions: "setTags",
                    target: "done",
                  },
                  onError: {
                    target: "done",
                  },
                },
              },

              done: {
                type: "final",
              },
            },
          },
        },

        onDone: { target: "#planning", actions: "analyticsViewMealPlan" },
      },

      planning: {
        id: "planning",
        initial: "init",
        entry: ["clearMenuOpenFlag"],

        on: {
          CLOSE_VIEW: {
            actions: ["clearSearchInput", "clearTagInput"],
            target: ".idle",
          },
          SHOW_RECIPE_PREVIEW: {
            actions: ["setCurrentDay", "setCurrentMeal", "setCurrentRecipe", "setCurrentRecipeIndex"],
            target: "#viewingRecipeMenu.viewingRecipePreview",
          },
        },

        states: {
          init: {
            always: [
              {
                cond: "isEditRequested",
                actions: ["clearEditRequestedFlag"],
                target: "editingMealPlan",
              },
              {
                target: "idle",
              },
            ],
          },

          idle: {
            on: {
              SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                actions: "setFocusedRecipe",
                target: "#planning.viewingRecipeNutritionalInfo",
              },
              SET_CURRENT_DAY: {
                actions: "setCurrentDay",
              },
              ADD_BREAKFAST: {
                actions: ["setCurrentMeal"],
                target: "#planning.selectRecipe",
              },
              ADD_LUNCH: {
                actions: ["setCurrentMeal"],
                target: "#planning.selectRecipe",
              },
              ADD_DINNER: {
                actions: ["setCurrentMeal"],
                target: "#planning.selectRecipe",
              },
              TOGGLE_DAY_MENU: {
                actions: ["setCurrentDay", "setHistory"],
                target: "viewingDayMenu",
              },
              TOGGLE_RECIPE_MENU: {
                actions: [
                  "setCurrentRecipe",
                  "setCurrentRecipeIndex",
                  "setCurrentMeal",
                  "setCurrentDay",
                  "setHistory",
                  "setCopyToDefault",
                ],
                target: "viewingRecipeMenu",
              },
              START_MEAL_PLAN: {
                target: "startingMealPlan",
              },
              COPY_MEAL_PLAN: {
                target: "copyingMealPlan",
              },
              SHOW_MEAL_PLAN_INGREDIENTS_LIST: {
                target: "#planning.viewingMealPlanIngredientsList",
              },
              SHOW_MEAL_PLAN_MENU: {
                target: "viewingMealPlanMenu",
              },

              // Explicitly ignore `CLOSE_VIEW` when on the `idle` state.
              CLOSE_VIEW: undefined,
            },
          },

          viewingDayMenu: {
            entry: ["setMenuOpenFlag", "clearCurrentRecipeIndex"],
            id: "viewingDayMenu",
            initial: "idle",
            states: {
              idle: {
                on: {
                  TOGGLE_DAY_MENU: {
                    target: "#planning.idle",
                  },
                  ADD_BREAKFAST: [
                    {
                      cond: "hasLessThanThreeBreakfasts",
                      actions: ["setCurrentMeal"],
                      target: "#planning.selectRecipe",
                    },
                    {
                      actions: ["setTooManyRecipesError"],
                      target: "#planning.confirmingError",
                    },
                  ],
                  ADD_LUNCH: [
                    {
                      cond: "hasLessThanThreeLunches",
                      actions: ["setCurrentMeal"],
                      target: "#planning.selectRecipe",
                    },
                    {
                      actions: ["setTooManyRecipesError"],
                      target: "#planning.confirmingError",
                    },
                  ],
                  ADD_DINNER: [
                    {
                      cond: "hasLessThanThreeDinners",
                      actions: ["setCurrentMeal"],
                      target: "#planning.selectRecipe",
                    },
                    {
                      actions: ["setTooManyRecipesError"],
                      target: "#planning.confirmingError",
                    },
                  ],
                  SHOW_DAY_NUTRITIONAL_INFO: {
                    target: "#planning.viewingDayNutritionalInfo",
                  },
                  ADD_RECIPES_TO_SHOPPING_LIST: {
                    target: "#planning.addRecipesToShoppingList",
                  },
                  UPDATE_MEAL_PLAN_DAY_SERVINGS: {
                    cond: "isMealPlanModifiable",
                    target: "updatingMealPlanDayServings",
                  },
                },
              },
              updatingMealPlanDayServings: {
                initial: "init",
                entry: ["setOriginalEvent"],
                exit: ["clearOriginalEvent"],

                states: {
                  init: {
                    always: [
                      {
                        cond: "isMealPlanDayServingsUniform",
                        target: "updating",
                      },
                      {
                        target: "confirmingAction",
                      },
                    ],
                  },

                  confirmingAction: {
                    on: {
                      CONFIRM_ACTION: {
                        target: "updating",
                      },
                      CANCEL_ACTION: {
                        target: "#planning.viewingDayMenu.idle",
                      },
                    },
                  },

                  updating: {
                    invoke: {
                      src: "updateMealPlanDayServings",
                      onDone: {
                        actions: ["updateMealPlan"],
                        target: "#planning.viewingDayMenu.idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                },
              },
            },
          },

          viewingRecipeNutritionalInfo: {
            on: {
              GO_TO_PREVIOUS_VIEW: {
                target: "#planning.idle",
              },
            },
          },

          viewingDayNutritionalInfo: {
            on: {
              GO_TO_PREVIOUS_VIEW: {
                target: "viewingDayMenu",
              },
            },
          },
          deletingMealPlan: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotDeleteMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },
              loading: {
                invoke: {
                  src: "deleteMealPlan",
                  onDone: {
                    actions: ["navigateToMealPlanListPage"],
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          startingMealPlan: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotStartMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              loading: {
                invoke: {
                  src: "startMealPlan",
                  onDone: {
                    actions: [
                      "updateMealPlanActiveFlag",
                      "setMealPlanStartedMessage",
                      "analyticsSuccessfulActivateMealPlan",
                    ],
                    target: "#planning.confirmingMessage",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          stoppingMealPlan: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotStopMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              loading: {
                invoke: {
                  src: "stopMealPlan",
                  onDone: {
                    actions: ["updateMealPlanActiveFlag", "setMealPlanStoppedMessage"],
                    target: "#planning.confirmingMessage",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          removingRecipe: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotRemoveRecipeError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              loading: {
                invoke: {
                  src: "removeRecipe",
                  onDone: {
                    actions: ["updateMealPlan", "clearCurrentRecipeIndex", "analyticsRemoveRecipeToMealPlan"],
                    target: "#planning.idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          addingRecipe: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotAddRecipeError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },
              loading: {
                invoke: {
                  src: "addRecipe",
                  onDone: {
                    actions: ["updateMealPlan", "analyticsAddRecipeToMealPlan"],
                    target: "#planning.idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          copyingMealPlan: {
            initial: "init",
            on: {
              GO_TO_PREVIOUS_VIEW: [
                {
                  cond: "isMenuOpen",
                  target: "#planning.viewingMealPlanMenu",
                },
                {
                  actions: ["closeView"],
                },
              ],
            },

            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanCopiable",
                    target: "idle",
                  },
                  {
                    actions: ["setCanNotCopyMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              idle: {
                on: {
                  CONFIRM_MEAL_PLAN_COPY: {
                    target: "loading",
                  },
                },
              },

              loading: {
                invoke: {
                  src: "copyMealPlan",
                  onDone: {
                    actions: ["navigateToMealPlanCopy", "analyticsSuccessfulCopyMealPlan"],
                    target: "#planning.idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          editingMealPlan: {
            initial: "init",

            on: {
              GO_TO_PREVIOUS_VIEW: {
                target: "#planning.viewingMealPlanMenu",
              },
            },

            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "idle",
                  },
                  {
                    actions: ["setCanNotEditMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              idle: {
                on: {
                  CONFIRM_MEAL_PLAN_EDIT: {
                    target: "loading",
                  },
                },
              },

              loading: {
                invoke: {
                  src: "editMealPlan",
                  onDone: {
                    actions: ["setMealPlanTitleDescription"],
                    target: "#planning.idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          sharingMealPlan: {
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotShareMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              loading: {
                invoke: {
                  src: "shareMealPlan",
                  onDone: {
                    actions: ["updateMealPlanSharedFlag", "setMealPlanSharedMessage"],
                    target: "#planning.confirmingMessage",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          unsharingMealPlan: {
            initial: "init",

            states: {
              init: {
                always: [
                  {
                    cond: "isMealPlanModifiable",
                    target: "loading",
                  },
                  {
                    actions: ["setCanNotUnshareMealPlanError"],
                    target: "#planning.confirmingError",
                  },
                ],
              },

              loading: {
                invoke: {
                  src: "unshareMealPlan",
                  onDone: {
                    actions: ["updateMealPlanSharedFlag", "setMealPlanUnsharedMessage"],
                    target: "#planning.confirmingMessage",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },
            },
          },

          viewingMealPlanMenu: {
            entry: ["setMenuOpenFlag"],
            initial: "idle",

            states: {
              idle: {
                on: {
                  SHOW_MEAL_PLAN_INFO: {
                    target: "#planning.viewingMealPlanInfo",
                  },
                  SHOW_MEAL_PLAN_INGREDIENTS_LIST: {
                    target: "#planning.viewingMealPlanIngredientsList",
                  },
                  SHOW_MEAL_PLAN_NUTRITIONAL_INFO: {
                    target: "#planning.viewingMealPlanNutritionalInfo",
                  },
                  START_MEAL_PLAN: {
                    target: "#planning.startingMealPlan",
                  },
                  STOP_MEAL_PLAN: {
                    target: "#planning.stoppingMealPlan",
                  },
                  EDIT_MEAL_PLAN: {
                    target: "#planning.editingMealPlan",
                  },
                  COPY_MEAL_PLAN: {
                    target: "#planning.copyingMealPlan",
                  },
                  SHARE_MEAL_PLAN: {
                    target: "#planning.sharingMealPlan",
                  },
                  UNSHARE_MEAL_PLAN: {
                    target: "#planning.unsharingMealPlan",
                  },
                  DELETE_MEAL_PLAN: {
                    target: "#planning.deletingMealPlan",
                  },
                  TOGGLE_INGREDIENT_UNIT: {
                    actions: "toggleIngredientUnit",
                  },
                  UPDATE_MEAL_PLAN_SERVINGS: {
                    cond: "isMealPlanModifiable",
                    target: "updatingMealPlanServings",
                  },
                },
              },

              updatingMealPlanServings: {
                initial: "init",
                entry: ["setOriginalEvent"],
                exit: ["clearOriginalEvent"],

                states: {
                  init: {
                    always: [
                      {
                        cond: "isMealPlanServingsUniform",
                        target: "updating",
                      },
                      {
                        target: "confirmingAction",
                      },
                    ],
                  },

                  confirmingAction: {
                    on: {
                      CONFIRM_ACTION: {
                        target: "updating",
                      },
                      CANCEL_ACTION: {
                        target: "#planning.viewingMealPlanMenu.idle",
                      },
                    },
                  },

                  updating: {
                    invoke: {
                      src: "updateMealPlanServings",
                      onDone: {
                        actions: ["updateMealPlan"],
                        target: "#planning.viewingMealPlanMenu.idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                },
              },
            },
          },

          viewingMealPlanIngredientsList: {
            entry: "setIngredientsPrintTarget",
            exit: "clearPrintTarget",
            initial: "init",

            on: {
              GO_TO_PREVIOUS_VIEW: {
                target: "viewingMealPlanMenu",
              },
              ADD_RECIPES_TO_SHOPPING_LIST: [
                {
                  target: "#planning.addRecipesToShoppingList",
                  cond: "isPremiumInContext",
                },
                {
                  actions: "writeToLocalStorage",
                  target: "settingMealPlanAddedToShoppingListMessage",
                },
              ],
            },

            states: {
              init: {
                always: [
                  {
                    cond: "hasIngredientsList",
                    target: "idle",
                  },
                  {
                    target: "loading",
                  },
                ],
              },
              loading: {
                invoke: {
                  src: "fetchIngredientsList",
                  onDone: {
                    actions: ["setIngredientsList"],
                    target: "idle",
                  },
                },
              },
              idle: {},
            },
          },
          settingMealPlanAddedToShoppingListMessage: {
            always: {
              actions: ["setRecipeAddedToShoppingListMessage", "updateShoppingListBadge"],
              target: "#planning.confirmingMessage",
            },
          },

          addRecipesToShoppingList: {
            invoke: {
              src: "addRecipesToShoppingListInFirebase",
              onDone: {
                actions: [
                  "setRecipeAddedToShoppingListMessage",
                  "updateShoppingListBadge",
                  "analyticsAddToShoppingList",
                ],
                target: "#planning.confirmingMessage",
              },
              onError: {
                actions: ["setShoppingListRecipesError"],
                target: "#planning.confirmingError",
              },
            },
          },

          viewingMealPlanNutritionalInfo: {
            on: {
              GO_TO_PREVIOUS_VIEW: {
                target: "viewingMealPlanMenu",
              },
            },
          },

          viewingMealPlanInfo: {
            on: {
              GO_TO_PREVIOUS_VIEW: [
                {
                  cond: "isMenuOpen",
                  target: "#planning.viewingMealPlanMenu",
                },
                {
                  actions: ["closeView"],
                },
              ],
            },
          },

          confirmingMessage: {
            initial: "idle",

            on: {
              CONFIRM_MESSAGE: {
                actions: ["clearMessage"],
                target: "idle",
              },
            },

            states: {
              idle: {
                on: {
                  SHARE: {
                    target: "sharing",
                  },
                  COPY_TO_CLIPBOARD: {
                    target: "copyingToClipboard",
                  },
                },
              },

              sharing: {
                entry: ["clearError"],
                invoke: {
                  src: "share",
                  onDone: {
                    target: "idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "idle",
                  },
                },
              },

              copyingToClipboard: {
                entry: ["clearError"],
                invoke: {
                  src: "copyToClipboard",
                  onDone: {
                    actions: [],
                    target: "idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "idle",
                  },
                },
              },
            },
          },

          confirmingError: {
            on: {
              CONFIRM_ERROR: {
                actions: ["clearError"],
                target: "idle",
              },
            },
          },

          viewingRecipeMenu: {
            id: "viewingRecipeMenu",
            entry: ["setMenuOpenFlag"],
            initial: "idle",
            states: {
              idle: {
                on: {
                  TOGGLE_RECIPE_MENU: { target: "#planning.idle" },
                  REMOVE_RECIPE: {
                    target: "#planning.removingRecipe",
                  },
                  CHANGE_RECIPE: {
                    target: "#planning.selectRecipe",
                  },
                  SHOW_RECIPE_PREVIEW: {
                    target: "viewingRecipePreview",
                  },
                  FAVORITE_RECIPE: { target: "favoritingRecipe" },
                  UNFAVORITE_RECIPE: { target: "unfavoritingRecipe" },
                  SHARE_RECIPE: {
                    actions: ["setRecipeSharedMessage"],
                    target: "#planning.confirmingMessage",
                  },
                  UPDATE_MEAL_PLAN_RECIPE_SERVINGS: {
                    cond: "isMealPlanModifiable",
                    target: "updatingMealPlanRecipeServings",
                  },
                  ADD_RECIPES_TO_SHOPPING_LIST: { target: "#planning.addRecipesToShoppingList" },
                  COPY_RECIPE_TO: { target: "copyRecipeTo" },
                },
              },
              favoritingRecipe: {
                initial: "loading",
                states: {
                  loading: {
                    invoke: {
                      src: "favoriteRecipe",
                      onDone: {
                        actions: "updateFavoriteRecipes",
                        target: "#viewingRecipeMenu.idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                },
              },
              unfavoritingRecipe: {
                initial: "loading",
                states: {
                  loading: {
                    invoke: {
                      src: "unfavoriteRecipe",
                      onDone: {
                        actions: "updateFavoriteRecipes",
                        target: "#viewingRecipeMenu.idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                },
              },
              updatingMealPlanRecipeServings: {
                invoke: {
                  src: "updateMealPlanRecipeServings",
                  onDone: {
                    actions: ["updateMealPlan"],
                    target: "idle",
                  },
                  onError: {
                    actions: ["setServiceError"],
                    target: "#planning.confirmingError",
                  },
                },
              },

              viewingRecipePreview: {
                initial: "loading",
                states: {
                  loading: {
                    invoke: {
                      src: "getFullRecipe",
                      onDone: {
                        actions: ["setFullRecipe", "analyticsViewRecipe"],
                        target: "idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                  idle: {},
                },
              },
              copyRecipeTo: {
                on: {
                  ADD_RECIPE: [
                    {
                      cond: "copyToTargetHasLessThanThreeRecipes",
                      target: "#planning.addingRecipe",
                    },
                    {
                      actions: ["setTooManyRecipesError"],
                      target: "#planning.confirmingError",
                    },
                  ],
                  SELECT_COPY_TO_DAY: { actions: "selectCopyToDay" },
                  SELECT_COPY_TO_MEAL: { actions: "selectCopyToMeal" },
                  GO_TO_PREVIOUS_VIEW: {
                    target: "#planning.viewingRecipeMenu",
                  },
                },
              },
            },
          },

          selectRecipe: {
            id: "selectRecipe",
            initial: "suggestions",
            states: {
              suggestions: {
                initial: "loading",
                id: "suggestions",
                states: {
                  loading: {
                    invoke: {
                      src: "getRecipeSuggestion",
                      onDone: {
                        actions: "setRecipeSuggestion",
                        target: "idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                  idle: {
                    on: {
                      TOGGLE_RECIPE_MENU: { target: "#planning.idle" },
                      TOGGLE_RECIPE_CARD_MENU: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "#suggestions.viewingRecipeCardMenu",
                      },
                      GO_TO_RECIPE_MENU_IDLE: { target: "#viewingRecipeMenu.idle" },
                      GO_TO_DAY_MENU_IDLE: { target: "#viewingDayMenu.idle" },
                      SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                      SHOW_SEARCH_TAB: { target: "#selectRecipe.search" },
                      ADD_RECIPE: { target: "#planning.addingRecipe" },
                      GENERATE_NEW_SUGGESTION: { target: "loading" },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                    },
                  },
                  viewingRecipeCardMenu: {
                    on: {
                      SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                      SHOW_SEARCH_TAB: { target: "#selectRecipe.search" },
                      TOGGLE_RECIPE_CARD_MENU: {
                        target: "#suggestions.idle",
                      },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                      ADD_RECIPE: { target: "#planning.addingRecipe" },
                    },
                  },
                  viewingRecipeCardNutritionalInfo: {
                    on: {
                      GO_TO_PREVIOUS_VIEW: {
                        target: "idle",
                      },
                    },
                  },
                },
              },
              favorites: {
                id: "favorites",
                initial: "idle",
                states: {
                  idle: {
                    on: {
                      TOGGLE_RECIPE_MENU: { target: "#planning.idle" },
                      TOGGLE_RECIPE_CARD_MENU: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "#favorites.viewingRecipeCardMenu",
                      },
                      GO_TO_RECIPE_MENU_IDLE: { target: "#viewingRecipeMenu.idle" },
                      GO_TO_DAY_MENU_IDLE: { target: "#viewingDayMenu.idle" },
                      SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                      SHOW_SEARCH_TAB: { target: "#selectRecipe.search" },
                      ADD_RECIPE: {
                        actions: ["clearSearchInput", "clearTagInput"],
                        target: "#planning.addingRecipe",
                      },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                    },
                  },
                  viewingRecipeCardMenu: {
                    on: {
                      SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                      SHOW_SEARCH_TAB: { target: "#selectRecipe.search" },
                      TOGGLE_RECIPE_CARD_MENU: {
                        target: "#favorites.idle",
                      },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                      ADD_RECIPE: {
                        actions: ["clearSearchInput", "clearTagInput"],
                        target: "#planning.addingRecipe",
                      },
                    },
                  },
                  viewingRecipeCardNutritionalInfo: {
                    on: {
                      GO_TO_PREVIOUS_VIEW: {
                        target: "idle",
                      },
                    },
                  },
                },
              },
              search: {
                id: "search",
                initial: "init",
                states: {
                  init: {
                    always: [
                      {
                        cond: "hasNotFetchedTags",
                        target: "loadingTags",
                      },
                      { target: "tempState" },
                    ],
                  },
                  tempState: {
                    always: [
                      { cond: "hasNoTagsAndNoSearchInput", target: "#search.popularTags" },
                      { target: "#selectRecipe.search.loadingSearchResult" },
                    ],
                  },
                  loadingTags: {
                    invoke: {
                      src: "getTags",
                      onDone: {
                        actions: "setTags",
                        target: "popularTags",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                  popularTags: {
                    on: {
                      TOGGLE_RECIPE_MENU: { target: "#planning.idle" },
                      GO_TO_RECIPE_MENU_IDLE: { target: "#viewingRecipeMenu.idle" },
                      GO_TO_DAY_MENU_IDLE: { target: "#viewingDayMenu.idle" },
                      SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                      SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                      SEARCH_INPUT_CHANGE: {
                        actions: "searchInputChange",
                        target: "loadingSearchResult",
                      },
                      CLEAR_SEARCH_INPUT: [
                        {
                          cond: "hasNoTagsAndNoSearchInput",
                          actions: "clearSearchInput",
                          target: "#selectRecipe.search.popularTags",
                        },
                        {
                          actions: "clearSearchInput",
                          target: "#selectRecipe.search.loadingSearchResult",
                        },
                      ],
                      TAG_CHANGE: {
                        actions: ["clearPageInputForSearchRecipes", "tagChange"],
                        target: "loadingSearchResult",
                      },
                      TOGGLE_TAG_VIEW: { target: "tagView" },
                    },
                  },
                  loadingSearchResultOnLoadMore: {
                    invoke: {
                      src: "searchRecipes",
                      onDone: {
                        actions: "setSearchResultOnLoadMore",
                        target: "idle",
                      },
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                  loadingSearchResult: {
                    invoke: {
                      src: "searchRecipes",
                      onDone: [
                        {
                          cond: "hasNoTagsAndNoSearchInput",
                          actions: ["clearPageInputForSearchRecipes", "setSearchResult"],
                          target: "popularTags",
                        },
                        {
                          actions: ["clearPageInputForSearchRecipes", "setSearchResult"],
                          target: "idle",
                        },
                      ],
                      onError: {
                        actions: ["setServiceError"],
                        target: "#planning.confirmingError",
                      },
                    },
                  },
                  tagView: {
                    initial: "idle",
                    states: {
                      idle: {
                        on: {
                          TOGGLE_TAG_VIEW: [
                            {
                              cond: "hasChosenTags",
                              target: "#search.idle",
                            },
                            {
                              target: "#search.popularTags",
                            },
                          ],
                          GO_TO_RECIPE_MENU_IDLE: { target: "#viewingRecipeMenu.idle" },
                          GO_TO_DAY_MENU_IDLE: { target: "#viewingDayMenu.idle" },
                          SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                          SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                          SEARCH_INPUT_CHANGE: {
                            actions: "searchInputChange",
                            target: "#search.loadingSearchResult",
                          },
                          GO_TO_IDLE: { target: "#planning.idle" },
                          CLEAR_SEARCH_INPUT: [
                            {
                              cond: "hasNoTagsAndNoSearchInput",
                              actions: ["clearPageInputForSearchRecipes", "clearSearchInput"],
                              target: "#selectRecipe.search.popularTags",
                            },
                            {
                              actions: ["clearPageInputForSearchRecipes", "clearSearchInput"],
                              target: "#selectRecipe.search.loadingSearchResult",
                            },
                          ],
                          REMOVE_TAG: { actions: "removeTag", target: "changingTags" },
                          ADD_TAG: { actions: "addTag", target: "changingTags" },
                          SET_COOK_TIME_LIMIT: {
                            actions: "setCookTimeLimit",
                            target: "changingTags",
                          },
                          SET_SATIETY_SCORE_RANGE: {
                            actions: "setSatietyScoreRange",
                            target: "changingTags",
                          },
                          REMOVE_COOKTIME_RANGE_TAG: {
                            actions: "removeCooktimeRangeTag",
                            target: "changingTags",
                          },
                          REMOVE_SATIETY_RANGE_TAG: {
                            actions: "removeSatietyRangeTag",
                            target: "changingTags",
                          },
                        },
                      },
                      changingTags: {
                        invoke: {
                          src: "searchRecipes",
                          onDone: [
                            {
                              cond: "hasNoTagsAndNoSearchInput",
                              actions: ["clearPageInputForSearchRecipes", "clearSearchResult"],
                              target: "idle",
                            },
                            {
                              actions: ["clearPageInputForSearchRecipes", "setSearchResult"],
                              target: "idle",
                            },
                          ],
                          onError: {
                            actions: ["setServiceError"],
                            target: "#planning.confirmingError",
                          },
                        },
                      },
                    },
                  },
                  idle: {
                    on: {
                      TOGGLE_RECIPE_CARD_MENU: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "#search.viewingRecipeCardMenu",
                      },
                      GO_TO_RECIPE_MENU_IDLE: { target: "#viewingRecipeMenu.idle" },
                      GO_TO_DAY_MENU_IDLE: { target: "#viewingDayMenu.idle" },
                      SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                      SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                      SEARCH_INPUT_CHANGE: {
                        actions: ["clearPageInputForSearchRecipes", "searchInputChange"],
                        target: "loadingSearchResult",
                      },
                      GO_TO_IDLE: { target: "#planning.idle" },
                      CLEAR_SEARCH_INPUT: [
                        {
                          cond: "hasNoTagsAndNoSearchInput",
                          actions: ["clearPageInputForSearchRecipes", "clearSearchInput"],
                          target: "#selectRecipe.search.popularTags",
                        },
                        {
                          actions: ["clearPageInputForSearchRecipes", "clearSearchInput"],
                          target: "#selectRecipe.search.loadingSearchResult",
                        },
                      ],
                      ADD_RECIPE: {
                        target: "#planning.addingRecipe",
                        actions: ["clearSearchInput", "clearTagInput"],
                      },
                      TOGGLE_TAG_VIEW: { target: "tagView" },
                      REMOVE_TAG: { actions: "removeTag", target: "loadingSearchResult" },
                      LOAD_MORE_RECIPES: {
                        actions: "setPageInputForSearchRecipes",
                        target: "loadingSearchResultOnLoadMore",
                      },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        actions: ["setFocusedRecipe", "setSelectRecipeHistory"],
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                      REMOVE_COOKTIME_RANGE_TAG: {
                        actions: "removeCooktimeRangeTag",
                        target: "loadingSearchResult",
                      },
                      REMOVE_SATIETY_RANGE_TAG: {
                        actions: "removeSatietyRangeTag",
                        target: "loadingSearchResult",
                      },
                    },
                  },
                  viewingRecipeCardMenu: {
                    on: {
                      SHOW_SUGGESTIONS_TAB: { target: "#selectRecipe.suggestions" },
                      SHOW_FAVORITES_TAB: { target: "#selectRecipe.favorites" },
                      TOGGLE_RECIPE_CARD_MENU: {
                        target: "#search.idle",
                      },
                      SHOW_RECIPE_CARD_NUTRITIONAL_INFO: {
                        target: "viewingRecipeCardNutritionalInfo",
                      },
                      ADD_RECIPE: {
                        target: "#planning.addingRecipe",
                        actions: ["clearSearchInput", "clearTagInput"],
                      },
                    },
                  },
                  viewingRecipeCardNutritionalInfo: {
                    on: {
                      GO_TO_PREVIOUS_VIEW: {
                        target: "idle",
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },

      error: {
        id: "error",
        type: "final",
      },
    },
  },
  {
    actions: {
      setSelectRecipeHistory: assign({
        selectRecipeHistory: (_, event) => (event as ToggleRecipeCardMenuEvent).history,
      }),
      setFocusedRecipe: assign({
        focusedRecipe: (_, event) =>
          (event as ToggleRecipeCardMenuEvent | ShowRecipeCardNutritionalInforEvent).recipe,
      }),
      writeToLocalStorage: (ctx, event) => {
        const data = JSON.parse(localStorage.getItem("dd/shopping-list")!) ?? emptyShoppingList();
        const e = event as AddRecipesToShoppingListEvent;
        const recipes = formatShoppingListDataForMealPlan(ctx, e);

        data.list.items = [...data.list.items, ...recipes!];
        localStorage.setItem("dd/shopping-list", JSON.stringify(data));
      },

      updateShoppingListBadge: () => {
        window.elements?.nav.setShoppingListNumItems(1);
      },
      updateUserState: assign({
        userId: (_, event) => (event as UserStateChangeEvent).id,
        firebaseUserId: (_, event) => (event as UserStateChangeEvent).firebaseUserId,
        isPremium: (_, event) => (event as UserStateChangeEvent).premium,
      }),
      setMealPlan: assign({
        mealPlan: (_, event) => (event as DoneInvokeEvent<MealPlanner_GetMealPlanQuery>).data.mealplanBySlug,
        formattedMealPlan: (_, event) =>
          getWeekdayAndDay((event as DoneInvokeEvent<MealPlanner_GetMealPlanQuery>).data.mealplanBySlug),
      }),
      setFavoriteRecipes: assign({
        favoriteRecipes: (_, event) =>
          (event as DoneInvokeEvent<MealPlanner_GetFavoriteRecipesQuery>).data.favoriteRecipes
            .recipes as MealPlanner_Recipe[],
      }),
      updateFavoriteRecipes: assign({
        favoriteRecipes: (context, event) => {
          if (
            (event as DoneInvokeEvent<MealPlanner_SetFavoriteRecipeMutation>).type ===
            "done.invoke.unfavoriteRecipe"
          ) {
            return context.favoriteRecipes.filter((recipe) => recipe.id !== context.currentRecipe!.recipe!.id);
          } else if (
            (event as DoneInvokeEvent<MealPlanner_SetFavoriteRecipeMutation>).type ===
            "done.invoke.favoriteRecipe"
          ) {
            return [...context.favoriteRecipes, getRecipeByIndex(context)!.recipe!];
          }

          return context.favoriteRecipes;
        },
      }),
      setIngredientUnit: assign({
        ingredientUnit: (_, event) => (event as SetIngredientUnitEvent).ingredientUnit,
      }),
      setMealPlanTitleDescription: assign({
        mealPlan: ({ mealPlan }, event) => ({
          ...mealPlan!,
          ...(event as DoneInvokeEvent<MealPlanner_MealPlanUpdatedTitleDescription>).data,
        }),
      }),
      setPageInputForSearchRecipes: assign({
        pageInputForSearchRecipes: (ctx) => ctx.pageInputForSearchRecipes + 1,
      }),
      updateMealPlan: assign<Context, Events>({
        mealPlan: (ctx, event) => {
          const updatedMealPlanData = (
            event as DoneInvokeEvent<MealPlanner_MealPlanUpdatedSchedule | MealPlanner_MealPlanUpdatedServings>
          ).data;
          return {
            ...ctx.mealPlan!,
            ...updatedMealPlanData,
          };
        },

        formattedMealPlan: (ctx, event) => {
          const updatedMealPlan = {
            ...ctx.mealPlan!,
            ...(
              event as DoneInvokeEvent<
                MealPlanner_MealPlanUpdatedSchedule | MealPlanner_MealPlanUpdatedServings
              >
            ).data,
          };
          return getWeekdayAndDay(updatedMealPlan);
        },

        ingredientsList: undefined,
      }),

      setCopyToDefault: assign({
        copyToDay: (ctx) => ctx.currentDay,
        copyToMeal: (ctx) => ctx.currentMeal!,
      }),
      selectCopyToDay: assign({
        copyToDay: (_, event) => (event as SelectCopyToDayEvent).day,
      }),
      selectCopyToMeal: assign({
        copyToMeal: (_, event) => (event as SelectCopyToMealEvent).meal,
      }),

      setIngredientsList: assign({
        ingredientsList: (_, event) => (event as DoneInvokeEvent<MealPlanner_MergedIngredient[]>).data,
      }),

      updateMealPlanActiveFlag: assign({
        mealPlan: ({ mealPlan }, event) => ({
          ...mealPlan!,
          isActive: (event as DoneInvokeEvent<boolean>).data,
        }),
      }),
      updateMealPlanSharedFlag: assign({
        mealPlan: ({ mealPlan }, event) => ({
          ...mealPlan!,
          isShared: (event as DoneInvokeEvent<boolean>).data,
        }),
      }),

      clearMenuOpenFlag: assign<Context, Events>({
        isMenuOpen: () => false,
      }),
      setMenuOpenFlag: assign<Context, Events>({
        isMenuOpen: () => true,
      }),

      closeView: send({ type: "CLOSE_VIEW" }),

      clearMessage: assign<Context, Events>({
        confirmationMessage: (_, event) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      setMealPlanStartedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.MEAL_PLAN_STARTED,
      }),
      setMealPlanStoppedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.MEAL_PLAN_STOPPED,
      }),
      setMealPlanSharedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.MEAL_PLAN_SHARED,
      }),
      setMealPlanUnsharedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.MEAL_PLAN_UNSHARED,
      }),
      setRecipeSharedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.RECIPE_SHARED,
      }),
      setMealPlanDeletedMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.MEALPLAN_DELETED,
      }),
      setRecipeAddedToShoppingListMessage: assign<Context, Events>({
        confirmationMessage: () => MealPlannerConfirmationMessage.RECIPES_ADDED_TO_SHOPPING_LIST,
      }),

      setCurrentDay: assign({
        currentDay: (_, event) => ((event as ToggleDayMenuEvent) || (event as ToggleRecipeMenuEvent)).day,
      }),
      setCurrentMeal: assign({
        currentMeal: (_, event) =>
          (event as ToggleRecipeMenuEvent | AddBreakfastEvent | AddLunchEvent | AddDinnerEvent).meal,
      }),
      setCurrentRecipe: assign({
        currentRecipe: (_, event) => (event as ToggleRecipeMenuEvent).recipe,
      }),
      setCurrentRecipeIndex: assign({
        currentRecipeIndex: (_, event) => (event as ToggleRecipeMenuEvent).index,
      }),

      clearCurrentRecipeIndex: assign({
        currentRecipeIndex: (_, event) => undefined, // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      clearError: assign<Context, Events>({
        error: undefined,
      }),
      setServiceError: assign({
        error: (_, event) => (event as DoneInvokeEvent<DDError<MealPlannerErrorCode>>).data,
      }),
      setTooManyRecipesError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.TOO_MANY_RECIPES),
      }),
      setPremiumMembershipRequiredError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.PREMIUM_MEMBERSHIP_REQUIRED),
      }),
      setCanNotStartMealPlanError: assign<Context, Events>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_START_MEAL_PLAN),
      }),
      setCanNotStopMealPlanError: assign<Context, Events>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_STOP_MEAL_PLAN),
      }),
      setCanNotShareMealPlanError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_SHARE_MEAL_PLAN),
      }),
      setCanNotUnshareMealPlanError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_UNSHARE_MEAL_PLAN),
      }),
      setCanNotCopyMealPlanError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_COPY_MEAL_PLAN),
      }),
      setCanNotEditMealPlanError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_EDIT_MEAL_PLAN),
      }),
      setCanNotAddRecipeError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_ADD_RECIPE),
      }),
      setCanNotRemoveRecipeError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_REMOVE_RECIPE),
      }),
      setCanNotDeleteMealPlanError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_DELETE_MEALPLAN),
      }),
      setShoppingListRecipesError: assign<Context, any>({
        error: () => new DDError(MealPlannerErrorCode.CAN_NOT_ADD_RECIPES_SHOPPING_LIST),
      }),

      navigateToMealPlanCopy: ({ navigate }, event) => {
        const slug = (event as DoneInvokeEvent<string>).data;
        navigate({
          to: MealPlanPage,
          params: { slug },
        });
      },
      setHistory: assign({
        history: (_, event) => (event as ToggleDayMenuEvent | ToggleRecipeMenuEvent).history,
      }),

      searchInputChange: assign({
        searchInput: (_, event) => (event as SearchInputChangeEvent).input,
      }),
      setSearchResult: assign({
        showLoadMoreButton: (_, event) =>
          (event as DoneInvokeEvent<MealPlanner_SearchRecipesQuery_listRecipes>).data.nextPage !== null,
        searchResult: (ctx, event) => {
          if (
            ctx.tagInput.length === 0 &&
            ctx.selectedCookTimeLimit.length === 0 &&
            ctx.selectedSatietyRange.length === 0 &&
            ctx.searchInput === ""
          ) {
            return [];
          } else {
            return (event as DoneInvokeEvent<MealPlanner_SearchRecipesQuery_listRecipes>).data
              .recipes as MealPlanner_Recipe[];
          }
        },
      }),
      setSearchResultOnLoadMore: assign({
        showLoadMoreButton: (_, event) =>
          (event as DoneInvokeEvent<MealPlanner_SearchRecipesQuery_listRecipes>).data.nextPage !== null,
        searchResult: (ctx, event) => {
          return [
            ...ctx.searchResult!,
            ...((event as DoneInvokeEvent<MealPlanner_SearchRecipesQuery_listRecipes>).data
              .recipes as MealPlanner_Recipe[]),
          ];
        },
      }),
      clearSearchInput: assign({
        searchInput: (_, event) => "", // eslint-disable-line @typescript-eslint/no-unused-vars
        searchResult: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      clearTagInput: assign({
        tagInput: (ctx) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
        chosenTags: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
        selectedSatietyRange: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
        selectedCookTimeLimit: (_, event) => [], // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      clearPageInputForSearchRecipes: assign({
        pageInputForSearchRecipes: (_, event) => 1, // eslint-disable-line @typescript-eslint/no-unused-vars
      }),
      tagChange: assign({
        tagInput: (ctx, event) => [...ctx.tagInput, ...(event as TagChangeEvent).tags],
        chosenTags: (ctx, event) =>
          ctx.allTags.filter((tagWithInfo) => (event as TagChangeEvent).tags.includes(tagWithInfo.id)),
      }),
      setTags: assign({
        allTags: (_, event) => (event as DoneInvokeEvent<MealPlanner_GetTagsQuery_listTags_tags[]>).data,
      }),
      removeTag: assign({
        tagInput: (ctx, event) => ctx.tagInput.filter((tag) => tag !== (event as RemoveTagEvent).tag.id),
        chosenTags: (ctx, event) => ctx.chosenTags.filter((tag) => tag !== (event as RemoveTagEvent).tag),
      }),
      addTag: assign({
        tagInput: (ctx, event) => [...ctx.tagInput, (event as AddTagEvent).tag.id],
        chosenTags: (ctx, event) => [...ctx.chosenTags, (event as AddTagEvent).tag],
      }),

      removeCooktimeRangeTag: assign({
        selectedCookTimeLimit: (ctx, event) =>
          ctx.selectedCookTimeLimit.filter((tag) => tag.id !== (event as RemoveCooktimeRangeTagEvent).tag.id),
      }),

      removeSatietyRangeTag: assign({
        selectedSatietyRange: (ctx, event) =>
          ctx.selectedSatietyRange.filter((tag) => tag.id !== (event as RemoveSatietyRangeTagEvent).tag.id),
      }),

      setCookTimeLimit: assign({
        selectedCookTimeLimit: (_, event) => (event as ChangeCookTimeLimitEvent).selectedCookTimeRange,
      }),

      setSatietyScoreRange: assign({
        selectedSatietyRange: (_, event) => (event as SetSatietyScoreRangeEvent).selectedSatietyRange,
      }),
      setRecipeSuggestion: assign({
        recipeSuggestion: (ctx, event) => [
          ...ctx.recipeSuggestion,
          ...(event as DoneInvokeEvent<MealPlanner_GetRecipeSuggestionQuery_recipeSuggestions[]>).data,
        ],
      }),
      setFullRecipe: assign({
        fullRecipe: (_, event) => (event as DoneInvokeEvent<MealPlanner_GetRecipeQuery_recipe>).data,
      }),
      setIngredientsPrintTarget: assign<Context, Events>({
        printTarget: PrintTarget.INGREDIENTS_LIST,
      }),
      clearPrintTarget: assign<Context, Events>({
        printTarget: undefined,
      }),
      toggleIngredientUnit: assign({
        ingredientUnit: (_, event) => (event as ToggleIngredientUnitEvent).ingredientUnit,
      }),
      navigateToOriginalRecipe: (context) => {
        const { navigate, fullRecipe } = context;
        const slug = fullRecipe?.slug as string;
        const servings = getRecipeByIndex(context)?.servings;

        navigate({
          to: RecipePage,
          params: { slug, servings },
        });
      },

      navigateToMealPlanListPage: ({ navigate }) => {
        navigate({
          to: MyMealPlansPage,
        });
      },

      clearSearchResult: assign({ searchResult: (_, event) => [] }), // eslint-disable-line @typescript-eslint/no-unused-vars

      clearEditRequestedFlag: assign<Context, Events>({
        isEditRequested: false,
      }),
      removeEditQueryParam: ({ isEditRequested, slug, navigate }) => {
        if (isEditRequested) {
          navigate({
            to: MealPlanPage,
            params: { slug },
            replace: true,
          });
        }
      },

      setOriginalEvent: assign({
        originalEvent: (context, event) => event,
      }),
      clearOriginalEvent: assign<Context, Events>({
        originalEvent: undefined,
      }),
      analyticsSuccessfulCopyMealPlan: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "successful_added_themed_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsSuccessfulActivateMealPlan: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "successful_activate_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsViewMealPlan: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "view_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsViewRecipe: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "view_recipe_from_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsAddToShoppingList: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "successful_add_to_shopping_list_from_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsAddRecipeToMealPlan: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "successful_add_recipe_to_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
      analyticsRemoveRecipeToMealPlan: (ctx) =>
        sendEvent({
          eventName: "meal_planner",
          action: "successful_remove_recipe_from_meal_plan",
          payload: {
            userId: ctx.userId ? sha256(ctx.userId) : "",
            isPremium: ctx.isPremium,
          },
        }),
    },

    services: {
      fetchMealPlan: async ({ slug }) => {
        try {
          const { data } = await client.query<
            MealPlanner_GetMealPlanQuery,
            MealPlanner_GetMealPlanQueryVariables
          >({
            query: MealPlanner_GetMealPlan,
            variables: { slug },
          });
          return data;
        } catch (err) {
          const { message } = err as ApolloError;

          if (message.includes("404") || message.includes("could not find a melaplan with the provided slug")) {
            throw new DDError(MealPlannerErrorCode.MEAL_PLAN_NOT_FOUND);
          } else if (
            message.match(/401|403/) ||
            message.includes("user does not have permission to see this mealplan")
          ) {
            throw new DDError(MealPlannerErrorCode.PREMIUM_MEMBERSHIP_REQUIRED);
          } else {
            throw new DDError(MealPlannerErrorCode.FAILED_TO_LOAD_INITIAL_DATA);
          }
        }
      },

      fetchFavoriteRecipes: async () => {
        try {
          const { data } = await client.query<
            MealPlanner_GetFavoriteRecipesQuery,
            MealPlanner_GetFavoriteRecipesQueryVariables
          >({
            query: MealPlanner_GetFavoriteRecipes,
          });
          return data;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_LOAD_INITIAL_DATA);
        }
      },

      startMealPlan: async ({ mealPlan }) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_START_MEAL_PLAN, mealPlan);

        try {
          await client.mutate<
            MealPlanner_ActivateMealPlanMutation,
            MealPlanner_ActivateMealPlanMutationVariables
          >({
            mutation: MealPlanner_ActivateMealPlan,
            variables: { id: mealPlan.id },
          });
          return true;
        } catch (error: any) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_START_MEAL_PLAN,
            "Server returned with an error",
            error
          );
        }
      },

      stopMealPlan: async ({ mealPlan }) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_STOP_MEAL_PLAN, mealPlan);

        try {
          await client.mutate<MealPlanner_DeactivateMealPlanMutation>({
            mutation: MealPlanner_DeactivateMealPlan,
          });
          return false;
        } catch (error: any) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_STOP_MEAL_PLAN,
            "Server returned with an error",
            error
          );
        }
      },

      removeRecipe: async ({ mealPlan }, event) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_REMOVE_RECIPE, mealPlan);

        const { day, meal, index } = event as RemoveRecipeEvent;

        try {
          const { data } = await client.mutate<
            MealPlanner_RemoveRecipeFromMealPlanMutation,
            MealPlanner_RemoveRecipeFromMealPlanMutationVariables
          >({
            mutation: MealPlanner_RemoveRecipeFromMealPlan,
            variables: {
              mealPlanId: mealPlan.id,
              day,
              meal,
              index,
            },
          });
          return data?.updateMealplanRemoveRecipe;
        } catch (err: any) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_REMOVE_RECIPE, "Server replied with an error", err);
        }
      },

      addRecipe: async ({ mealPlan }, event) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_ADD_RECIPE, mealPlan);

        const { day, meal, recipeId, index, servings } = event as AddRecipeEvent;

        try {
          if (typeof index !== "undefined") {
            const { data } = await client.mutate<
              MealPlanner_ReplaceRecipeFromMealPlanMutation,
              MealPlanner_ReplaceRecipeFromMealPlanMutationVariables
            >({
              mutation: MealPlanner_ReplaceRecipeFromMealPlan,
              variables: {
                mealPlanId: mealPlan.id,
                day,
                meal,
                recipeId,
                index,
                servings,
              },
            });
            return data?.updateMealplanReplaceRecipe;
          } else {
            const { data } = await client.mutate<
              MealPlanner_AddRecipeToMealPlanMutation,
              MealPlanner_AddRecipeToMealPlanMutationVariables
            >({
              mutation: MealPlanner_AddRecipeToMealPlan,
              variables: {
                mealPlanId: mealPlan.id,
                day,
                meal,
                recipeId,
                servings,
              },
            });
            return data?.updateMealplanAddRecipe;
          }
        } catch (err: any) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_ADD_RECIPE, "Server replied with an error", err);
        }
      },

      deleteMealPlan: async ({ mealPlan }) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_DELETE_MEALPLAN, mealPlan);

        try {
          const { data } = await client.mutate<
            MealPlanner_DeleteMealPlanMutation,
            MealPlanner_DeleteMealPlanMutationVariables
          >({
            mutation: MealPlanner_DeleteMealPlan,
            variables: {
              id: mealPlan.id,
            },
          });
          return data;
        } catch (err: any) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_DELETE_MEALPLAN,
            "Server returned with an error",
            err
          );
        }
      },

      copyMealPlan: async ({ mealPlan }, event) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_COPY_MEAL_PLAN, mealPlan);

        const { title, description } = event as ConfirmMealPlanCopyEvent;
        let data: Nullable<MealPlanner_CopyMealPlanMutation>;

        try {
          const result = await client.mutate<
            MealPlanner_CopyMealPlanMutation,
            MealPlanner_CopyMealPlanMutationVariables
          >({
            mutation: MealPlanner_CopyMealPlan,
            variables: {
              id: mealPlan.id,
              title,
              description,
            },
          });
          data = result.data;
        } catch (error: any) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_COPY_MEAL_PLAN,
            "Server returned with an error",
            error
          );
        }

        if (!data) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_COPY_MEAL_PLAN,
            "Server returned an unexpected response"
          );
        }

        return data.copyMealplan.slug;
      },

      editMealPlan: async function editMealPlan({ mealPlan }, event) {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_EDIT_MEAL_PLAN, mealPlan);

        const { title, description } = event as ConfirmMealPlanEditEvent;
        let data: Nullable<MealPlanner_EditMealPlanMutation>;

        try {
          const result = await client.mutate<
            MealPlanner_EditMealPlanMutation,
            MealPlanner_EditMealPlanMutationVariables
          >({
            mutation: MealPlanner_EditMealPlan,
            variables: {
              id: mealPlan.id,
              title,
              description,
            },
          });
          data = result.data;
        } catch (error: any) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_EDIT_MEAL_PLAN,
            "Server returned with an error",
            error
          );
        }

        if (!data) {
          throw new DDError(
            MealPlannerErrorCode.FAILED_TO_EDIT_MEAL_PLAN,
            "Server returned an unexpected response"
          );
        }

        return data.updateMealplan.mealplan;
      },

      updateMealPlanServings: async ({ mealPlan, allowedServings, originalEvent }) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_SERVINGS, mealPlan);

        const { servings } = originalEvent as UpdateMealPlanServingsEvent;

        if (!allowedServings.includes(servings)) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_SERVINGS);
        }

        try {
          const { data } = await client.mutate<
            MealPlanner_UpdateMealPlanServingsMutation,
            MealPlanner_UpdateMealPlanServingsMutationVariables
          >({
            mutation: MealPlanner_UpdateMealPlanServings,
            variables: {
              id: mealPlan.id,
              servings,
            },
          });
          return data!.updateMealplanServings;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_UPDATE_MEAL_PLAN_SERVINGS);
        }
      },

      updateMealPlanDayServings: async ({ mealPlan, allowedServings, originalEvent }) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_DAY_SERVINGS, mealPlan);

        const { day, servings } = originalEvent as UpdateMealPlanDayServingsEvent;

        if (!allowedServings.includes(servings)) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_DAY_SERVINGS);
        }

        try {
          const { data } = await client.mutate<
            MealPlanner_UpdateMealPlanDayServingsMutation,
            MealPlanner_UpdateMealPlanDayServingsMutationVariables
          >({
            mutation: MealPlanner_UpdateMealPlanDayServings,
            variables: {
              id: mealPlan.id,
              day,
              servings,
            },
          });
          return data!.updateMealplanDayServings;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_UPDATE_MEAL_PLAN_DAY_SERVINGS);
        }
      },

      updateMealPlanRecipeServings: async (context, event) => {
        assertNonNullMealPlan(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_RECIPE_SERVINGS, context.mealPlan);

        const { day, meal, recipe, index, servings } = event as UpdateMealPlanRecipeServingsEvent;
        const {
          servings: { allowed: allowedServings },
        } = getRecipeByIndex(context)!.recipe!;

        if (!allowedServings.includes(servings)) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_UPDATE_MEAL_PLAN_RECIPE_SERVINGS);
        }

        try {
          const { data } = await client.mutate<
            MealPlanner_UpdateMealPlanRecipeServingsMutation,
            MealPlanner_UpdateMealPlanRecipeServingsMutationVariables
          >({
            mutation: MealPlanner_UpdateMealPlanRecipeServings,
            variables: {
              id: context.mealPlan.id,
              day,
              meal,
              recipe: recipe.id,
              index,
              servings,
            },
          });
          return data!.updateMealplanRecipeServings;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_UPDATE_MEAL_PLAN_RECIPE_SERVINGS);
        }
      },

      fetchIngredientsList: async ({ formattedMealPlan }) => {
        const recipes = (formattedMealPlan ?? [])
          .map((day) => day.mealplanDay)
          .flatMap(({ breakfast, lunch, dinner }) => [
            ...breakfast.recipeWithServings,
            ...lunch.recipeWithServings,
            ...dinner.recipeWithServings,
          ])
          .filter((recipe: MealPlanner_RecipeWithServings | null): recipe is MealPlanner_RecipeWithServings =>
            Boolean(recipe)
          )
          .map(({ recipe, servings }) => ({ id: recipe!.id, servings }));

        try {
          const { data } = await client.mutate<
            MealPlanner_MergeIngredientsQuery,
            MealPlanner_MergeIngredientsQueryVariables
          >({
            mutation: MealPlanner_MergeIngredients,
            variables: {
              recipes,
            },
          });
          return data?.mergeIngredients?.ingredients;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_UPDATE_MEAL_PLAN_RECIPE_SERVINGS);
        }
      },

      shareMealPlan: async ({ mealPlan }) => {
        return mutateMealPlanSharedFlag(mealPlan, true);
      },

      unshareMealPlan: async ({ mealPlan }) => {
        return mutateMealPlanSharedFlag(mealPlan, false);
      },

      searchRecipes: async (ctx) => {
        try {
          const { data } = await client.query<
            MealPlanner_SearchRecipesQuery,
            MealPlanner_SearchRecipesQueryVariables
          >({
            query: MealPlanner_SearchRecipes,
            variables: {
              pageSize: 15,
              query: ctx.searchInput === "" ? null : ctx.searchInput,
              tagFilters: ctx.tagInput!,
              page: ctx.pageInputForSearchRecipes,
              satietyScoreRange:
                ctx.selectedSatietyRange.length > 0
                  ? getMinMaxValue(ctx.selectedSatietyRange)
                  : { min: 0, max: 100 },
              cookTimeMinutesRange:
                ctx.selectedCookTimeLimit.length > 0
                  ? getMinMaxValue(ctx.selectedCookTimeLimit)
                  : { min: null, max: null },
            },
          });
          return data.listRecipes;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_SEARCH_RECIPES);
        }
      },
      getTags: async () => {
        try {
          const { data } = await client.query<MealPlanner_GetTagsQuery>({
            query: MealPlanner_GetTags,
          });
          const allData = [...data.listTags.tags, ...data.pageTwo.tags].filter(
            (tag) => tag?.type !== "mealplan_type"
          );

          return allData;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_LOAD_TAGS);
        }
      },
      getRecipeSuggestion: async (ctx) => {
        try {
          const { data } = await client.query<
            MealPlanner_GetRecipeSuggestionQuery,
            MealPlanner_GetRecipeSuggestionQueryVariables
          >({
            query: MealPlanner_GetRecipeSuggestion,
            variables: {
              meal: ctx.currentMeal!,
              recipeCount: 4,
            },
            fetchPolicy: "network-only",
          });
          return data.recipeSuggestions;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_LOAD_RECIPE_SUGGESTION);
        }
      },

      getFullRecipe: async (ctx) => {
        try {
          const { data } = await client.query<MealPlanner_GetRecipeQuery, MealPlanner_GetRecipeQueryVariables>({
            query: MealPlanner_GetRecipe,
            variables: {
              id: ctx.currentRecipe!.recipe!.id,
            },
          });
          return data.recipe;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_LOAD_FULL_RECIPE);
        }
      },
      favoriteRecipe: async (ctx) => {
        try {
          const { data } = await client.mutate<
            MealPlanner_SetFavoriteRecipeMutation,
            MealPlanner_SetFavoriteRecipeMutationVariables
          >({
            mutation: MealPlanner_SetFavoriteRecipe,
            variables: {
              id: ctx.currentRecipe!.recipe!.id,
              favorite: true,
              kind: DomainKind.RECIPE,
            },
          });
          return data;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_FAVORITE_RECIPE);
        }
      },
      unfavoriteRecipe: async (ctx) => {
        try {
          const { data } = await client.mutate<
            MealPlanner_SetFavoriteRecipeMutation,
            MealPlanner_SetFavoriteRecipeMutationVariables
          >({
            mutation: MealPlanner_SetFavoriteRecipe,
            variables: {
              id: ctx.currentRecipe!.recipe!.id,
              favorite: false,
              kind: DomainKind.RECIPE,
            },
          });
          return data;
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.FAILED_TO_UNFAVORITE_RECIPE);
        }
      },

      share: async ({ canShare }, event) => {
        if (!canShare) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_SHARE);
        }

        const { title, url } = event as ShareContentEvent;

        try {
          await navigator.share({
            title,
            url,
          });
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_SHARE);
        }
      },

      copyToClipboard: async ({ canCopyToClipboard }, event) => {
        if (!canCopyToClipboard) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_COPY_TO_CLIPBOARD);
        }

        const { value } = event as CopyToClipboardEvent;

        try {
          await navigator.clipboard.writeText(value);
        } catch (err) {
          throw new DDError(MealPlannerErrorCode.CAN_NOT_COPY_TO_CLIPBOARD);
        }
      },

      addRecipesToShoppingListInFirebase: async (ctx: Context, event) => {
        const e = event as AddRecipesToShoppingListEvent;
        const encodeUserIdForFirestore = (str: string) => str.replace(/^users\//, "");

        const tryOrNull = <T>(fn: Function): T | null => {
          try {
            return fn();
          } catch (e) {
            return null;
          }
        };

        const parseDocOrNull = (doc: DocumentData) =>
          tryOrNull<FirebaseShoppingListPayload>(() => JSON.parse(doc.data()!.json));

        const getShoppingListDocFromFirestore = (lang: string, userId: string) => {
          const db = getFirestore(firebaseApp);
          const collectionRef = collection(db, "shopping_list", encodeUserIdForFirestore(userId), lang);

          return doc(collectionRef, "list");
        };

        const emptyShoppingListWithRecipes = (
          recipes: FirebaseShoppingListRecipe[]
        ): FirebaseShoppingListPayload => ({
          version: "1",
          list: {
            customIngredients: [],
            ingredients: [],
            items: recipes,
          },
        });

        const mergeShoppingListWithRecipes = (
          sl: FirebaseShoppingListPayload,
          recipes: FirebaseShoppingListRecipe[]
        ): FirebaseShoppingListPayload => ({
          version: sl.version,
          list: {
            customIngredients: sl.list.customIngredients,
            ingredients: sl.list.ingredients,
            items: recipes,
          },
        });

        const recipes = formatShoppingListDataForMealPlan(ctx, e);

        if (Array.isArray(recipes)) {
          // get the shopping list (if exists)
          const docRef = getShoppingListDocFromFirestore(ctx.locale, ctx.firebaseUserId!);
          return new Promise((resolve) => {
            getDoc(docRef)
              .then(parseDocOrNull)
              .then((shoppingList) => {
                if (shoppingList !== null) {
                  // if we already have recipes in the shopping list we merge both new and old recipes
                  const mergedRecipes = [...shoppingList.list.items, ...recipes];
                  const payload = mergeShoppingListWithRecipes(shoppingList, mergedRecipes);
                  updateDoc(docRef, { json: JSON.stringify(payload) }).then(() => resolve(recipes.length));
                } else {
                  // the user has no shopping list, let's create one
                  const payload = emptyShoppingListWithRecipes(recipes);
                  setDoc(docRef, { json: JSON.stringify(payload) }).then(() => resolve(recipes.length));
                }
              });
          });
        }

        // no recipes found
        return Promise.reject();
      },
    },

    guards: {
      hasSlug: (ctx) => ctx.slug !== "",
      isPremium: (_, event) => (event as UserStateChangeEvent).premium === true,
      isPremiumInContext: (ctx) => ctx.isPremium === true,

      isMealPlanModifiable: ({ mealPlan, userId }) =>
        typeof mealPlan !== "undefined" && isMealPlanModifiable(mealPlan, userId),

      isMealPlanCopiable: ({ mealPlan, isPremium }) =>
        typeof mealPlan !== "undefined" && isMealPlanCopiable(mealPlan, isPremium),

      isMenuOpen: ({ isMenuOpen }) => isMenuOpen,
      noError: ({ error }) => !error,

      hasChosenTags: (ctx) =>
        ctx.chosenTags.length > 0 ||
        ctx.selectedCookTimeLimit.length > 0 ||
        ctx.selectedSatietyRange.length > 0,
      hasNoTagsAndNoSearchInput: (ctx) =>
        ctx.searchInput === "" &&
        ctx.tagInput!.length === 0 &&
        ctx.selectedCookTimeLimit.length === 0 &&
        ctx.selectedSatietyRange.length === 0,

      hasNotFetchedTags: (ctx) => ctx.allTags.length === 0,

      isEditRequested: ({ isEditRequested }) => isEditRequested,

      hasLessThanThreeBreakfasts: (context) =>
        (getMealPlanDay(context)?.breakfast?.recipeWithServings?.length ?? 0) < 3,
      hasLessThanThreeLunches: (context) =>
        (getMealPlanDay(context)?.lunch?.recipeWithServings?.length ?? 0) < 3,
      hasLessThanThreeDinners: (context) =>
        (getMealPlanDay(context)?.dinner?.recipeWithServings?.length ?? 0) < 3,

      hasIngredientsList: ({ ingredientsList }) => typeof ingredientsList !== "undefined",

      isMealPlanServingsUniform: (context) => isMealPlanServingsUniform(context),
      isMealPlanDayServingsUniform: (context) => {
        const mealPlanServings = context.mealPlan!.servings ?? 1;
        const day = getMealPlanDay(context)!;
        return isMealPlanDayServingsUniform(mealPlanServings, day);
      },
      copyToTargetHasLessThanThreeRecipes: ({ mealPlan, copyToDay, copyToMeal }) => {
        const rawDay = mealPlan?.schedule.find((day) => day?.name === copyToDay);
        const mealKey = copyToMeal!.toLowerCase() as "breakfast" | "lunch" | "dinner";
        const rawMeal = rawDay![mealKey];
        return (rawMeal.recipeWithServings.length ?? 0) < 3;
      },

      isSelectHistorySuggestions: (ctx) => ctx.selectRecipeHistory === "suggestions",
      isSelectHistoryFavorites: (ctx) => ctx.selectRecipeHistory === "favorites",
    },
  }
);

async function mutateMealPlanSharedFlag(
  mealPlan: MealPlanner_MealPlan | undefined,
  isShared: boolean
): Promise<boolean> {
  assertNonNullMealPlan(
    isShared ? MealPlannerErrorCode.CAN_NOT_SHARE_MEAL_PLAN : MealPlannerErrorCode.CAN_NOT_UNSHARE_MEAL_PLAN,
    mealPlan
  );

  try {
    await client.mutate<
      MealPlanner_SetMealPlanSharedFlagMutation,
      MealPlanner_SetMealPlanSharedFlagMutationVariables
    >({
      mutation: MealPlanner_SetMealPlanSharedFlag,
      variables: { id: mealPlan.id, isShared },
    });
    return isShared;
  } catch (error: any) {
    throw new DDError(
      isShared
        ? MealPlannerErrorCode.FAILED_TO_SHARE_MEAL_PLAN
        : MealPlannerErrorCode.FAILED_TO_UNSHARE_MEAL_PLAN,
      "Server returned with an error",
      error
    );
  }
}

function assertNonNullMealPlan(
  errorCode: MealPlannerErrorCode,
  mealPlan?: MealPlanner_MealPlan
): asserts mealPlan is MealPlanner_MealPlan {
  if (!mealPlan) {
    throw new DDError(errorCode, "Meal plan is empty");
  }
}

type Nullable<T> = T | undefined | null;
