import React from "react";

export const FilterIcon = ({ filterIconStyle }: { filterIconStyle: string }) => {
  return (
    <div style={{ width: "fit-content" }} className={`flex p-1 rounded-3xl ${filterIconStyle}`}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 18H14.5V16H10.5V18ZM3.5 6V8H21.5V6H3.5ZM6.5 13H18.5V11H6.5V13Z" fill="black" />
      </svg>
    </div>
  );
};
