import React from "react";
import { IconProps } from "./types";

export function BackIcon({ className }: IconProps) {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7915 3.80137L16.0215 2.03137L6.02148 12.0314L16.0215 22.0314L17.7915 20.2614L9.56148 12.0314L17.7915 3.80137Z" />
    </svg>
  );
}
