import { t } from "@lingui/macro";
import { bindHover, bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import Popover from "material-ui-popup-state/HoverPopover";
import React from "react";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import styled from "@emotion/styled";
import { default as colors } from "../../../utils/colors.json";

const Container = styled.span`
  color: ${colors.GREEN};
  font-weight: bolder;
  margin-left: 10px;
`;

const TestKitchenLabelPopover = styled(Popover)`
  .MuiPopover-paper {
    font-size: 15px;
    padding: 10px;
    max-width: 350px;
  }
`;

function useTranslations() {
  return {
    label: t({
      id: "TestKitchen.label",
      message: "Test kitchen",
    }),
    message: t({
      id: "TestKitchen.message",
      message:
        "Besides being tested by the original recipe creator, this recipe has also been tested and quality approved by our test kitchen.",
    }),
  };
}

const TestKitchenLabel = ({ id }: { id: string }) => {
  const tt = useTranslations();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `test-kitchen-${id}`,
  });

  return (
    <>
      <Container {...bindHover(popupState)}>
        <FaCheckCircle /> {tt.label}
      </Container>
      <TestKitchenLabelPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus={true}
      >
        {tt.message}
      </TestKitchenLabelPopover>
    </>
  );
};

export default TestKitchenLabel;
