import React from "react";
import { MealPlannerTranslationsContext } from "../../ecosystems/mealplans/mealplan/components/MealPlanner/translations";
import { MealPlanner_Recipe } from "../../types/MealPlanner_Recipe";
import { getEnergyRatingBackgroundColor } from "../../utils/nutrition";

const CarbCircle = ({ recipe, onClick }: { recipe: MealPlanner_Recipe; onClick?: () => void }) => {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const color = getEnergyRatingBackgroundColor(recipe.strictness.rating);
  const carbs = tt.quantities.amountInGrams(recipe.nutrition.values.netCarbs, 0);
  const highProteinBorder = recipe.isHighProtein ? "p-1 bg-light-blue rounded-3xl" : "";

  return (
    <div onClick={onClick} className={`absolute ${highProteinBorder} bottom-1 left-1 cursor-pointer`}>
      <div className={recipe.isHighProtein ? "bg-white p-0.5 rounded-3xl" : ""}>
        <i
          style={{ fontSize: "12px", width: "28px", height: "28px" }}
          className={`z-10 ${color} text-white flex rounded-3xl font-medium items-center justify-center not-italic`}
        >
          {carbs}
        </i>
      </div>
    </div>
  );
};

export default CarbCircle;
