import classNames from "classnames";
import { sha256 } from "js-sha256";
import React, { useContext } from "react";
import { pageContext } from "../../../../../components/PageProvider/PageProvider";
import { print } from "../../../../../utils/print";
import { ScreenSize, useScreenSize } from "../../../../../utils/screen-sizes";
import { useMeasurementUnit } from "../../../../../hooks/useMeasurementUnit/useMeasurementUnit";
import { Locale } from "../../../../../types/Locale";
import { sendEvent } from "../../../../../utils/gtm";
import { IngredientUnit, MeasurementUnit } from "../../../../../utils/units";
import { RemoveIcon } from "../../icons/RemoveIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getEnergyLevel, isMealPlanCopiable, isMealPlanModifiable } from "../../utils";
import { List, ListItem } from "../list";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader } from "./Dialog/DialogHeader";
import { Popover } from "./Popover";
import { ServingsMenuItem } from "./ServingsMenuItem";
import { MealPlannerTranslationsContext } from "./translations";

export const MealPlanMenu = React.memo(MealPlanMenuComponent);

function MealPlanMenuComponent({ state, send }: MealPlanMenuProps) {
  const isOpen = state.matches("planning.viewingMealPlanMenu");
  const isFullScreen = useScreenSize() < ScreenSize.LG;

  const closeMenu = () => send({ type: "CLOSE_VIEW" });

  if (isFullScreen) {
    return (
      <Dialog open={isOpen} onClose={closeMenu}>
        <MealPlanMenuContent state={state} send={send} className="z-20 fixed inset-0" />
      </Dialog>
    );
  } else {
    return (
      <Popover open={isOpen} onClose={closeMenu} className="z-20 absolute inset-x-0 lg:top-full top-0">
        <MealPlanMenuContent state={state} send={send} className="relative rounded-b-2xl" />
      </Popover>
    );
  }
}

interface MealPlanMenuProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function MealPlanMenuContent({ state, send, className = "" }: MealPlanMenuContentProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { mealPlan, userId, isPremium, allowedServings } = state.context;
  const { locale } = useContext(pageContext);
  const { setMeasurementUnit } = useMeasurementUnit();

  const canModifyMealPlan = isMealPlanModifiable(mealPlan!, userId);
  const canCopyMealPlan = isMealPlanCopiable(mealPlan!, isPremium);
  const isStarted = mealPlan!.isActive ?? false;
  const isShared = mealPlan!.isShared ?? false;

  const showIngredients = () => send({ type: "SHOW_MEAL_PLAN_INGREDIENTS_LIST" });
  const showNutrition = () => send({ type: "SHOW_MEAL_PLAN_NUTRITIONAL_INFO" });
  const showInfo = () => send({ type: "SHOW_MEAL_PLAN_INFO" });
  const startMealPlan = () => send({ type: "START_MEAL_PLAN" });
  const stopMealPlan = () => send({ type: "STOP_MEAL_PLAN" });
  const copyMealPlan = () => send({ type: "COPY_MEAL_PLAN" });
  const editMealPlan = () => send({ type: "EDIT_MEAL_PLAN" });
  const shareMealPlan = () => send({ type: "SHARE_MEAL_PLAN" });
  const unshareMealPlan = () => send({ type: "UNSHARE_MEAL_PLAN" });
  const deleteMealPlan = () => send({ type: "DELETE_MEAL_PLAN" });
  const updateServings = (servings: number) => send({ type: "UPDATE_MEAL_PLAN_SERVINGS", servings });

  const handlePrintRecipes = () => {
    sendEvent({
      eventName: "meal_planner",
      action: "print_meal_plan",
      payload: {
        userId: userId ? sha256(userId) : "",
        isPremium: isPremium,
      },
    }),
      print();
  };

  const toggleIngredientUnit = () => {
    if (state.context.ingredientUnit === IngredientUnit.METRIC) {
      send({ type: "TOGGLE_INGREDIENT_UNIT", ingredientUnit: IngredientUnit.IMPERIAL });
      setMeasurementUnit(MeasurementUnit.IMPERIAL);
    } else {
      send({ type: "TOGGLE_INGREDIENT_UNIT", ingredientUnit: IngredientUnit.METRIC });
      setMeasurementUnit(MeasurementUnit.METRIC);
    }
  };

  return (
    <div className={`w-full h-full bg-sand shadow-lg ${className} overflow-y-scroll lg:overflow-auto`}>
      <Header state={state} send={send} className="lg:hidden" />
      <div className="sm:pb-6 flex flex-col items-center">
        <List className="mb-4">
          {canModifyMealPlan && (
            <StartStopMenuItem
              isStarted={isStarted}
              startMealPlan={startMealPlan}
              stopMealPlan={stopMealPlan}
            />
          )}
          <ServingsMenuItem
            key="servings"
            dataTestid="mealplanner-update-mealplan-servings"
            servings={mealPlan!.servings}
            allowedServings={allowedServings}
            updateServings={updateServings}
            loading={state.matches("planning.viewingMealPlanMenu.updatingMealPlanServings.updating")}
            canModify={canModifyMealPlan}
          />
          {locale !== Locale.SV && (
            <SimpleMenuItem
              key="toggleUnit"
              onClick={toggleIngredientUnit}
              label={
                state.context.ingredientUnit === IngredientUnit.IMPERIAL
                  ? tt.mealPlanMenu.toggleToMetric
                  : tt.mealPlanMenu.toggleToImperial
              }
            />
          )}
          <SimpleMenuItem
            key="ingredientsList"
            onClick={showIngredients}
            label={tt.mealPlanMenu.ingredientsListButtonLabel}
          />

          <SimpleMenuItem
            key="nutrition"
            onClick={showNutrition}
            label={tt.mealPlanMenu.nutritionalInfoButtonLabel}
          />
          {canCopyMealPlan && (
            <SimpleMenuItem onClick={copyMealPlan} label={tt.mealPlanMenu.copyMealPlanButtonLabel} />
          )}
        </List>

        <List className="mb-4">
          {canModifyMealPlan && (
            <ShareUnshareMenuItem
              key="share"
              isShared={isShared}
              shareMealPlan={shareMealPlan}
              unshareMealPlan={unshareMealPlan}
            />
          )}
          {canModifyMealPlan && (
            <SimpleMenuItem key="edit" onClick={editMealPlan} label={tt.mealPlanMenu.editMealPlanButtonLabel} />
          )}
          <SimpleMenuItem
            key="print"
            onClick={handlePrintRecipes}
            label={tt.mealPlanMenu.printRecipesButtonLabel}
          />
          <SimpleMenuItem key="about" onClick={showInfo} label={tt.mealPlanMenu.aboutMealPlanButtonLabel} />
        </List>
        <List className="mb-4">
          {canModifyMealPlan && (
            <SimpleMenuItem
              key="delete"
              onClick={deleteMealPlan}
              label={tt.mealPlanMenu.deleteMealPlanButtonLabel}
              icon={<RemoveIcon />}
              dataTestid="mealplanner-delete-mealplan-button"
            />
          )}
        </List>
      </div>
    </div>
  );
}

interface MealPlanMenuContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  className?: string;
}

function Header({ state, send, className }: HeaderProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const mealPlan = state.context.mealPlan!;
  const title = mealPlan.title || tt.defaultMealPlanTitle;
  const energyLevelLabel = tt.energyLevel[getEnergyLevel(mealPlan.isHighProtein, mealPlan.strictness.rating)];

  const isStarted = mealPlan.isActive ?? false;

  const currentClassName = classNames("uppercase font-medium text-sm mb-2", {
    invisible: !isStarted,
  });

  return (
    <DialogHeader state={state} send={send} backButton={false} className={className}>
      <div className="mt-6 sm:mt-0 sm:mb-2 flex flex-col items-center">
        <h2 className="text-center text-xs font-medium uppercase m-0 mb-1">{energyLevelLabel}</h2>
        <h1 className="text-center text-2xl font-medium m-0 mb-4">{title}</h1>
        <span className={currentClassName}>{tt.mealPlanMenu.mealPlanStartedLabel}</span>
      </div>
    </DialogHeader>
  );
}

interface HeaderProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  className?: string;
}

function StartStopMenuItem({ isStarted, startMealPlan, stopMealPlan }: StartStopMenuItemProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const label = isStarted ? tt.mealPlanMenu.stopMealPlanButtonLabel : tt.mealPlanMenu.startMealPlanButtonLabel;
  const handleClick = isStarted ? stopMealPlan : startMealPlan;

  return (
    <ListItem button onClick={handleClick}>
      {label}
    </ListItem>
  );
}

interface StartStopMenuItemProps {
  isStarted: boolean;
  startMealPlan: () => void;
  stopMealPlan: () => void;
}

function ShareUnshareMenuItem({ isShared, shareMealPlan, unshareMealPlan }: ShareUnshareMenuItemProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const label = isShared
    ? tt.mealPlanMenu.unshareMealPlanButtonLabel
    : tt.mealPlanMenu.shareMealPlanButtonLabel;
  const handleClick = isShared ? unshareMealPlan : shareMealPlan;

  return (
    <ListItem button onClick={handleClick}>
      {label}
    </ListItem>
  );
}

interface ShareUnshareMenuItemProps {
  isShared: boolean;
  shareMealPlan: () => void;
  unshareMealPlan: () => void;
}

export function SimpleMenuItem({ onClick, label, icon, disabled, dataTestid }: SimpleMenuItemProps) {
  return (
    <ListItem button onClick={onClick} disabled={disabled} dataTestid={dataTestid}>
      {label}
      {icon}
    </ListItem>
  );
}

interface SimpleMenuItemProps {
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  dataTestid?: string;
}

export function SimpleMenuLink({ href, label, icon, openNewTab = false }: SimpleMenuLinkProps) {
  const newTabArg = openNewTab ? "_blank" : "_self";
  return (
    <ListItem>
      <a
        target={newTabArg}
        href={href}
        className="w-full py-3 px-2 text-black text-left flex justify-between items-center"
        rel="noreferrer"
      >
        {label}
        {icon}
      </a>
    </ListItem>
  );
}

interface SimpleMenuLinkProps {
  href: string;
  label: string;
  icon?: JSX.Element;
  className?: string;
  openNewTab?: boolean;
}
