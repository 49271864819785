import { MealPlanner_MealPlan } from "../../../../../types/MealPlanner_MealPlan";
import { extendedWeekdays } from "../../../../../utils/date";
import { FormattedMealPlan, FormattedMealPlanDay } from "../../types";

export const getWeekdayAndDay = (mealplan: MealPlanner_MealPlan): FormattedMealPlan => {
  const weekdaysArray = extendedWeekdays.map((weekday): FormattedMealPlanDay => {
    return {
      weekday,
      mealplanDay: mealplan.schedule.find((day) => day?.name === weekday) ?? {
        __typename: "Day",
        name: weekday,
        servings: mealplan.servings,
        breakfast: {
          __typename: "Meal",
          active: true,
          recipeWithServings: [],
        },
        lunch: {
          __typename: "Meal",
          active: true,
          recipeWithServings: [],
        },
        dinner: {
          __typename: "Meal",
          active: true,
          recipeWithServings: [],
        },
        strictness: {
          __typename: "RatingValuePair",
          rating: "liberal",
        },
        nutrition: {
          __typename: "Nutrition",
          values: {
            __typename: "NutritionValues",
            calories: 0,
            fat: 0,
            fiber: 0,
            netCarbs: 0,
            protein: 0,
            totalCarbs: 0,
          },
          percentages: {
            __typename: "NutritionPercentages",
            fat: 0,
            netCarbs: 0,
            protein: 0,
          },
        },
      },
    };
  });

  return weekdaysArray;
};
