import React from "react";
import { CloseIcon } from "../../../icons/CloseIcon";
import { BackIcon } from "../../../icons/BackIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../../types";
import { IconButton } from "../../buttons";

export const DialogHeader = React.memo(HeaderComponent);
export const HeaderTitle = React.memo(HeaderTitleComponent);

function HeaderComponent({
  state,
  send,
  backButton = true,
  className = "",
  children,
  menuType,
  onClose = () => send({ type: "CLOSE_VIEW" }),
}: HeaderProps) {
  const { isMenuOpen } = state.context;
  const onBack = () => send({ type: "GO_TO_PREVIOUS_VIEW" });

  const isBackButtonVisible = backButton && isMenuOpen;
  const backButtonClassName = isBackButtonVisible ? "" : "invisible";

  return (
    <header className={`p-4 flex justify-between items-start bg-sand ${className}`}>
      {menuType !== "recipeMenu" && (
        <IconButton kind="default-transparent" spacing="tight" onClick={onBack} className={backButtonClassName}>
          <BackIcon className="w-8 h-8" />
        </IconButton>
      )}
      {children}
      <IconButton data-testid="mealplanner-day-popup-close" kind="default" spacing="tight" onClick={onClose}>
        <CloseIcon className="w-8 h-8" />
      </IconButton>
    </header>
  );
}

type HeaderProps = React.PropsWithChildren<{
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  backButton?: boolean;
  className?: string;
  menuType?: string;
  onClose?: () => void;
}>;

function HeaderTitleComponent({ children }: HeaderTitleProps) {
  return <span className="self-stretch flex items-center text-sm font-medium uppercase">{children}</span>;
}

type HeaderTitleProps = React.PropsWithChildren<{}>;
