import React from "react";
import { SuccessIcon } from "../../icons/SuccessIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { Button } from "../buttons";
import { Dialog } from "./Dialog/Dialog";
import { MealPlannerTranslationsContext } from "./translations";

export const ActionConfirmationDialog = React.memo(ActionConfirmationDialogComponent);

function ActionConfirmationDialogComponent({ state, send }: ActionConfirmationDialogProps) {
  const copy = useActionConfirmationCopy(state);
  const isOpen = typeof copy !== "undefined";
  const confirmAction = () => send({ type: "CONFIRM_ACTION" });
  const cancelAction = () => send({ type: "CANCEL_ACTION" });

  if (!copy) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={cancelAction}>
      <div className="z-10 max-w-3/4 sm:max-w-sm rounded-xl shadow-lg bg-sand overflow-hidden">
        <div className="p-8 flex flex-col gap-y-4 justify-center items-center">
          <SuccessIcon className="h-16 w-16 text-blue fill-current opacity-40" />
          <h1 className="m-0 text-lg text-center">{copy.title}</h1>
          {copy.message}
        </div>
        <div className="w-full flex">
          <Button
            data-testid="cancel-button"
            kind="base"
            onClick={cancelAction}
            className="flex-1 py-4 bg-white text-red font-medium border-r border-sand"
          >
            {copy.cancelButtonLabel}
          </Button>
          <Button
            data-testid="ok-button"
            kind="base"
            onClick={confirmAction}
            className="flex-1 py-4 bg-white text-light-blue font-medium"
          >
            {copy.confirmButtonLabel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

interface ActionConfirmationDialogProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function useActionConfirmationCopy(state: MealPlanMachineState) {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const messages = React.useMemo(() => {
    const confirmButtonLabel = tt.actionConfirmationDialog.confirmButtonLabel;
    const cancelButtonLabel = tt.actionConfirmationDialog.cancelButtonLabel;

    return Object.entries({
      "planning.viewingMealPlanMenu.updatingMealPlanServings.confirmingAction": {
        confirmButtonLabel,
        cancelButtonLabel,
        title: tt.actionConfirmationDialog.updateMealPlanServings.title,
        message: tt.actionConfirmationDialog.updateMealPlanServings.message,
      },
      "planning.viewingDayMenu.updatingMealPlanDayServings.confirmingAction": {
        confirmButtonLabel,
        cancelButtonLabel,
        title: tt.actionConfirmationDialog.updateMealPlanDayServings.title,
        message: tt.actionConfirmationDialog.updateMealPlanDayServings.message,
      },
    });
  }, [tt]);

  const message = React.useMemo(() => {
    for (const [stateName, message] of messages) {
      if (state.matches(stateName)) {
        return message;
      }
    }

    return undefined;
  }, [state, messages]);

  return message;
}
