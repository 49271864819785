import React from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { LoadingIcon } from "../../icons/LoadingIcon";
import { ListItem } from "../list";
import { MealPlannerTranslationsContext } from "./translations";

export const ServingsMenuItem = React.memo(ServingsMenuItemComponent);

function ServingsMenuItemComponent({
  servings,
  allowedServings,
  updateServings,
  canModify,
  loading,
  dataTestid,
}: ServingsMenuItemProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const controlsVisibility = loading ? "invisible" : "visible";
  const loadingVisibility = loading ? "flex" : "hidden";

  const { options, valid } = React.useMemo(() => {
    const valid = allowedServings.includes(servings);
    return {
      options: valid ? allowedServings : [servings, ...allowedServings].sort((a, b) => a - b),
      valid,
    };
  }, [servings, allowedServings]);

  const changeServings = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateServings(parseInt(event.target.value, 10));
  };

  const controls = canModify ? (
    <div className="relative border border-sand rounded-full overflow-hidden bg-white">
      <select
        data-testid={dataTestid}
        value={servings}
        onChange={changeServings}
        className={`w-full pl-4 pr-10 py-1 appearance-none ${controlsVisibility}`}
      >
        {options.map((option) => (
          <option key={option} disabled={option === servings && !valid}>
            {option}
          </option>
        ))}
      </select>
      <div className={`absolute inset-y-0 right-4 flex items-center pointer-events-none ${controlsVisibility}`}>
        <FaChevronDown />
      </div>
      <div className={`absolute inset-0 justify-center items-center ${loadingVisibility}`}>
        <LoadingIcon className="w-7 h-7 animate-spin" />
      </div>
    </div>
  ) : (
    <span>{servings}</span>
  );

  return (
    <ListItem>
      <div className="py-2 px-2 flex justify-between items-center">
        <span>{tt.mealPlanMenu.servingsLabel}</span>
        {controls}
      </div>
    </ListItem>
  );
}

interface ServingsMenuItemProps {
  servings: number;
  allowedServings: number[];
  updateServings: (servings: number) => void;
  canModify: boolean;
  loading: boolean;
  dataTestid?: string;
}
