import React from "react";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { Dialog } from "../MealPlanner/Dialog/Dialog";
import RecipeSelectorContent from "./RecipeSelectorContent";
import RecipeSelectorHeader from "./RecipeSelectorHeader";

export const RecipeSelectorDialog = React.memo(RecipeSelectorDialogComponent);

function RecipeSelectorDialogComponent({ state, send }: RecipeSelectorDialogProps) {
  const isDialogOpen = state.matches("planning.selectRecipe");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <RecipeSelector state={state} send={send} />
    </Dialog>
  );
}

interface RecipeSelectorDialogProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function RecipeSelector({ state, send }: RecipeSelectorDialogProps) {
  return (
    <div
      style={{ maxWidth: "1200px" }}
      className="z-10 fixed sm:static inset-0 sm:inset-auto sm:w-3/4 sm:h-3/4 sm:rounded-xl sm:shadow-lg bg-white overflow-y-scroll"
    >
      <RecipeSelectorHeader state={state} send={send} />
      <RecipeSelectorContent state={state} send={send} />
    </div>
  );
}
