import React from "react";
import { IconProps } from "./types";

export const ErrorIcon = React.memo(ErrorIconComponent);

function ErrorIconComponent({ className }: IconProps) {
  return (
    <svg className={className} width="76" height="76" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.6534 22.5679L37.9409 32.2804L28.2284 22.5679L22.9409 27.8554L32.6534 37.5679L22.9409 47.2804L28.2284 52.5679L37.9409 42.8554L47.6534 52.5679L52.9409 47.2804L43.2284 37.5679L52.9409 27.8554L47.6534 22.5679ZM37.9409 0.0678711C17.2034 0.0678711 0.440918 16.8304 0.440918 37.5679C0.440918 58.3054 17.2034 75.0679 37.9409 75.0679C58.6784 75.0679 75.4409 58.3054 75.4409 37.5679C75.4409 16.8304 58.6784 0.0678711 37.9409 0.0678711ZM37.9409 67.5679C21.4034 67.5679 7.94092 54.1054 7.94092 37.5679C7.94092 21.0304 21.4034 7.56787 37.9409 7.56787C54.4784 7.56787 67.9409 21.0304 67.9409 37.5679C67.9409 54.1054 54.4784 67.5679 37.9409 67.5679Z" />
    </svg>
  );
}
