import { sha256 } from "js-sha256";
import React from "react";
import { sendEvent } from "../../../../../../utils/gtm";
import { IngredientsListIcon } from "../../../icons/IngredientsListIcon";
import { MoreIcon } from "../../../icons/MoreIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../../types";
import { isMealPlanCopiable, isMealPlanModifiable } from "../../../utils";
import { Button, IconButton } from "../../buttons";
import { MealPlannerTranslationsContext } from "../translations";

export const Toolbar = React.memo(ToolbarComponent);

function ToolbarComponent({ state, send }: ToolbarProps) {
  const { userId, isPremium } = state.context;
  const {
    mealPlanMenu: { ingredientsListButtonLabel, printRecipesButtonLabel },
  } = React.useContext(MealPlannerTranslationsContext);
  const toolbarClassName = "flex flex-row justify-between lg:justify-start lg:mt-4 items-center gap-x-2 w-full";

  const primaryButton = getPrimaryButton(state, send);

  const handleIngredientsListClick = () => send({ type: "SHOW_MEAL_PLAN_INGREDIENTS_LIST" });

  const handlePrintRecipesClick = () => {
    sendEvent({
      eventName: "meal_planner",
      action: "print_meal_plan",
      payload: {
        userId: userId ? sha256(userId) : "",
        isPremium: isPremium,
      },
    }),
      print();
  };

  return (
    <div className={toolbarClassName}>
      <IngredientsListButton state={state} send={send} />
      {primaryButton}
      <Button
        kind="default"
        style={{ fontWeight: 500 }} //overrides the font-medium
        className="hidden lg:block"
        onClick={handleIngredientsListClick}
      >
        {ingredientsListButtonLabel}
      </Button>
      <Button
        style={{ fontWeight: 500, paddingLeft: "8px", paddingRight: "8px" }} //overrides the font-medium
        kind="default"
        className="hidden lg:block"
        onClick={handlePrintRecipesClick}
      >
        {printRecipesButtonLabel}
      </Button>
      <div data-testid="meal-plan-menu-button">
        <MenuButton state={state} send={send} dataTestid="mealplanner-context-menu" />
      </div>
    </div>
  );
}

interface ToolbarProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function IngredientsListButton({ send }: ButtonProps) {
  const showMealPlanIngredientList = () => send({ type: "SHOW_MEAL_PLAN_INGREDIENTS_LIST" });
  return (
    <IconButton kind="default" onClick={showMealPlanIngredientList} className="block lg:hidden">
      <IngredientsListIcon className={iconClassName} />
    </IconButton>
  );
}

function MenuButton({ send, dataTestid }: ButtonProps) {
  const showMealPlanInfo = () => send({ type: "SHOW_MEAL_PLAN_MENU" });
  return (
    <IconButton kind="default" onClick={showMealPlanInfo} data-testid={dataTestid}>
      <MoreIcon className={iconClassName} />
    </IconButton>
  );
}

function StartButton({ state, send }: ButtonProps) {
  const {
    toolbar: { startMealPlanButtonLabel },
  } = React.useContext(MealPlannerTranslationsContext);
  const isStarting = state.matches("planning.startingMealPlan");
  const isStarted = state.context.mealPlan?.isActive ?? false;
  const isDisabled = isStarting || isStarted;

  const startMealPlan = () => send({ type: "START_MEAL_PLAN" });

  return (
    <>
      {!isStarted && (
        <Button
          kind="primary"
          onClick={startMealPlan}
          disabled={isDisabled}
          className={toolbarMainComponent}
          data-testid="start-mealplan-button"
        >
          {startMealPlanButtonLabel}
        </Button>
      )}
    </>
  );
}

function CopyButton({ state, send }: ButtonProps) {
  const {
    toolbar: { copyMealPlanButtonLabel },
  } = React.useContext(MealPlannerTranslationsContext);
  const isCopying = state.matches("planning.copyingMealPlan");
  const copyMealPlan = () => send({ type: "COPY_MEAL_PLAN" });

  return (
    <Button
      kind="primary"
      onClick={copyMealPlan}
      disabled={isCopying}
      className={toolbarMainComponent}
      style={{ paddingLeft: "8px", paddingRight: "8px" }}
    >
      {copyMealPlanButtonLabel}
    </Button>
  );
}

function getPrimaryButton(state: MealPlanMachineState, send: MealPlanMachineSend) {
  const { mealPlan, userId, isPremium } = state.context;
  const canStart = isMealPlanModifiable(mealPlan!, userId);
  const canCopy = isMealPlanCopiable(mealPlan!, isPremium);

  if (canStart) {
    return <StartButton state={state} send={send} />;
  }

  if (canCopy) {
    return <CopyButton state={state} send={send} />;
  }

  return null;
}

interface ButtonProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  dataTestid?: string;
}

const iconClassName = "w-8 h-8";

const toolbarMainComponent = "flex items-center justify-center flex-grow lg:grow-0 font-medium";
