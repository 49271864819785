import React from "react";
import styled from "@emotion/styled";
import {
  GetMealplanBySlug_mealplanBySlug_schedule_breakfast_recipesDetails_tips,
  GetMealplanBySlug_mealplanBySlug_schedule_dinner_recipesDetails_tips,
  GetMealplanBySlug_mealplanBySlug_schedule_lunch_recipesDetails_tips,
} from "../../../types/GetMealplanBySlug";
import { htmlContent } from "../../../utils/html";

type RecipeTip =
  | GetMealplanBySlug_mealplanBySlug_schedule_breakfast_recipesDetails_tips
  | GetMealplanBySlug_mealplanBySlug_schedule_lunch_recipesDetails_tips
  | GetMealplanBySlug_mealplanBySlug_schedule_dinner_recipesDetails_tips;

const Title = styled.h3`
  font-size: 40px;
  font-weight: 600;
  margin: 54px 0 4px;
  line-height: 1.125;
  letter-spacing: -0.7px;

  @media print {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Subtitle = styled.h4`
  font-size: 32px;
  font-weight: 600;
  margin: 27px 0 12px;
  line-height: 1.125;
  letter-spacing: -0.25px;
`;
const Paragraph = styled.p`
  margin-bottom: 30px;

  @media print {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Tip = ({ tip, isFirst }: { tip: RecipeTip; isFirst: boolean }) => {
  const TitleComponent = isFirst ? Title : Subtitle;
  return (
    <>
      <TitleComponent>{tip.header}</TitleComponent>
      {tip.content.map((contentItem, index) => (
        <Paragraph key={index} dangerouslySetInnerHTML={htmlContent(contentItem)} />
      ))}
    </>
  );
};

const Tips = ({ tips }: { tips: RecipeTip[] }) => {
  return (
    <div data-testid="tips-container">
      {tips.map((tip, index) => (
        <Tip key={index} tip={tip} isFirst={index === 0} />
      ))}
    </div>
  );
};

export default Tips;
