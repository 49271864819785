import React, { memo, useContext } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { GetPageContext } from "../../../pages";
import { MealPlanPage } from "../pages";
import { MealPlanner } from "./components/MealPlanner/MealPlanner";

type MealPlanPageContext = GetPageContext<typeof MealPlanPage>;

// ts-prune-ignore-next
export default memo(MealPlanPageComponent);

function MealPlanPageComponent() {
  const {
    params: { slug },
    query: { edit },
  } = useContext<MealPlanPageContext>(pageContext);

  return <MealPlanner key={slug} slug={slug} edit={edit ?? false} />;
}
