import React from "react";

export function ShareRecipeIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M16.9563 5.57996L15.5363 6.99996L13.9463 5.40996V16.58H11.9663V5.40996L10.3763 6.99996L8.9563 5.57996L12.9563 1.57996L16.9563 5.57996ZM20.9563 10.58V21.58C20.9563 22.68 20.0563 23.58 18.9563 23.58H6.9563C5.8463 23.58 4.9563 22.68 4.9563 21.58V10.58C4.9563 9.46996 5.8463 8.57996 6.9563 8.57996H9.9563V10.58H6.9563V21.58H18.9563V10.58H15.9563V8.57996H18.9563C20.0563 8.57996 20.9563 9.46996 20.9563 10.58Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
