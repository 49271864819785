import React from "react";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import FavoritesTab from "./FavoritesTab";
import SearchTab from "./SearchTab";
import SuggestionsTab from "./SuggestionsTab";

export default React.memo(RecipeSelectorContent);
function RecipeSelectorContent({ state, send }: { state: MealPlanMachineState; send: MealPlanMachineSend }) {
  const isSuggestionsTabActive = state.matches("planning.selectRecipe.suggestions");
  const isFavoritesTabActive = state.matches("planning.selectRecipe.favorites");
  const isSearchTabActive = state.matches("planning.selectRecipe.search");

  return (
    <div className="bg-white h-auto sm:rounded-b-3xl">
      {isSuggestionsTabActive && <SuggestionsTab state={state} send={send} />}
      {isFavoritesTabActive && <FavoritesTab state={state} send={send} />}
      {isSearchTabActive && <SearchTab state={state} send={send} />}
    </div>
  );
}
