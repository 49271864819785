import React from "react";
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import { MdKeyboardArrowUp } from "@react-icons/all-files/md/MdKeyboardArrowUp";
import { MealType } from "../../../../../types/graphql-global-types";
import { DayKey } from "../../../../../types/MealPlan";
import { upperFirst } from "../../../../../utils/string";
import { FormattedMealPlanDay, MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { DayMenu } from "./DayMenu";
import { MealPlanRecipe } from "./MealPlanRecipe";
import { MealPlannerTranslationsContext } from "./translations";
import { isMealPlanCopiable } from "../../utils";

export default React.memo(MealPlanDay);

function MealPlanDay({
  dayPayload,
  state,
  send,
}: {
  dayPayload: FormattedMealPlanDay;
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const dayTitle = upperFirst(tt.dates.weekdayLong(dayPayload.weekday));
  const breakfastRecipes = dayPayload.mealplanDay.breakfast.recipeWithServings;
  const lunchRecipes = dayPayload.mealplanDay.lunch.recipeWithServings;
  const dinnerRecipes = dayPayload.mealplanDay.dinner.recipeWithServings;
  const { mealPlan, isPremium } = state.context;
  const canCopy = isMealPlanCopiable(mealPlan!, isPremium);

  const amountOfMeals =
    (breakfastRecipes.length > 0 ? 1 : 0) +
    (lunchRecipes.length > 0 ? 1 : 0) +
    (dinnerRecipes.length > 0 ? 1 : 0);

  const breakfastHasBorder = lunchRecipes.length + dinnerRecipes.length > 0;
  const lunchHasBorder = dinnerRecipes.length > 0;

  const mealArray = [breakfastRecipes, lunchRecipes, dinnerRecipes];

  const mostRecipesPerMeal = Math.max(...mealArray.map((meal) => meal.length));

  const amountOfRows =
    mostRecipesPerMeal === 1
      ? "lg:grid-rows-1"
      : mostRecipesPerMeal === 2
      ? "lg:grid-rows-2"
      : "lg:grid-rows-3";

  const missingBreakfasts = new Array(mostRecipesPerMeal - breakfastRecipes.length).fill("");
  const missingLunches = new Array(mostRecipesPerMeal - lunchRecipes.length).fill("");
  const missingDinners = new Array(mostRecipesPerMeal - dinnerRecipes.length).fill("");

  const missingBreakfastStyle = breakfastRecipes.length === 0 ? "hidden lg:block" : "";
  const missingLunchStyle = lunchRecipes.length === 0 ? "hidden lg:block" : "";
  const missingDinnerStyle = dinnerRecipes.length === 0 ? "hidden lg:block" : "";

  const handleClickOnDayMenu = () =>
    send({ type: "TOGGLE_DAY_MENU", day: dayPayload.weekday, history: "dayMenu" });

  const showDayMenu =
    state.matches("planning.viewingDayMenu") && state.context.currentDay === dayPayload.weekday;

  return (
    <div className="lg:mb-5" data-testid="mealplanner-day-recipes-container">
      <div className="px-4">
        <div className="lg:relative">
          <div className="flex items-center justify-center">
            <div
              className="flex items-center px-8 py-1 bg-sand rounded-3xl cursor-pointer"
              onClick={handleClickOnDayMenu}
              data-testid="mealplanner-day-menu"
            >
              <h3 className="m-0 pr-2 font-medium text-2xl">{dayTitle}</h3>
              {showDayMenu ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </div>
            {showDayMenu && <DayMenu state={state} send={send} weekDay={dayPayload.weekday} />}
          </div>
        </div>
        {amountOfMeals !== 0 && (
          <div className="grid grid-cols-1 lg:grid-rows-1 lg:grid-cols-3 lg:gap-x-6 gap-y-4 mt-4">
            <div data-testid="mealplanner-breakfast-recipe">
              <p className={`font-medium text-lg py-1 m-0 ${missingBreakfastStyle}`}>
                {tt.meals.mealLabel(MealType.BREAKFAST, true)}
              </p>
              <div className={`grid ${amountOfRows} gap-y-4`}>
                {breakfastRecipes.map((recipeWithServings, index) => (
                  <MealPlanRecipe
                    key={`${recipeWithServings!.recipe!.id}-${index}`}
                    recipeWithServings={recipeWithServings!}
                    state={state}
                    send={send}
                    meal={MealType.BREAKFAST}
                    day={dayPayload.weekday}
                    index={index}
                  />
                ))}
                {missingBreakfasts.length !== 0 &&
                  missingBreakfasts.map((emptyRecipe: string, index: number) => (
                    <MissingRecipe
                      key={index}
                      isMealPlanCopiable={canCopy}
                      send={send}
                      meal={MealType.BREAKFAST}
                      day={dayPayload.weekday}
                    />
                  ))}
              </div>
              {breakfastHasBorder && (
                <div className={`w-full bg-lighter-grey h-0.5 my-5 lg:h-0 block ${missingBreakfastStyle}`} />
              )}
            </div>

            <div data-testid="mealplanner-lunch-recipe">
              <p className={`font-medium text-lg py-1 m-0 ${missingLunchStyle}`}>
                {tt.meals.mealLabel(MealType.LUNCH, true)}
              </p>
              <div className={`grid ${amountOfRows} gap-y-4`}>
                {lunchRecipes.map((recipeWithServings, index) => (
                  <MealPlanRecipe
                    key={`${recipeWithServings!.recipe!.id}-${index}`}
                    recipeWithServings={recipeWithServings!}
                    state={state}
                    send={send}
                    meal={MealType.LUNCH}
                    day={dayPayload.weekday}
                    index={index}
                  />
                ))}
                {missingLunches.length !== 0 &&
                  missingLunches.map((emptyRecipe: string, index: number) => (
                    <MissingRecipe
                      key={index}
                      isMealPlanCopiable={canCopy}
                      send={send}
                      meal={MealType.LUNCH}
                      day={dayPayload.weekday}
                    />
                  ))}
              </div>
              {lunchHasBorder && (
                <div className={`w-full bg-lighter-grey h-0.5 my-5 lg:h-0 block ${missingLunchStyle}`} />
              )}
            </div>

            <div data-testid="mealplanner-dinner-recipe">
              <p className={`font-medium text-lg py-1 m-0 ${missingDinnerStyle}`}>
                {tt.meals.mealLabel(MealType.DINNER, true)}
              </p>

              <div className={`grid ${amountOfRows} gap-y-4`}>
                {dinnerRecipes.map((recipeWithServings, index) => (
                  <MealPlanRecipe
                    key={`${recipeWithServings!.recipe!.id}-${index}`}
                    recipeWithServings={recipeWithServings!}
                    state={state}
                    send={send}
                    meal={MealType.DINNER}
                    day={dayPayload.weekday}
                    index={index}
                  />
                ))}
                {missingDinners.length !== 0 &&
                  missingDinners.map((emptyRecipe: string, index: number) => (
                    <MissingRecipe
                      key={index}
                      isMealPlanCopiable={canCopy}
                      send={send}
                      meal={MealType.DINNER}
                      day={dayPayload.weekday}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {amountOfMeals === 0 && <MissingMeal handleClickOnDayMenu={handleClickOnDayMenu} />}
      <div className="h-2 w-full bg-sand my-8"></div>
    </div>
  );
}

const MissingMeal = ({ handleClickOnDayMenu }: { handleClickOnDayMenu: () => void }) => {
  const tt = React.useContext(MealPlannerTranslationsContext);

  return (
    <div className="px-6 cursor-pointer">
      <div
        className="my-5 relative justify-center items-center flex cursor-pointer"
        style={{ height: "139px" }}
        onClick={handleClickOnDayMenu}
      >
        <p className="absolute z-10">{tt.mealPlanGrid.addMealButtonLabel}</p>
        <div className="w-full rounded-3xl bg-lighter-grey opacity-50 h-full"></div>
      </div>
    </div>
  );
};

const MissingRecipe = ({
  isMealPlanCopiable,
  send,
  meal,
  day,
}: {
  isMealPlanCopiable: boolean;
  send: MealPlanMachineSend;
  meal: MealType;
  day: DayKey;
}) => {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const handleAddRecipe = () => {
    send({ type: "SET_CURRENT_DAY", day });

    if (meal === MealType.BREAKFAST) {
      send({ type: "ADD_BREAKFAST", meal });
    } else if (meal === MealType.LUNCH) {
      send({ type: "ADD_LUNCH", meal });
    } else {
      send({ type: "ADD_DINNER", meal });
    }
  };

  // isMealPlanCopiable means that this is not a user
  // meal plan but one created by us that can be copied.
  // in this case, it should not be possible to add a
  // recipe before copying it
  return isMealPlanCopiable ? (
    <div className="grid grid-rows-1 grid-cols-8 w-full bg-lighter-grey opacity-50 rounded-xl h-full">
      <div className="col-span-3 pointer-events-none">
        <div style={{ paddingTop: "100%" }} />
      </div>
    </div>
  ) : (
    <div
      onClick={handleAddRecipe}
      className="grid grid-rows-1 grid-cols-8 w-full bg-lighter-grey opacity-50 rounded-xl h-full cursor-pointer"
    >
      <div className="col-span-3 pointer-events-none">
        <div style={{ paddingTop: "100%" }} />
      </div>
      <p className="col-span-5 my-auto">{tt.mealPlanGrid.missingRecipePlaceholderTitle}</p>
    </div>
  );
};
