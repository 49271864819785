import React from "react";
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft";
import { CloseIcon } from "../../icons/CloseIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { IconButton } from "../buttons";
import { MealPlannerTranslationsContext } from "../MealPlanner/translations";

export default React.memo(RecipeSelectorHeader);

function RecipeSelectorHeader({ state, send }: { state: MealPlanMachineState; send: MealPlanMachineSend }) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const dayAndMeal = `${tt.dates.weekdayLong(state.context.currentDay!)} ${tt.meals.mealLabel(
    state.context.currentMeal!
  )}`;
  const chosenTab = "bg-green font-medium";
  const unchosenTab = " font-medium cursor-pointer";

  const suggestionsChosen = state.matches("planning.selectRecipe.suggestions") ? chosenTab : unchosenTab;
  const favoritesChosen = state.matches("planning.selectRecipe.favorites") ? chosenTab : unchosenTab;
  const searchChosen = state.matches("planning.selectRecipe.search") ? chosenTab : unchosenTab;

  const textColorSuggestions = state.matches("planning.selectRecipe.suggestions") ? "text-black" : "text-grey";
  const textColorFavorites = state.matches("planning.selectRecipe.favorites") ? "text-black" : "text-grey";
  const textColorSearch = state.matches("planning.selectRecipe.search") ? "text-black" : "text-grey";

  const handleClickOnSuggestions = () => send({ type: "SHOW_SUGGESTIONS_TAB" });
  const handleClickOnFavorites = () => {
    return send({ type: "SHOW_FAVORITES_TAB" });
  };
  const handleClickOnSearch = () => send({ type: "SHOW_SEARCH_TAB" });
  const handleBack = () => {
    if (state.context.history === "recipeMenu") {
      send({ type: "GO_TO_RECIPE_MENU_IDLE" });
    } else if (state.context.history === "dayMenu") {
      send({ type: "GO_TO_DAY_MENU_IDLE" });
    }
  };

  const handleClickOnCloseIcon = () => send({ type: "CLOSE_VIEW" });

  return (
    <div className="bg-grey-meal-planner sticky top-0 z-30">
      <div className="flex justify-between items-center">
        <button className="left-0 cursor-pointer pl-5 pt-5" onClick={handleBack}>
          <MdKeyboardArrowLeft size="30" />
        </button>
        <h3 className="text-sm font-medium m-0 pt-6 uppercase">{dayAndMeal}</h3>

        <IconButton kind="default" spacing="tight" className="mt-5 mr-5" onClick={handleClickOnCloseIcon}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="flex justify-evenly mt-10 lg:w-6/12 lg:mx-auto">
        <div>
          <button
            data-testid="mealplanner-suggestions-tab"
            onClick={handleClickOnSuggestions}
            className={`${textColorSuggestions} font-medium px-0`}
          >
            {tt.recipeSelectorDialog.recipeSuggestionsTabLabel}
          </button>
          <div style={{ width: "30px", height: "2px" }} aria-hidden className={suggestionsChosen}></div>
        </div>
        <div>
          <button
            data-testid="mealplanner-favorites-tab"
            onClick={handleClickOnFavorites}
            className={`${textColorFavorites} font-medium px-0`}
          >
            {tt.recipeSelectorDialog.favoriteRecipesTabLabel}
          </button>
          <div style={{ width: "30px", height: "2px" }} aria-hidden className={favoritesChosen}></div>
        </div>
        <div>
          <button
            data-testid="mealplanner-search-tab"
            onClick={handleClickOnSearch}
            className={`${textColorSearch} font-medium px-0`}
          >
            {tt.recipeSelectorDialog.searchRecipesTabLabel}
          </button>
          <div style={{ width: "30px", height: "2px" }} aria-hidden className={searchChosen}></div>
        </div>
      </div>
    </div>
  );
}
