import React from "react";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { MealPlanMachineSend, MealPlanMachineState, MealPlannerErrorCode, Message } from "../../types";
import { Button } from "../buttons";
import { Dialog } from "./Dialog/Dialog";
import { MealPlannerTranslations, MealPlannerTranslationsContext } from "./translations";

export const ErrorDialog = React.memo(ErrorDialogComponent);

function ErrorDialogComponent({ state, send }: ErrorDialogProps) {
  const isOpen = state.matches("planning.confirmingError");
  const errorCode = state.context.error?.code;
  const confirmError = () => send({ type: "CONFIRM_ERROR" });

  return (
    <Dialog open={isOpen} onClose={confirmError}>
      <ErrorDialogContent errorCode={errorCode} confirmError={confirmError} />
    </Dialog>
  );
}

interface ErrorDialogProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function ErrorDialogContent({ errorCode, confirmError }: ErrorDialogContentProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { title, description } = useErrorMessage(tt, errorCode);

  return (
    <div className="z-10 max-w-3/4 sm:max-w-sm rounded-xl shadow-lg bg-sand overflow-hidden">
      <div className="p-8 flex flex-col gap-y-4 justify-center items-center">
        <ErrorIcon className="h-16 w-16 text-red fill-current" />
        <h1 className="m-0 text-lg">{title}</h1>
        <p className="m-0">{description}</p>
      </div>
      <Button kind="base" onClick={confirmError} className="w-full py-4 bg-white text-light-blue font-medium">
        {tt.errorDialog.confirmErrorButtonLabel}
      </Button>
    </div>
  );
}

interface ErrorDialogContentProps {
  errorCode?: MealPlannerErrorCode;
  confirmError: () => void;
}

function useErrorMessage(tt: MealPlannerTranslations, errorCode?: MealPlannerErrorCode): Message {
  const errorMessage = errorCode && tt.errorDialog.messages[errorCode];
  const message = (
    errorCode === MealPlannerErrorCode.TOO_MANY_RECIPES
      ? errorMessage
      : {
          ...tt.errorDialog.defaultMessage,
          ...errorMessage,
        }
  ) as Message;
  return message;
}
