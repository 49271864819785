import React from "react";
import classNames from "classnames";
import { InfoIcon } from "../icons/InfoIcon";

export const Alert = React.memo(AlertComponent);

function AlertComponent({ severity, className = "", children }: AlertProps) {
  const { icon, colors } = getSeverityStyling(severity);

  const containerClassName = classNames("px-6 py-4 flex items-start gap-x-2 text-sm", colors, className);

  return (
    <div className={containerClassName}>
      {icon}
      <span className="mt-1">{children}</span>
    </div>
  );
}

type AlertProps = React.PropsWithChildren<{
  severity: Severity;
  className?: string;
}>;

type Severity = "info";

function getSeverityStyling(severity: Severity) {
  switch (severity) {
    case "info":
      return { icon: <InfoIcon className="fill-current" />, colors: "bg-lighter-blue text-blue" };

    default:
      return { icon: null, colors: "" };
  }
}
