import React from "react";
import RecipeSection from "../../../../../components/RecipeSection/RecipeSection";
import { MealPlanMachineState } from "../../types";
import { groupSameRecipesTogether, isMealPlanModifiable } from "../../utils";
import { MealPlannerTranslationsContext } from "./translations";

export const PrintableRecipes = React.memo(PrintableRecipesComponent);

function PrintableRecipesComponent({ state }: PrintableRecipesProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { mealPlan, formattedMealPlan, userId, ingredientUnit } = state.context;
  const canModifyMealPlan = isMealPlanModifiable(mealPlan!, userId);

  const adjustInstructionTagId = state.context.allTags.find(
    (tag) => tag.title === "instructions-do-not-scale-with-servings"
  )?.id;

  const recipes = groupSameRecipesTogether(formattedMealPlan, !canModifyMealPlan);

  return (
    <div>
      {recipes.length === 0 && <div>{tt.printableRecipes.noRecipesMessage}</div>}
      {recipes.map(({ recipe, servings, meals }) => (
        <RecipeSection
          key={`${recipe!.id}-${servings}`}
          recipe={recipe as any}
          meals={meals}
          unit={ingredientUnit}
          servingSize={servings}
          adjustInstructionTagId={adjustInstructionTagId}
        />
      ))}
    </div>
  );
}

interface PrintableRecipesProps {
  state: MealPlanMachineState;
}
