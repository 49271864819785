import React from "react";

export function RemoveIcon() {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M10.485 14.1229C10.872 14.1229 11.1887 13.8138 11.1887 13.436V6.29142C11.1887 5.91359 10.872 5.60445 10.485 5.60445C10.0979 5.60445 9.78125 5.91359 9.78125 6.29142V13.436C9.78125 13.8138 10.0979 14.1229 10.485 14.1229Z"
          fill="#727171"
        />
        <path
          d="M13.4051 14.0886C13.4403 14.0886 13.4755 14.0886 13.5107 14.0886C13.8626 14.0886 14.144 13.8481 14.2144 13.5046L15.1644 6.39446C15.1996 6.01663 14.9533 5.67314 14.5663 5.63879C14.1792 5.60444 13.8274 5.84488 13.7922 6.22272L12.8422 13.3329C12.7366 13.7107 13.0181 14.0542 13.4051 14.0886Z"
          fill="#727171"
        />
        <path
          d="M6.79041 13.5046C6.82559 13.8481 7.14226 14.0886 7.49411 14.0886C7.5293 14.0886 7.56448 14.0886 7.59967 14.0886C7.9867 14.0542 8.26818 13.6764 8.19781 13.3329L7.21263 6.2227C7.17744 5.84486 6.82559 5.57007 6.43855 5.63877C6.05152 5.67312 5.77004 6.05096 5.84041 6.39444L6.79041 13.5046Z"
          fill="#727171"
        />
        <path
          d="M19.2812 2.71916H15.0238V1.89479C15.0238 1.20781 14.2849 0.726929 13.2645 0.726929H7.70523C6.68486 0.726929 5.94597 1.20781 5.94597 1.89479V2.68481H1.68857C1.30153 2.68481 0.984863 2.99394 0.984863 3.37178C0.984863 3.74962 1.30153 4.05876 1.68857 4.05876H2.77931L4.15153 15.703V15.8404C4.36264 16.6647 5.13672 17.2487 6.01635 17.2487H14.9534C15.833 17.2487 16.6071 16.6647 16.8182 15.8404L18.2256 4.0931H19.2812C19.6682 4.0931 19.9849 3.78397 19.9849 3.40613C19.9849 3.02829 19.6682 2.71916 19.2812 2.71916ZM7.35338 2.13523C7.42375 2.10088 7.52931 2.10088 7.70523 2.10088H13.2997C13.4404 2.10088 13.546 2.13523 13.6515 2.13523V2.71916H7.35338V2.13523ZM15.446 15.5312C15.3756 15.7373 15.1645 15.8747 14.9534 15.8747H6.01635C5.80523 15.8747 5.59412 15.7373 5.52375 15.5312L4.18672 4.0931H16.783L15.446 15.5312Z"
          fill="#727171"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="19" height="16.5217" fill="white" transform="translate(0.984863 0.726929)" />
        </clipPath>
      </defs>
    </svg>
  );
}
