import React from "react";
import classNames from "classnames";
import { Button } from "./buttons";

export const List = React.memo(ListComponent);
export const ListItem = React.memo(ListItemComponent);

function ListComponent({ className: extraClassName = "", children }: ListProps) {
  const className = classNames(defaultListClassName, extraClassName);

  return <ul className={className}>{children}</ul>;
}

type ListProps = React.PropsWithChildren<{
  className?: string;
}>;

function ListItemComponent({
  className: extraClassName = "",
  button = false,
  onClick,
  children,
  disabled,
  dataTestid,
}: ListItemProps) {
  const className = classNames(defaultListItemClassName, extraClassName);

  const content = button ? (
    <Button
      data-testid={dataTestid}
      disabled={disabled}
      kind="base"
      onClick={onClick}
      className={defaultButtonClassName}
    >
      {children}
    </Button>
  ) : (
    children
  );

  return <li className={className}>{content}</li>;
}

type ListItemProps = React.PropsWithChildren<{
  className?: string;
  button?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  dataTestid?: string;
}>;

const defaultListClassName = "list-none w-full p-0 m-0 bg-white flex flex-col text-black";
const defaultListItemClassName = "px-4 border-b border-sand hover:bg-black hover:bg-opacity-5";
const defaultButtonClassName = "w-full py-3 px-2 text-left flex justify-between items-center";
