import React from "react";
import CarbCircle from "../../../../../../components/CarbCircle/CarbCircle";
import { HighlightedStar } from "../../../../../../components/Rating/Rating";
import { MealType } from "../../../../../../types/graphql-global-types";
import { MealPlanner_RecipeWithServings } from "../../../../../../types/MealPlanner_RecipeWithServings";
import { RecipePage } from "../../../../../../pages";
import { DayKey } from "../../../../../../types/MealPlan";
import { getSrc } from "../../../../../../utils/image";
import { getMealFieldName } from "../../../../../../utils/mealplan";
import { CloseIcon } from "../../../icons/CloseIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../../types";

import { getEnergyLevel, getMealPlanDay, parseNutritionalInfo } from "../../../utils";
import { Alert } from "../../Alert";
import { IconButton } from "../../buttons";
import { Dialog } from "../Dialog/Dialog";
import { AdditionalStats, Heading, MainStats } from "../NutritionalInfoDialog";
import { MealPlannerTranslationsContext } from "../translations";
import { IngredientsSection } from "./IngredientsSection";
import { convertRatingValueToOneDecimal } from "../../../../../../utils/fraction";
import { getRecipeTime } from "../../../../../../utils/recipe";

export const RecipePreviewDialog = React.memo(RecipePreviewDialogComponent);

function RecipePreviewDialogComponent({ state, send }: RecipePreviewProps) {
  const isDialogOpen = state.matches("planning.viewingRecipeMenu.viewingRecipePreview.idle");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView} className="overflow-auto">
      <RecipePreviewContent state={state} send={send} />
    </Dialog>
  );
}

interface RecipePreviewProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function RecipePreviewContent({ state, send }: RecipePreviewProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const image = state.context.fullRecipe?.images!.vtImage!.path as string;

  const recipe = state.context.currentRecipe!.recipe;
  const handleClickOnCloseIcon = () => send({ type: "CLOSE_VIEW" });

  const todayServings = findTodayServings(state);
  const tomorrowServings = findTomorrowServings(state);
  const totalServings = todayServings + tomorrowServings;
  const { link, fullRecipe } = state.context;
  const recipeLink = link({ to: RecipePage, params: { slug: fullRecipe!.slug } });

  return (
    <div
      style={{ maxWidth: "960px" }}
      className="z-10 fixed sm:static inset-0 sm:inset-auto sm:my-8 sm:rounded-xl sm:shadow-lg sm:w-3/4 bg-white overflow-y-scroll sm:overflow-visible"
    >
      <IconButton
        kind="default"
        spacing="tight"
        className="absolute right-0 m-3 cursor-pointer flex items-center sm:hidden z-10"
        onClick={handleClickOnCloseIcon}
      >
        <CloseIcon />
      </IconButton>
      <div className="w-full relative">
        <IconButton
          kind="default"
          spacing="tight"
          className="cursor-pointer sm:m-4 right-0 absolute hidden sm:flex sm:items-center"
          onClick={handleClickOnCloseIcon}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-2 flex flex-col sm:px-12 sm:pt-12 sm:pb-6 lg:gap-12">
        <div className="relative sm:m-auto w-full h-full" style={{ aspectRatio: "1/1" }}>
          <CarbCircle recipe={recipe!} />
          <img
            className="block sm:rounded-2xl w-full h-full object-cover min-w-full sm:min-w-auto"
            alt=""
            src={getSrc(image, { width: 1200, height: 1200 })}
          />
        </div>
        <RecipeHeader
          state={state}
          send={send}
          todayServings={todayServings}
          tomorrowServings={tomorrowServings}
          totalServings={totalServings}
        />

        <div className="bg-sand p-1 mb-6 w-full lg:hidden"></div>
      </div>
      <div className="lg:grid lg:grid-cols-2 hidden lg:px-10 lg:gap-12">
        <IngredientsSection state={state} send={send} totalServings={totalServings} />

        <div className="lg:flex lg:flex-col">
          <NutritionSection state={state} send={send} />

          <InstructionsSection state={state} send={send} />
        </div>
      </div>
      <div className="lg:hidden sm:px-12">
        <NutritionSection state={state} send={send} />
        <div className="bg-sand p-1 mt-6 w-full"></div>
        <IngredientsSection state={state} send={send} totalServings={totalServings} />
        <div className="bg-sand p-1 mt-6 w-full"></div>
        <InstructionsSection state={state} send={send} />
      </div>
      <div className="w-full bg-sand flex justify-center sm:rounded-b-xl">
        <a href={recipeLink} className="my-6 rounded-full px-4 py-2 bg-white text-base font-medium text-blue">
          {tt.recipePreviewDialog.viewRecipeButtonLabel}
        </a>
      </div>
    </div>
  );
}

function RecipeHeader({ state, todayServings, totalServings, tomorrowServings }: RecipeHeaderProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const title = state.context.fullRecipe?.title;
  const description = state.context.fullRecipe?.description;
  const dayAndMeal = `${tt.dates.weekdayLong(state.context.currentDay! as DayKey)} ${tt.meals.mealLabel(
    state.context.currentMeal!
  )}`;
  const cookingTime = tt.quantities.timeInMinutes(getRecipeTime(state.context.fullRecipe?.time));
  const starRating = state.context.fullRecipe?.rating;

  return (
    <div className="px-4 sm:px-0 flex justify-center flex-col">
      <h3 style={{ fontSize: "18px" }} className="font-medium mb-2 mt-6 uppercase">
        {dayAndMeal}
      </h3>
      <h2 className="m-0 font-medium text-4xl">{title}</h2>
      <div className="grid grid-cols-3 text-sm mt-3" style={{ width: "fit-content" }}>
        <span className="col-span-1 pr-2">{cookingTime}</span>
        <span className="border-r col-span-1 border-l border-grey text-center px-2">
          {tomorrowServings > 0
            ? tt.quantities.servingsWithLeftovers(todayServings, tomorrowServings)
            : tt.quantities.servings(todayServings)}
        </span>
        <div className="col-span-1 pl-2">
          <HighlightedStar className="ml-2">★</HighlightedStar>
          <span>{convertRatingValueToOneDecimal(starRating)}</span>
        </div>
      </div>
      {tomorrowServings > 0 && (
        <Alert severity="info" className="mt-4">
          {tt.recipePreviewDialog.leftoversMessage(totalServings, tomorrowServings)}
        </Alert>
      )}
      <p>{description}</p>
    </div>
  );
}

interface RecipeHeaderProps extends RecipePreviewProps {
  todayServings: number;
  tomorrowServings: number;
  totalServings: number;
}

function findTodayServings(state: MealPlanMachineState): number {
  const { currentMeal, currentRecipe } = state.context;
  const day = getMealPlanDay(state.context);

  if (!day || !currentMeal || !currentRecipe) {
    return 0;
  }

  const mealAttr = getMealFieldName(currentMeal);
  const meal = day[mealAttr];
  const sameRecipes = meal.recipeWithServings
    .filter((r: MealPlanner_RecipeWithServings | null): r is MealPlanner_RecipeWithServings => Boolean(r))
    .filter((recipeWithServings) => recipeWithServings!.recipe!.id === currentRecipe!.recipe!.id);

  return sameRecipes.reduce((acc, { servings }) => acc + servings, 0);
}

function findTomorrowServings(state: MealPlanMachineState): number {
  const { currentDay, currentMeal, currentRecipe, mealPlan } = state.context;
  const lastDay = mealPlan!.schedule.length;
  const todaysId = state.context.formattedMealPlan!.findIndex((day) => day.mealplanDay.name === currentDay!);

  if (currentMeal === MealType.DINNER && todaysId < lastDay) {
    const tomorrowsId = todaysId + 1;
    const tomorrowsRecipes = state.context.formattedMealPlan![tomorrowsId].mealplanDay;
    const tomorrowsLunch = tomorrowsRecipes.lunch;
    const tomorrowsLunchWithSameRecipe = tomorrowsLunch.recipeWithServings
      .filter((r: MealPlanner_RecipeWithServings | null): r is MealPlanner_RecipeWithServings => Boolean(r))
      .filter((recipeWithServings) => recipeWithServings!.recipe!.id === currentRecipe!.recipe!.id);

    return tomorrowsLunchWithSameRecipe.reduce((acc, { servings }) => acc + servings, 0);
  }

  return 0;
}

function NutritionSection({ state }: RecipePreviewProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const nutrition = parseNutritionalInfo(state.context.fullRecipe!.nutrition);
  const {
    isHighProtein,
    strictness: { rating: strictness },
    satietyScore,
  } = state.context.fullRecipe!;

  const energyLevelLabel = tt.energyLevel[getEnergyLevel(isHighProtein, strictness)];
  const nutritionCalculateInfo = tt.nutritionalInfo.totalNutritionForRecipe;
  return (
    <div className="px-4 sm:px-0">
      <div>
        <div>
          <Heading
            energyLabel={energyLevelLabel}
            nutritionalInfo={nutrition}
            nutritionCalculateInfo={nutritionCalculateInfo}
          />
          <MainStats nutritionalInfo={nutrition} className="mb-6" />
          <AdditionalStats nutritionalInfo={nutrition} satietyScore={satietyScore} />
        </div>
      </div>
    </div>
  );
}

function InstructionsSection({ state }: RecipePreviewProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const instructions = state.context.fullRecipe?.instructionSections ?? [{ steps: [] }];
  // tip: although instructionSections may be defined, it may not contain any element inside
  const steps = instructions[0]?.steps ?? [];

  return steps.length === 0 ? null : (
    <div className="px-4 sm:px-0 mt-6">
      <div className="flex justify-between items-center">
        <h3 className="font-medium mb-0">{tt.recipePreviewDialog.instructionsSectionLabel}</h3>
      </div>
      <div>
        {steps.map((instruction, index) => (
          <div key={index} className="grid grid-cols-10 my-6">
            <div className="col-span-1 flex items-start">
              <span className="bg-grey-meal-planner px-4 py-2 rounded-3xl">{index + 1}</span>
            </div>
            <span className="col-span-9 flex items-center ml-7">{instruction}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
