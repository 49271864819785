import { bindHover, bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import Popover from "material-ui-popup-state/HoverPopover";
import React from "react";
import styled from "@emotion/styled";
import {
  GetMealplanBySlug_mealplanBySlug_authors_contributors,
  GetMealplanBySlug_mealplanBySlug_authors_main,
} from "../../types/GetMealplanBySlug";
import { MOBILE_LG } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import ResponsiveImage from "../Image/ResponsiveImage";

interface AuthorPopoverProps extends ReturnType<typeof bindPopover> {
  author: GetMealplanBySlug_mealplanBySlug_authors_main | GetMealplanBySlug_mealplanBySlug_authors_contributors;
}

export function AuthorPopover({ author, ...popoverProps }: AuthorPopoverProps) {
  return (
    <PopoverContainer
      {...popoverProps}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      hideBackdrop
    >
      <a href={author.profileUrl}>
        {author.image && (
          <Img
            alt={author.fullName}
            path={author.image?.default}
            width={150}
            height={150}
            dimensions={[
              { width: 150, height: 150 },
              { width: 300, height: 300 },
            ]}
            defaultDimension={{ width: 150, height: 150 }}
          />
        )}
        <Title>{author.fullName}</Title>
        {author.description && <Description>{author.description}</Description>}
      </a>
    </PopoverContainer>
  );
}

export function useAuthorPopover(author: AuthorPopoverProps["author"]) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: author.id,
  });
  const hasPopover = Boolean(author.description);
  const hoverAttrs = hasPopover ? bindHover(popupState) : undefined;
  const popoverAttrs = bindPopover(popupState);

  return { hoverAttrs, popoverAttrs };
}

const PopoverContainer = styled(Popover)`
  .MuiPopover-paper {
    width: 450px;
    max-width: 80vw;

    border: 1px solid #ddd;
    border-radius: 2px;

    a {
      display: block;
      padding: 15px;
      color: ${colors.BLACK_LIGHTER};
    }
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 20px;
`;

const Description = styled.span`
  display: block;
  font-size: 15px;
  line-height: 1.2;
`;

const Img = styled(ResponsiveImage)`
  float: left;
  width: 80px;
  padding: 0 5px 5px 0;

  @media (min-width: ${MOBILE_LG}) {
    width: 150px;
    padding-right: 15px;
  }
`;
