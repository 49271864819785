import memoizeOne from "memoize-one";
import sanitizeHtml from "sanitize-html";

export const htmlContent = memoizeOne((content: string) => ({
  __html: sanitizeHtml(content, {
    allowedTags: ["iframe", "p"],
  }),
}));

export const videoIframeHtmlContent = memoizeOne((content: string) => ({
  __html: sanitizeHtml(content, {
    allowedTags: ["iframe"],
    allowedAttributes: {
      iframe: ["width", "height", "frameborder", "src", "allow", "fullscreen"],
    },
  }),
}));
