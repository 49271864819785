const isAgent = (name: RegExp) => navigator.userAgent.search(name) !== -1;
const isIPadInDesktopMode = () => navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
const commandPrint = () => document.execCommand("print");
const windowPrint = () => window.print();

export function print() {
  const isIOS = isAgent(/iphone/gi) || isAgent(/ipad/gi) || isIPadInDesktopMode();
  const isAndroid = isAgent(/android/gi);
  const isFirefox = isAgent(/firefox/gi);

  if (isIOS) {
    // safari & chrome
    // prevents crash on firefox
    if (!isFirefox) {
      windowPrint();
    }
  } else {
    // android & desktop browsers
    try {
      // let's try with executing the command print for compatibility reasons
      if (!commandPrint()) {
        throw new Error();
      }
    } catch (e) {
      if (isFirefox && isAndroid) {
        // not supported, `windows.print` is missing
        // see https://github.com/mozilla-mobile/fenix/issues/10217
      } else {
        // anyone else
        windowPrint();
      }
    }
  }
}
