import classNames from "classnames";
import React from "react";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { Button } from "../buttons";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader, HeaderTitle } from "./Dialog/DialogHeader";
import { MealPlannerTranslationsContext } from "./translations";

export const EditMealPlanDialog = React.memo(EditMealPlanDialogComponent);

function EditMealPlanDialogComponent({ state, send }: EditMealPlanProps) {
  const isDialogOpen =
    state.matches("planning.editingMealPlan") && !state.matches("planning.editingMealPlan.loading");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <EditMealPlanView state={state} send={send} />
    </Dialog>
  );
}

interface EditMealPlanProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function EditMealPlanView({ state, send }: EditMealPlanProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const mealPlan = state.context.mealPlan!;

  const [title, setTitle] = React.useState(parseInput(mealPlan.title, true));
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(parseInput(event.target.value, true));
  };
  const [description, setDescription] = React.useState(parseInput(mealPlan.description, false));
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescription(parseInput(event.target.value, false));

  const canSave = title.isValid && description.isValid;

  const handleSaveClick = () =>
    send({ type: "CONFIRM_MEAL_PLAN_EDIT", title: title.value, description: description.value });
  const handleCancelClick = () => send({ type: "CLOSE_VIEW" });

  const titleInputClassName = classNames(inputClassName, {
    [validInputClassName]: title.isValid,
    [invalidInputClassName]: !title.isValid,
  });

  const descriptionInputClassName = classNames(textAreaClassName, {
    [validInputClassName]: description.isValid,
    [invalidInputClassName]: !description.isValid,
  });

  return (
    <div className={viewClassName}>
      <DialogHeader state={state} send={send}>
        <HeaderTitle>{tt.editMealPlanDialog.dialogTitle}</HeaderTitle>
      </DialogHeader>
      <div className="p-4 pb-12 sm:pb-4 flex-1 sm:flex-auto flex flex-col px-12">
        <label htmlFor="title" className={labelClassName}>
          {tt.editMealPlanDialog.mealPlanTitleLabel}
        </label>
        <input
          id="title"
          type="text"
          autoComplete="off"
          maxLength={80}
          value={title.value}
          onChange={handleTitleChange}
          className={titleInputClassName}
        />

        <label htmlFor="description" className={labelClassName}>
          {tt.editMealPlanDialog.mealPlanDescriptionLabel}
        </label>
        <textarea
          id="description"
          autoComplete="off"
          maxLength={800}
          value={description.value}
          onChange={handleDescriptionChange}
          rows={5}
          className={descriptionInputClassName}
        />
        <div className="flex m-auto flex-col sm:flex-row">
          <Button kind="primary" onClick={handleSaveClick} disabled={!canSave} className={buttonClassName}>
            {tt.editMealPlanDialog.confirmEditButtonLabel}
          </Button>
          <Button kind="secondary" onClick={handleCancelClick} className={buttonClassName}>
            {tt.editMealPlanDialog.cancelEditButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

interface Input {
  value: string;
  isValid: boolean;
}

function parseInput(value: string, required: boolean): Input {
  return {
    value,
    isValid: !required || value.length > 0,
  };
}

const viewClassName = [
  // Put the view in front of the overlay.
  "z-10",
  // Mobile screens: position the view to take the whole available space.
  "fixed inset-0",
  // Larger screens: the view should have minimum and maximum dimensions.
  "sm:static sm:inset-auto sm:min-w-1/2 sm:max-w-3/4 sm:h-3/4",
  // Larger screens: round the borders and add a drop shadow.
  "sm:rounded-xl sm:shadow-lg",
  // Vertically overflow if needed. This is also needed for the rounded corners.
  "overflow-y-auto",
  // Content will be layered out using flex.
  "flex flex-col",
  // White background.
  "bg-white",
].join(" ");

const labelClassName = "text-sm font-medium mb-2";
const inputClassName = "border rounded-3xl px-4 py-2 mb-6 text-sm";
const textAreaClassName = "border rounded-3xl px-4 py-4 mb-6 flex-1 text-sm";
const validInputClassName = "border-sand";
const invalidInputClassName = "border-red";

const buttonClassName = "w-48 mb-2 self-center";
