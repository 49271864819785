import React from "react";
import styled from "@emotion/styled";
import { LoadingAnimated } from "../../../../../components/LoadingAnimated/LoadingAnimated";
import DDLogoMark from "../../../../../components/Logo/diet-doctor-logo.svg";
import { print } from "../../../../../utils/print";
import useWindowWidth from "../../../../../hooks/useWindowWidth/useWindowWidth";
import { MOBILE_MD } from "../../../../../utils/breakpoints";
import { IngredientUnit } from "../../../../../utils/units";
import { PrintIcon } from "../../icons/PrintIcon";
import {
  IngredientsListIngredient,
  IngredientsListIngredientValue,
  IngredientsListSection,
  MealPlanMachineSend,
  MealPlanMachineState,
} from "../../types";
import { parseIngredientsList } from "../../utils";
import { Button, IconButton } from "../buttons";
import { MealPlannerTranslationsContext } from "./translations";

export const IngredientsList = React.memo(IngredientsListComponent);

function IngredientsListComponent({ state, send }: ShoppingListProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { formattedMealPlan } = state.context;
  const columnAmount = useWindowWidth() < parseInt(MOBILE_MD) ? 1 : 2;
  const ingredientsList = parseIngredientsList(state.context.ingredientsList ?? []);
  const { ingredientUnit } = state.context;
  const isLoading = state.matches("planning.viewingMealPlanIngredientsList.loading");

  const handlePrint = () => {
    print();
  };

  const addMealPlanToShoppingList = () =>
    send!({
      type: "ADD_RECIPES_TO_SHOPPING_LIST",
      recipeOrMealPlanDays: formattedMealPlan!.map(({ weekday }) => weekday),
    });

  return (
    <div className="py-4 pb-12 sm:pb-4 lg:px-24 px-4">
      <div className="flex flex-col md:flex-row mb-4 border-b border-sand pb-4">
        <div className="flex flex-row w-full">
          <img src={DDLogoMark} className="w-20 mb-4 hidden print:block" />
          <h1 className="flex-1 m-0 text-3xl print:text-2xl font-medium">
            {tt.mealPlanIngredientsList.ingredientsListLabel}
          </h1>
          <IconButton kind="default" onClick={handlePrint} className="mr-4 md:hidden block">
            <PrintIcon className="text-black fill-current" />
          </IconButton>
        </div>

        {!isLoading && (
          <div className="flex mt-4 md:mt-0 print:hidden ">
            <IconButton kind="default" onClick={handlePrint} className="mr-4 hidden md:block mt-2">
              <PrintIcon className="text-black fill-current" />
            </IconButton>
            <Button kind="primary" className="w-max border-none " onClick={addMealPlanToShoppingList}>
              {tt.mealPlanMenu.addToShoppingListButtonLabel}
            </Button>
          </div>
        )}
      </div>
      <OnlyPrintDecorator>
        {isLoading && (
          <div className="flex justify-center">
            <LoadingAnimated />
          </div>
        )}
        <div style={{ columnCount: columnAmount }}>
          {!isLoading &&
            ingredientsList.sections.map((section) => (
              <IngredientsListSectionList
                key={section.name}
                section={section}
                ingredientUnit={ingredientUnit}
              />
            ))}
        </div>
      </OnlyPrintDecorator>
    </div>
  );
}

interface ShoppingListProps {
  state: MealPlanMachineState;
  send?: MealPlanMachineSend;
}

function IngredientsListSectionList({ section, ingredientUnit }: IngredientsListSectionProps) {
  const { name, ingredients } = section;

  return (
    <section className="mb-8">
      <h2 className="m-0 text-lg font-medium">{name}</h2>
      <ul className="p-0 m-0">
        {ingredients.map((ingredient) => (
          <li key={ingredient.id} className="list-none print:border-none">
            <IngredientsListIngredientItem ingredient={ingredient} ingredientUnit={ingredientUnit} />
          </li>
        ))}
      </ul>
    </section>
  );
}

interface IngredientsListSectionProps {
  section: IngredientsListSection;
  ingredientUnit: IngredientUnit;
}

function IngredientsListIngredientItem({ ingredient, ingredientUnit }: IngredientsListIngredientProps) {
  const { name, values } = ingredient;
  const value = values[ingredientUnit];
  const quantity = value && formatQuantity(value);

  return (
    <div className="w-full py-1 flex items-center space-x-4 text-lg print:py-0 print:text-sm print:space-x-0">
      <span>{name}</span>
      <span className="hidden print:flex print:pr-2">,</span>
      {quantity && (
        <>
          <span className="text-grey">{quantity}</span>
        </>
      )}
    </div>
  );
}

interface IngredientsListIngredientProps {
  ingredient: IngredientsListIngredient;
  ingredientUnit: IngredientUnit;
}

function formatQuantity({ amount, unit }: IngredientsListIngredientValue): string {
  if (unit.length > 0) {
    return `${amount} ${unit}`;
  }

  return `${amount}`;
}

const OnlyPrintDecorator = styled.div`
  @media print {
    column-gap: 80px;
  }
`;
