import React, { useContext } from "react";
import styled from "@emotion/styled";
import { MealType } from "../../types/graphql-global-types";
import { RecipeDetails } from "../../types/MealPlan";
import { MOBILE_LG } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import { Weekday } from "../../utils/date";
import { buildMealDaysString, isDinnerLunchMealCombo } from "../../utils/recipe";
import { IngredientUnit } from "../../utils/units";
import CarbsBadge from "../CarbsBadge/CarbsBadge";
import ResponsiveImage from "../Image/ResponsiveImage";
import { pageContext } from "../PageProvider/PageProvider";
import TimeAndDifficultyLabel from "../TimeAndDifficultyLabel/TimeAndDifficultyLabel";
import IngredientsBox from "./IngredientsBox/IngredientsBox";
import Instructions from "./Instructions/Instructions";
import RecipeFooter from "./RecipeFooter/RecipeFooter";
import Tips from "./Tips/Tips";
import Video from "./Video/Video";

const Container = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 15px 60px;

  @media print {
    page-break-after: always;
    grid-template-columns: 1fr 3fr;
    @-moz-document url-prefix() {
      display: block;
    }
  }
`;

const Header = styled.div`
  grid-column: span 2;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin: 36px 0 3px;

  @media (min-width: ${MOBILE_LG}) {
    font-size: 40px;
    line-height: 45px;
    margin: 54px 0 4px;
  }

  @media print {
    font-size: 18px;
    line-height: 20px;
  }
`;

const TitleMeta = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #777;
  margin-bottom: 14px;

  @media print {
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 11px;
  }
`;

const RecipeInfo = styled.div`
  margin: 0 0 15px;
  display: flex;
  align-items: baseline;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 26px;

  @media print {
    font-size: 14px;
  }
`;

const SideContainer = styled.div`
  grid-column: span 2;

  @media (min-width: ${MOBILE_LG}) {
    grid-column: auto;
  }

  @media print {
    grid-column: auto;
  }
`;

const InstructionsSection = styled.div`
  grid-column: span 2;

  @media (min-width: ${MOBILE_LG}) {
    grid-column: auto;
  }

  @media print {
    grid-column: auto;
  }
`;

const StyledCarbsBadge = styled(CarbsBadge)`
  @media print {
    display: none;
  }
`;

const StyleTimeAndDifficultyLabel = styled(TimeAndDifficultyLabel)`
  @media print {
    font-size: 11px;
    color: ${colors.BLACK};
  }
`;

const RecipeSection = ({
  recipe,
  meals,
  unit,
  servingSize,
  adjustInstructionTagId,
}: {
  recipe: RecipeDetails;
  meals: Array<{ weekday: Weekday; meal: MealType }>;
  unit: IngredientUnit;
  servingSize: number;
  adjustInstructionTagId?: string;
}) => {
  const { locale } = useContext(pageContext);
  const isDinnerLunchCombo = isDinnerLunchMealCombo(meals);
  return (
    <Container>
      <Header>
        <Title>{recipe.title}</Title>
        <TitleMeta>{buildMealDaysString(locale, meals)}</TitleMeta>
      </Header>
      <ResponsiveImage
        path={recipe.images.hz}
        alt={recipe.title}
        width={1000}
        height={667}
        sizes="(max-width: 900px) 100vw, (min-width: 1200px) 400px, calc( (100vw - 300px) / 2 )"
        dimensions={[
          { width: 150, height: 100 },
          { width: 300, height: 200 },
          { width: 400, height: 267 },
          { width: 600, height: 400 },
          { width: 800, height: 533 },
          { width: 1200, height: 800 },
          { width: 1600, height: 1067 },
          { width: 2000, height: 1333 },
        ]}
        defaultDimension={{ width: 1200, height: 800 }}
        rounded
      />
      <div>
        <RecipeInfo>
          <StyledCarbsBadge recipe={recipe} />
          <StyleTimeAndDifficultyLabel recipe={recipe} />
        </RecipeInfo>
        <Description>{recipe.description}</Description>
      </div>
      <SideContainer>
        <IngredientsBox
          recipe={recipe}
          unit={unit}
          servingSize={servingSize}
          isDinnerLunchCombo={isDinnerLunchCombo}
        />
        <RecipeFooter recipe={recipe} />
      </SideContainer>
      <InstructionsSection>
        <Instructions
          adjustInstructionTagId={adjustInstructionTagId}
          recipe={recipe}
          servingSize={servingSize}
        />
        {recipe.tips.length > 0 && <Tips tips={recipe.tips} />}
        {(recipe.videos || []).length > 0 && <Video videos={recipe.videos} />}
      </InstructionsSection>
    </Container>
  );
};

export default RecipeSection;
