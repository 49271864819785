import { t } from "@lingui/macro";
import React from "react";
import styled from "@emotion/styled";
import { FullRecipe_videos } from "../../../types/FullRecipe";
import { MOBILE_LG } from "../../../utils/breakpoints";
import { videoIframeHtmlContent } from "../../../utils/html";

const VideoContainer = styled.div`
  grid-column: span 2;

  @media (min-width: ${MOBILE_LG}) {
    grid-column: 2;
  }

  @media print {
    display: none;
  }
`;

const VideoTitle = styled.h3`
  font-size: 40px;
  font-weight: 600;
  margin: 4px 0;
  line-height: 1.125;
  letter-spacing: -0.7px;
`;

const vimeoIframe = (id: string) => {
  return videoIframeHtmlContent(`
    <iframe
      width="390px"
      height="390px"
      frameborder="0"
      src="https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0"
      allow="autoplay; fullscreen"
    ></iframe>`);
};

function useTranslations() {
  return {
    videoHeading: t({
      id: "MealPlanPage.RecipesTab.videoHeading",
      message: "Video",
    }),
  };
}

const Video = ({ videos }: { videos: Array<FullRecipe_videos | null> | null }) => {
  const tt = useTranslations();
  const video = videos && videos.find((aVideo) => aVideo && aVideo.type === "VIMEO");
  if (!video) {
    return null;
  }
  return (
    <VideoContainer data-testid="video-container">
      <VideoTitle>{tt.videoHeading}</VideoTitle>
      <div dangerouslySetInnerHTML={vimeoIframe(video.id)} />
    </VideoContainer>
  );
};

export default Video;
