import {
  FullRecipe_ingredientSections_ingredients,
  FullRecipe_ingredientSections_ingredients_values_metric,
  FullRecipe_ingredientSections_ingredients_values_sv,
  FullRecipe_ingredientSections_ingredients_values_us,
} from "../types/FullRecipe";
import { GetIngredients_ingredients } from "../types/GetIngredients";
import { Unit } from "../types/MealPlan";
import { ingredientAnd, ingredientOptional, ingredientOr } from "./translations";

type RecipeIngredientsValues =
  | FullRecipe_ingredientSections_ingredients_values_us
  | FullRecipe_ingredientSections_ingredients_values_metric
  | FullRecipe_ingredientSections_ingredients_values_sv
  | undefined;

/**
 * Recipe ingredient display text, following the rules:
 *  [value] [unit] [(dual value/unit)]
 *  [beforeName] titles.plural [afterName]
 *  ['or' alternateIngredient]
 *  ['and' additionalIngredients]
 *  [(optional)]
 */
export const getRecipeIngredientDisplayText = (
  item: FullRecipe_ingredientSections_ingredients,
  ingredient: GetIngredients_ingredients,
  additionalIngredients: GetIngredients_ingredients[],
  alternateIngredients: GetIngredients_ingredients[],
  unit: Unit,
  servingSize: number
) => {
  let displayText = getIngredientFullName(item, ingredient);

  let recipeIngredientsValues: RecipeIngredientsValues;
  if (item.values) {
    recipeIngredientsValues = item.values[unit].find((unitValue) => unitValue.servingSize === servingSize);
    if (recipeIngredientsValues) {
      const unitValue = getUnitValue(recipeIngredientsValues);
      if (unitValue) {
        displayText = unitValue + " " + displayText;
      }
    }
  }

  if (item.alternateIngredients && item.alternateIngredients.length > 0) {
    const alternateIngredientItem = item.alternateIngredients[0];
    const alternateIngredient = alternateIngredients.find(
      (anIngredient) => anIngredient.id === alternateIngredientItem.id
    );
    if (alternateIngredient) {
      displayText += ingredientOr() + getIngredientFullName(alternateIngredientItem, alternateIngredient);
    }
  }

  if (item.additionalIngredients && item.additionalIngredients.length > 0) {
    const additionalIngredientItem = item.additionalIngredients[0];
    const additionalIngredient = additionalIngredients.find(
      (anIngredient) => anIngredient.id === additionalIngredientItem.id
    );
    if (additionalIngredient) {
      displayText += ingredientAnd() + getIngredientFullName(additionalIngredientItem, additionalIngredient);
    }
  }

  if (item.optional) {
    displayText += ` (${ingredientOptional()})`;
  }
  return { displayText };
};

interface IngredientItem {
  beforeName?: string;
  afterName?: string;
}

const getIngredientFullName = (item: IngredientItem, ingredient: GetIngredients_ingredients) => {
  let fullname = ingredient.titles.plural;

  if (item.beforeName && item.beforeName.length > 0) {
    fullname = item.beforeName + " " + fullname;
  }

  if (item.afterName && item.afterName.length > 0) {
    if (item.afterName.startsWith(",")) {
      fullname += item.afterName;
    } else {
      fullname += " " + item.afterName;
    }
  }
  return fullname;
};

const getUnitValue = (ingredientValues: RecipeIngredientsValues) => {
  if (!ingredientValues) {
    return "";
  }
  let unitValue = [ingredientValues.value, ingredientValues.unit].filter(Boolean).join(" ");
  if (ingredientValues.dualValue) {
    const dualValue = [ingredientValues.dualValue.value, ingredientValues.dualValue.unit]
      .filter(Boolean)
      .join(" ");
    if (dualValue.length > 0) {
      unitValue += " (" + dualValue + ")";
    }
  }
  return unitValue;
};
