import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { MdClear } from "@react-icons/all-files/md/MdClear";
import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import { useDebounce } from "../../../../../hooks/useDebounce/useDebounce";
import { MealPlanner_GetTagsQuery_listTags_tags } from "../../../../../types/MealPlanner_GetTagsQuery";
import { MealPlanner_SearchRecipesQuery_listRecipes_recipes } from "../../../../../types/MealPlanner_SearchRecipesQuery";
import { upperFirst } from "../../../../../utils/string";
import { FilterIcon } from "../../icons/FilterIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getServingSizeForRecipeSlot } from "../../utils";
import { Button } from "../buttons";
import { MealPlannerTranslationsContext } from "../MealPlanner/translations";
import RecipeCard from "./RecipeCard";
import { useTags } from "../../../../../utils/tags";
import { satietyScoreRange } from "../../../../../constants/satietyScoreRange";
import { cookingTimeRange } from "../../../../../constants/cookingTimeRange";
import { getSelectedCookingTimeRange, getSelectedSatietyScoreRange } from "../../../../../utils/utils";
import { InfoIcon } from "../../../../../icons/InfoIcon";
import { Link } from "../../../../../components/Link/Link";
import { SatietyScorePage } from "../../../../../pages";
import { RangeType } from "../../../../../constants/types";

export default React.memo(SearchTab);

function SearchTab({ state, send }: { state: MealPlanMachineState; send: MealPlanMachineSend }) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const {
    allTags,
    searchInput,
    searchResult: recipes,
    chosenTags,
    currentDay,
    currentMeal,
    currentRecipeIndex,
    selectedSatietyRange,
    selectedCookTimeLimit,
  } = state.context;
  const [query, setQuery] = useState(searchInput);
  const servings = getServingSizeForRecipeSlot(state.context);

  const { tags, tagsWithId } = useTags(allTags, tt, currentMeal);

  const debounceSearchInputChange = useDebounce((query: string) => {
    if (query === "" && state.context.chosenTags.length === 0) {
      send({ type: "CLEAR_SEARCH_INPUT" });
    } else {
      send({ type: "SEARCH_INPUT_CHANGE", input: query });
    }
  }, 500);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    debounceSearchInputChange(newQuery);
  };
  const handleAddRecipe = (recipeId: string) => {
    send({
      type: "ADD_RECIPE",
      day: currentDay!,
      meal: currentMeal!,
      recipeId,
      servings,
      index: currentRecipeIndex,
    });
  };
  const handleClearSearchInput = () => {
    send({ type: "CLEAR_SEARCH_INPUT" });
    setQuery("");
  };
  const handleClickOnTag = (tag: string) => send({ type: "TAG_CHANGE", tags: [tag] });
  const toggleAllTagsView = () => send({ type: "TOGGLE_TAG_VIEW" });
  const handleRemoveTag = (tag: MealPlanner_GetTagsQuery_listTags_tags) => send({ type: "REMOVE_TAG", tag });
  const handleAddTag = (tag: MealPlanner_GetTagsQuery_listTags_tags) => send({ type: "ADD_TAG", tag });

  const handleMoreSearchResult = () => send({ type: "LOAD_MORE_RECIPES" });

  const isActiveTagStyle = (tagId: string) => {
    const stdStyle = "px-4 py-2 rounded-3xl m-1 leading-none cursor-pointer";

    const activeTag = chosenTags.some((chosenTag) => chosenTag.id === tagId)
      ? `border border-green ${stdStyle}`
      : ` border border-bluegrey bg-bluegrey ${stdStyle}`;

    return activeTag;
  };

  const filterIconStyle =
    state.context.chosenTags.length > 0 ||
    state.context.selectedCookTimeLimit.length > 0 ||
    state.context.selectedSatietyRange.length > 0 ||
    state.matches("planning.selectRecipe.search.tagView")
      ? "border border-green bg-white"
      : "bg-bluegrey";

  const showSearchResult =
    state.matches("planning.selectRecipe.search.loadingSearchResultOnLoadMore") ||
    state.matches("planning.selectRecipe.search.idle") ||
    state.matches("planning.selectRecipe.search.viewingRecipeCardMenu") ||
    state.matches("planning.selectRecipe.search.viewingRecipeCardNutritionalInfo");

  const setCookTimeLimit = (cookTimeLimit: RangeType) => {
    const selectedCookTime = getSelectedCookingTimeRange(selectedCookTimeLimit, cookTimeLimit);

    send({
      type: "SET_COOK_TIME_LIMIT",
      selectedCookTimeRange: selectedCookTime,
    });
  };

  const setSatietyScoreRange = (satietyScore: RangeType) => {
    const satietyScoreValues = getSelectedSatietyScoreRange(selectedSatietyRange, satietyScore);

    send({
      type: "SET_SATIETY_SCORE_RANGE",
      selectedSatietyRange: satietyScoreValues,
    });
  };

  const modifiedSatietyRange = selectedSatietyRange.map((item) => {
    return { ...item, title: `${tt.recipeSelectorDialog.satietyScore} ${item.title}` };
  });

  return (
    <div className="m-4 relative">
      <div className="border bg-white border-grey-meal-planner px-3 flex items-center rounded-3xl shadow-md">
        <MdSearch className="mr-3" />
        <input
          placeholder={tt.recipeSelectorDialog.searchInputPlaceholder}
          value={query}
          onChange={handleOnChange}
          className="w-full py-2"
        />
        <button className="flex items-center" onClick={handleClearSearchInput}>
          <MdClear className="cursor-pointer" />
        </button>
      </div>
      <div className="flex items-center mt-5">
        <button className="cursor-pointer" onClick={toggleAllTagsView}>
          <FilterIcon filterIconStyle={filterIconStyle} />
        </button>
        <div className="flex flex-wrap">
          {chosenTags.map((tag) => (
            <div
              className="border border-green px-3 py-1 rounded-3xl m-1 leading-none cursor-pointer"
              key={tag.title}
              onClick={() => handleRemoveTag(tag)}
            >
              {upperFirst(tag.title)}
            </div>
          ))}
          {selectedCookTimeLimit.map((tag, idx) => (
            <div
              className="border border-green px-3 py-1 rounded-3xl m-1 leading-none cursor-pointer"
              key={idx}
              onClick={() => send({ type: "REMOVE_COOKTIME_RANGE_TAG", tag })}
            >
              {tag.title}
            </div>
          ))}

          {modifiedSatietyRange.map((tag, idx) => (
            <div
              className="border border-green px-3 py-1 rounded-3xl m-1 leading-none cursor-pointer"
              key={idx}
              onClick={() => send({ type: "REMOVE_SATIETY_RANGE_TAG", tag })}
            >
              {tag.title}
            </div>
          ))}
        </div>
      </div>
      <div>
        {showSearchResult && (
          <>
            {recipes!.map((item: MealPlanner_SearchRecipesQuery_listRecipes_recipes, index: number) => (
              <RecipeCard
                state={state}
                send={send}
                key={item.id + index}
                item={item}
                handleClickOnRecipe={() => handleAddRecipe(item.id)}
                history="search"
              />
            ))}
            {state.matches("planning.selectRecipe.search.loadingSearchResultOnLoadMore") && (
              <div className="justify-center flex my-2">
                <CircularProgress />
              </div>
            )}
            {state.matches("planning.selectRecipe.search.idle") && state.context.showLoadMoreButton && (
              <Button onClick={handleMoreSearchResult} className="m-auto flex my-2" kind="primary">
                {tt.recipeSelectorDialog.loadMoreButtonTitle}
              </Button>
            )}
          </>
        )}
        {state.matches("planning.selectRecipe.search.loadingSearchResult") && (
          <div className="justify-center flex my-2">
            <CircularProgress />
          </div>
        )}

        {state.matches("planning.selectRecipe.search.idle") && recipes?.length === 0 && (
          <div className="flex justify-center">{tt.recipeSelectorDialog.noRecipesFoundMessage}</div>
        )}
        {state.matches("planning.selectRecipe.search.popularTags") && (
          <div className="grid grid-cols-2">
            {tagsWithId.map((tag) => (
              <button
                className="bg-bluegrey cursor-pointer m-2 flex py-5 sm:py-10 items-center justify-center rounded-lg font-medium"
                key={tag.title}
                onClick={() => handleClickOnTag(tag.id)}
              >
                {upperFirst(tag.title)}
              </button>
            ))}
          </div>
        )}
        {state.matches("planning.selectRecipe.search.tagView") && (
          <div className="m-4 flex justify-center flex-col relative">
            <div className="mb-4">
              <h3 className="font-medium text-xl m-0 mb-2">{tt.recipeSelectorDialog.cookingTime}</h3>
              <div className="flex flex-wrap">
                {cookingTimeRange.map((range) => (
                  <button
                    key={range.id}
                    onClick={() => setCookTimeLimit(range)}
                    className={`${
                      selectedCookTimeLimit.some((selected) => selected.id === range.id)
                        ? "border border-green"
                        : "border border-bluegrey bg-bluegrey"
                    } py-1.5 px-4 text-base min-w-min rounded-full mr-2 mb-2`}
                  >
                    {upperFirst(range.title)}
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-row items-center">
                <h3 className="font-medium text-xl m-0 mb-2">{tt.recipeSelectorDialog.satietyScore}</h3>
                <Link to={SatietyScorePage} target="_blank" className="ml-1">
                  <InfoIcon className="text-green" />
                </Link>
              </div>

              <div className="flex flex-wrap">
                {satietyScoreRange.map((range) => (
                  <button
                    key={range.id}
                    onClick={() => setSatietyScoreRange(range)}
                    className={`${
                      selectedSatietyRange.some((selected) => selected.id === range.id)
                        ? "border border-green"
                        : "border border-bluegrey bg-bluegrey"
                    } py-1.5 px-4 text-base min-w-min rounded-full mr-2 mb-2`}
                  >
                    {upperFirst(range.title)}
                  </button>
                ))}
              </div>
            </div>

            {tags.map((tagType) => (
              <div key={tagType.title}>
                <h3>{tagType.title}</h3>
                <div className="flex flex-wrap">
                  {tagType.tags.map((tag) => (
                    <button
                      className={isActiveTagStyle(tag.id)}
                      key={tag.id}
                      onClick={
                        chosenTags.some((chosenTag) => chosenTag.id === tag.id)
                          ? () => handleRemoveTag(tag)
                          : () => handleAddTag(tag)
                      }
                    >
                      {upperFirst(tag.title)}
                    </button>
                  ))}
                </div>
              </div>
            ))}

            <div className="mx-auto flex justify-center sticky bottom-0 bg-white w-full items-center">
              <Button onClick={toggleAllTagsView} kind="primary">
                {tt.recipeSelectorDialog.showRecipesButtonLabel(state.context.searchResult!.length)}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
