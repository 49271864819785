import React, { useEffect, useMemo } from "react";
import { isBrowser } from "../../../../../../utils/ssr";
import { ScreenProps } from "../../../types";
import { groupSameRecipesTogether } from "../../../utils";
import { RecipeSelectorDialog } from "../../RecipeSelector/RecipeSelectorDialog";
import { ActionConfirmationDialog } from "../ActionConfirmationDialog";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { CopyMealPlanDialog } from "../CopyMealPlanDialog";
import { CopyRecipeDialog } from "../CopyRecipeDialog";
import { EditMealPlanDialog } from "../EditMealPlanDialog";
import { ErrorDialog } from "../ErrorDialog";
import { LoadingDialog } from "../LoadingDialog";
import { MealPlanDayList } from "../MealPlanDayList";
import { MealPlanHeader } from "../MealPlanHeader";
import { MealPlanInfoDialog } from "../MealPlanInfoDialog";
import { MealPlanIngredientsListDialog } from "../MealPlanIngredientsListDialog";
import { NextMealPlanStep } from "../NextMealPlanStep";
import { NutritionalInfoDialog } from "../NutritionalInfoDialog";
import { PrintableIngredientsList } from "../PrintableIngredientsList";
import { PrintableMealPlan } from "../PrintableMealPlan";
import { RecipePreviewDialog } from "../RecipePreviewDialog/RecipePreviewDialog";

export const PlanningScreen = React.memo(PlanningScreenComponent);

function PlanningScreenComponent({ state, send }: ScreenProps) {
  const isTemplateMealPlan = useMemo(() => state.context.mealPlan?.owner === "", [state.context.mealPlan]);

  const sourceSlug = useMemo(
    () => (isTemplateMealPlan ? state.context.mealPlan?.slug : state.context.mealPlan?.sourceSlug),
    [state.context.mealPlan, isTemplateMealPlan]
  );

  useEffect(() => {
    if (isBrowser() && state?.context?.formattedMealPlan) {
      // Expose meal plan
      const mealPlan = state.context.formattedMealPlan;
      (window as any).ddMealPlan = mealPlan;
      const ddMealPlanEvent = new CustomEvent("ddMealPlanLoaded", {
        detail: mealPlan,
      });
      window.dispatchEvent(ddMealPlanEvent);

      const mealPlanEl = document.createElement("div");
      mealPlanEl.style.display = "none";
      mealPlanEl.setAttribute("id", "dd-mealplan");
      mealPlanEl.setAttribute("data-json", JSON.stringify(mealPlan));
      document.head.appendChild(mealPlanEl);

      // Expose recipes
      const recipes = groupSameRecipesTogether(state.context.formattedMealPlan);
      (window as any).ddRecipes = recipes;

      const ddRecipesEvent = new CustomEvent("ddRecipesLoaded", {
        detail: recipes,
      });
      window.dispatchEvent(ddRecipesEvent);

      const recipesEl = document.createElement("div");
      recipesEl.style.display = "none";
      recipesEl.setAttribute("id", "dd-recipes");
      recipesEl.setAttribute("data-json", JSON.stringify(recipes));
      document.head.appendChild(recipesEl);
    }
  }, [state.context.formattedMealPlan]);

  return (
    <>
      <div style={{ maxWidth: "1200px" }} className="m-auto print:hidden">
        <MealPlanHeader state={state} send={send} />
        <MealPlanDayList state={state} send={send} />
        <RecipePreviewDialog state={state} send={send} />
        <CopyRecipeDialog state={state} send={send} />
        <RecipeSelectorDialog state={state} send={send} />
        <MealPlanInfoDialog state={state} send={send} />
        <NutritionalInfoDialog state={state} send={send} />
        <MealPlanIngredientsListDialog state={state} send={send} />
        <CopyMealPlanDialog state={state} send={send} />
        <EditMealPlanDialog state={state} send={send} />
        <LoadingDialog state={state} />
        <ConfirmationDialog state={state} send={send} />
        <ErrorDialog state={state} send={send} />
        <ActionConfirmationDialog state={state} send={send} />
        <NextMealPlanStep sourceSlug={sourceSlug} />
      </div>
      <PrintableMealPlan state={state} />
      <PrintableIngredientsList state={state} />
    </>
  );
}
