import { plural, t } from "@lingui/macro";
import { FaChartPie } from "@react-icons/all-files/fa/FaChartPie";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { GetMealplanBySlug_mealplanBySlug_schedule_breakfast_recipesDetails_ingredientSections } from "../../../types/GetMealplanBySlug";
import { RecipeDetails, Unit } from "../../../types/MealPlan";
import { MOBILE_LG } from "../../../utils/breakpoints";
import { default as colors } from "../../../utils/colors.json";
import { getRecipeIngredientDisplayText } from "../../../utils/ingredient";
import IngredientLabel from "../../IngredientLabel/IngredientLabel";
import { NutritionContent } from "../../Nutrition/Nutrition";

const Container = styled.div`
  position: relative;
  background-color: ${colors.BEIGE};
  padding: 20px;
  margin: 0 -20px;

  @media (min-width: ${MOBILE_LG}) {
    padding: 35px;
    margin: 0;
  }

  @media print {
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
`;

const Servings = styled.div`
  margin-bottom: 14px;

  @media print {
    font-size: 14px;
  }
`;

const Title = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 20px 0 4px;

  @media (min-width: ${MOBILE_LG}) {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -0.7px;
  }

  @media print {
    font-size: 18px;
    line-height: 20px;
  }
`;

const IngredientSectionContainer = styled.div`
  margin: 0 0 30px;

  @media print {
    font-size: 14px;
    line-height: 20px;
  }
`;

const IngredientSectionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const IngredientList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const IngredientListItem = styled.li`
  padding-bottom: 7px;
`;

const NutritionFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.GREY_LIGHTER};
  border: 3px solid ${colors.GREY_LIGHTER};

  margin: 0 -20px 30px;

  @media (min-width: ${MOBILE_LG}) {
    cursor: pointer;
    margin: 0 0 50px;
    border-radius: 0 0 35px 0;
  }

  @media print {
    display: none;
  }
`;

const NutritionAnchor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px 15px 20px;
  font-size: 18px;
  font-weight: 600;

  @media (min-width: ${MOBILE_LG}) {
    padding: 15px 25px 15px 35px;
  }
`;

const NutritionLabel = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }
`;

const NutritionContentContainer = styled.div`
  position: relative;
  background-color: ${colors.WHITE};
  padding: 35px;

  @media (min-width: ${MOBILE_LG}) {
    border-radius: 0 0 35px 0;
  }
`;

function useTranslations() {
  return {
    ingredientsHeading: t({
      id: "MealPlanPage.RecipesTab.ingredientsHeading",
      message: "Ingredients",
    }),
    nutritionHeading: t({
      id: "MealPlanPage.RecipesTab.nutritionHeading",
      message: "Nutrition",
    }),
    servingsLabel: (servings: number, isDinnerLunchCombo: boolean) => {
      if (isDinnerLunchCombo) {
        return t({
          id: "MealPlanPage.RecipesTab.dinnerLunchComboServingsLabel",
          message: plural(servings, {
            one: "# + # serving (dinner + lunch)",
            other: "# + # servings (dinner + lunch)",
          }),
          // FIXME: Remove the replace function call once this bug is fixed:
          // https://github.com/lingui/js-lingui/issues/970 and a new Lingui version is released.
        }).replace("#", `${servings}`);
      } else {
        return t({
          id: "MealPlanPage.RecipesTab.servingsLabel",
          message: plural(servings, {
            one: "# serving",
            other: "# servings",
          }),
        });
      }
    },
  };
}

const NutritionFooter = ({ recipe }: { recipe: RecipeDetails }) => {
  const tt = useTranslations();
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  return (
    <NutritionFooterContainer onClick={toggle}>
      <NutritionAnchor>
        <NutritionLabel>
          <FaChartPie />
          <span>{tt.nutritionHeading}</span>
        </NutritionLabel>
        {isOpen ? <FaMinus /> : <FaPlus />}
      </NutritionAnchor>
      {isOpen && (
        <NutritionContentContainer>
          <NutritionContent nutrition={recipe.nutrition} strictness={recipe.strictness.rating} />
        </NutritionContentContainer>
      )}
    </NutritionFooterContainer>
  );
};

const IngredientSection = ({
  ingredientSection,
  unit,
  servingSize,
}: {
  ingredientSection: GetMealplanBySlug_mealplanBySlug_schedule_breakfast_recipesDetails_ingredientSections;
  unit: Unit;
  servingSize: number;
}) => {
  return (
    <IngredientSectionContainer>
      <IngredientSectionTitle>{ingredientSection.title}</IngredientSectionTitle>
      <IngredientList>
        {ingredientSection.ingredients.map((ingredientSectionItem, index) => {
          const ingredient = ingredientSectionItem.ingredient;
          const additionalIngredients = ingredientSectionItem.additionalIngredients.map((aditional) => {
            return aditional.ingredient;
          });

          const alternateIngredients = ingredientSectionItem.alternateIngredients.map((alternate) => {
            return alternate.ingredient;
          });

          const { displayText } = getRecipeIngredientDisplayText(
            ingredientSectionItem,
            ingredient,
            additionalIngredients,
            alternateIngredients,
            unit,
            servingSize
          );
          return (
            <IngredientListItem key={index}>
              <IngredientLabel label={displayText} />
            </IngredientListItem>
          );
        })}
      </IngredientList>
    </IngredientSectionContainer>
  );
};

const IngredientsBox = ({
  recipe,
  unit,
  servingSize,
  isDinnerLunchCombo,
}: {
  recipe: RecipeDetails;
  unit: Unit;
  servingSize: number;
  isDinnerLunchCombo: boolean;
}) => {
  const tt = useTranslations();
  const recipeServings = isDinnerLunchCombo ? servingSize * 2 : servingSize;
  return (
    <>
      <Container>
        <Servings>{tt.servingsLabel(servingSize, isDinnerLunchCombo)}</Servings>
        <Title>{tt.ingredientsHeading}</Title>
        {recipe.ingredientSections.map((ingredientSection, index) => (
          <IngredientSection
            key={index}
            ingredientSection={ingredientSection}
            unit={unit}
            servingSize={recipeServings}
          />
        ))}
      </Container>
      <NutritionFooter recipe={recipe} />
    </>
  );
};

export default IngredientsBox;
