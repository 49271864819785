import React, { useContext } from "react";
import { Link } from "../../../../../components/Link/Link";
import { pageContext } from "../../../../../components/PageProvider/PageProvider";
import { SatietyScorePage } from "../../../../../pages";
import { Locale } from "../../../../../types/Locale";
import { MealPlanMachineSend, MealPlanMachineState, NutritionalInfo as NutritionalInfoType } from "../../types";
import { getEnergyLevel, getMealPlanDay, parseNutritionalInfo } from "../../utils";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader, HeaderTitle } from "./Dialog/DialogHeader";
import { MealPlannerTranslations, MealPlannerTranslationsContext } from "./translations";

export const NutritionalInfoDialog = React.memo(NutritionalInfoDialogComponent);

function NutritionalInfoDialogComponent({ state, send }: NutritionalInfoProps) {
  const inSelectRecipeNutritionDialog =
    state.matches("planning.selectRecipe.suggestions.viewingRecipeCardNutritionalInfo") ||
    state.matches("planning.selectRecipe.favorites.viewingRecipeCardNutritionalInfo") ||
    state.matches("planning.selectRecipe.search.viewingRecipeCardNutritionalInfo");

  const isDialogOpen =
    state.matches("planning.viewingMealPlanNutritionalInfo") ||
    state.matches("planning.viewingDayNutritionalInfo") ||
    inSelectRecipeNutritionDialog ||
    state.matches("planning.viewingRecipeNutritionalInfo");

  const closeView = () =>
    inSelectRecipeNutritionDialog ? send({ type: "GO_TO_PREVIOUS_VIEW" }) : send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <NutritionalInfo state={state} send={send} inSelectRecipe={inSelectRecipeNutritionDialog} />
    </Dialog>
  );
}

interface NutritionalInfoProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  inSelectRecipe?: boolean;
}

function NutritionalInfo({ state, send, inSelectRecipe }: NutritionalInfoProps) {
  const tt = useContext(MealPlannerTranslationsContext);

  let nutritionalInfo: NutritionalInfoType;
  let energyLevelLabel;
  let nutritionCalculateInfo;
  let satietyScore;
  if (state.matches("planning.viewingMealPlanNutritionalInfo")) {
    const {
      isHighProtein,
      strictness: { rating: strictness },
      nutritionAverage,
    } = state.context.mealPlan!;
    energyLevelLabel = tt.energyLevel[getEnergyLevel(isHighProtein, strictness)];
    nutritionalInfo = parseNutritionalInfo(nutritionAverage);
    nutritionCalculateInfo = tt.nutritionalInfo.averageNutritionPerDayLabel;
  } else if (state.matches("planning.viewingDayNutritionalInfo")) {
    const {
      nutrition,
      strictness: { rating: strictness },
    } = getMealPlanDay(state.context)!;

    const isHighProtein = false; //WP does not support isHighProtein for days yet
    energyLevelLabel = tt.energyLevel[getEnergyLevel(isHighProtein, strictness)];
    nutritionalInfo = parseNutritionalInfo(nutrition);
    nutritionCalculateInfo = tt.nutritionalInfo.totalNutritionForDayLabel;
  } else if (
    state.matches("planning.selectRecipe.suggestions.viewingRecipeCardNutritionalInfo") ||
    state.matches("planning.selectRecipe.favorites.viewingRecipeCardNutritionalInfo") ||
    state.matches("planning.selectRecipe.search.viewingRecipeCardNutritionalInfo") ||
    state.matches("planning.viewingRecipeNutritionalInfo")
  ) {
    const {
      isHighProtein,
      strictness: { rating: strictness },
      nutrition,
    } = state.context.focusedRecipe!;

    satietyScore = state.context.focusedRecipe?.satietyScore;
    energyLevelLabel = tt.energyLevel[getEnergyLevel(isHighProtein, strictness)];
    nutritionalInfo = parseNutritionalInfo(nutrition);
    nutritionCalculateInfo = tt.nutritionalInfo.totalNutritionForRecipe;
  }

  const onClose = inSelectRecipe
    ? () => send({ type: "GO_TO_PREVIOUS_VIEW" })
    : () => send({ type: "CLOSE_VIEW" });

  return (
    <div className="z-10 fixed sm:static inset-0 sm:inset-auto sm:max-w-3/4 sm:max-h-3/4 sm:rounded-xl sm:shadow-lg bg-white overflow-y-auto">
      <DialogHeader menuType="dayMenu" state={state} send={send} onClose={onClose} backButton={!inSelectRecipe}>
        <HeaderTitle>{tt.nutritionalInfoDialog.dialogTitle}</HeaderTitle>
      </DialogHeader>
      <div className="p-4 pt-8">
        <Heading
          energyLabel={energyLevelLabel}
          nutritionalInfo={nutritionalInfo!}
          nutritionCalculateInfo={nutritionCalculateInfo}
        />
        <MainStats nutritionalInfo={nutritionalInfo!} className="mb-6" />
        <AdditionalStats nutritionalInfo={nutritionalInfo!} satietyScore={satietyScore} />
      </div>
    </div>
  );
}

export function Heading({ energyLabel, nutritionalInfo, nutritionCalculateInfo }: HeadingProps) {
  return (
    <div className="mb-8 flex">
      <div className="flex-1">
        <h3 className="m-0 font-medium">{energyLabel}</h3>
        <p className="w-10/12">{nutritionCalculateInfo}</p>
      </div>
      <NutritionalChart nutritionalInfo={nutritionalInfo} className="h-40 w-40" />
    </div>
  );
}

interface HeadingProps {
  energyLabel?: string;
  nutritionalInfo: NutritionalInfoType;
  className?: string;
  nutritionCalculateInfo?: string;
}

function NutritionalChart({ nutritionalInfo, className = "" }: NutritionalChartProps) {
  const tt = useContext(MealPlannerTranslationsContext);
  const { netCarbs, protein, fat, calories } = nutritionalInfo;
  const segments = React.useMemo(
    () =>
      [
        { id: "netCarbs", ...netCarbs, offset: 0 },
        { id: "protein", ...protein, offset: netCarbs.percentage },
        { id: "fat", ...fat, offset: netCarbs.percentage + protein.percentage },
      ] as const,
    [netCarbs, protein, fat]
  );

  return (
    <div className={`relative flex items-center justify-center ${className}`}>
      <svg
        className="absolute inset-0 h-full w-full origin-center -rotate-90"
        width={svgSize}
        height={svgSize}
        viewBox={svgViewBox}
      >
        {segments.map(({ id, percentage, offset }) => (
          <circle
            key={id}
            cx={circleX}
            cy={circleY}
            r={circleRadius}
            strokeWidth={circleStrokeWidth}
            strokeDasharray={`${percentage - segmentGapSize} ${
              circleCircumference - percentage + segmentGapSize
            }`}
            strokeDashoffset={circleCircumference - offset + segmentGapSize / 2}
            className={`stroke-current ${colors[id]?.textColor}`}
            fill="none"
          ></circle>
        ))}
      </svg>
      {tt.nutritionalInfo.caloriesPerServingMessage(calories, "text-center text-xs", "font-medium not-italic")}
    </div>
  );
}

interface NutritionalChartProps {
  nutritionalInfo: NutritionalInfoType;
  className?: string;
}

const circleCircumference = 100; // Useful to use unitless values as percentages.
const circleStrokeWidth = 4;
const circleRadius = circleCircumference / (2 * Math.PI);
const svgSize = 2 * circleRadius + circleStrokeWidth;
const svgViewBox = `0 0 ${svgSize} ${svgSize}`;
const [circleX, circleY] = [svgSize / 2, svgSize / 2];
const segmentGapSize = 0.5;

export function MainStats({ nutritionalInfo, className = "" }: MainStatsProps) {
  const tt = useContext(MealPlannerTranslationsContext);
  const { netCarbs, protein, fat } = nutritionalInfo;
  const stats = React.useMemo(
    () =>
      [
        { id: "netCarbs", ...netCarbs },
        { id: "protein", ...protein },
        { id: "fat", ...fat },
      ] as const,
    [netCarbs, protein, fat]
  );

  return (
    <div className={`flex justify-center gap-x-4 ${className}`}>
      {stats.map((props) => (
        <NutritionalStat key={props.id} tt={tt} {...props} />
      ))}
    </div>
  );
}

interface MainStatsProps {
  nutritionalInfo: NutritionalInfoType;
  className?: string;
}

export function AdditionalStats({ nutritionalInfo, satietyScore }: AdditionalStatsProps) {
  const tt = useContext(MealPlannerTranslationsContext);
  const { locale } = useContext(pageContext);
  const { fiber, totalCarbs } = nutritionalInfo;
  const stats = React.useMemo(
    () =>
      (
        [
          { id: "fiber", amount: fiber },
          { id: "totalCarbs", amount: totalCarbs },
        ] as const
      ).filter(({ amount }) => typeof amount !== "undefined"),
    [fiber, totalCarbs]
  );

  return (
    <div className="text-sm font-medium">
      {stats.map(({ id, amount }) => {
        const name = tt.nutritionalInfo.nutrients[id];
        return (
          <div key={id} className="first:border-t border-b border-sand py-3 flex">
            <span className="w-2/3 uppercase">{name}</span>
            <span className="w-1/3">{tt.quantities.amountInGrams(amount!, 0)}</span>
          </div>
        );
      })}
      {satietyScore !== undefined && satietyScore !== null && locale === Locale.EN && (
        <div className="first:border-t border-b border-sand py-3 flex">
          <Link to={SatietyScorePage} target="_blank" className="w-2/3 uppercase">
            Satiety Score
          </Link>
          <span className="w-1/3">{Math.round(satietyScore)}</span>
        </div>
      )}
    </div>
  );
}

interface AdditionalStatsProps {
  nutritionalInfo: NutritionalInfoType;
  satietyScore?: number | null;
  className?: string;
}

function NutritionalStat({ id, amount, percentage, tt }: NutritionalStatProps) {
  const bgColor = colors[id]?.bgColor;
  const textColor = colors[id]?.textColor;
  const statName = tt.nutritionalInfo.nutrients[id];

  return (
    <div className="flex-1 flex flex-col">
      <div className={`h-1.5 rounded-full mb-2 ${bgColor}`} />
      <span className="px-1 text-sm font-medium uppercase">{statName}</span>
      <span className="px-1 text-lg font-medium">{tt.quantities.amountInGrams(amount, 0)}</span>
      <span className={`px-1 text-sm font-medium ${textColor}`}>{tt.quantities.percentage(percentage, 0)}</span>
    </div>
  );
}

interface NutritionalStatProps {
  id: keyof NutritionalInfoType;
  amount: number;
  percentage: number;
  tt: MealPlannerTranslations;
}

const colors: Partial<Record<keyof NutritionalInfoType, { textColor: string; bgColor: string }>> = {
  netCarbs: {
    textColor: "text-mpt-green",
    bgColor: "bg-mpt-green",
  },
  protein: {
    textColor: "text-mpt-blue",
    bgColor: "bg-mpt-blue",
  },
  fat: {
    textColor: "text-mpt-orange",
    bgColor: "bg-mpt-orange",
  },
};
