import React from "react";

export function FavoriteIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M17.4563 3.56366C15.7163 3.56366 14.0463 4.37366 12.9563 5.65366C11.8663 4.37366 10.1963 3.56366 8.4563 3.56366C5.3763 3.56366 2.9563 5.98366 2.9563 9.06366C2.9563 12.8437 6.3563 15.9237 11.5063 20.6037L12.9563 21.9137L14.4063 20.5937C19.5563 15.9237 22.9563 12.8437 22.9563 9.06366C22.9563 5.98366 20.5363 3.56366 17.4563 3.56366ZM13.0563 19.1137L12.9563 19.2137L12.8563 19.1137C8.0963 14.8037 4.9563 11.9537 4.9563 9.06366C4.9563 7.06366 6.4563 5.56366 8.4563 5.56366C9.9963 5.56366 11.4963 6.55366 12.0263 7.92366H13.8963C14.4163 6.55366 15.9163 5.56366 17.4563 5.56366C19.4563 5.56366 20.9563 7.06366 20.9563 9.06366C20.9563 11.9537 17.8163 14.8037 13.0563 19.1137Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
