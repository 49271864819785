import React from "react";
import { weekdays } from "../../../../../utils/date";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import MealPlanDay from "./MealPlanDay";

export const MealPlanDayList = React.memo(MealPlanDayListComponent);

function MealPlanDayListComponent({ state, send }: MealPlanDayListProps) {
  const formattedMealPlan = state.context.formattedMealPlan!;

  return (
    <>
      {formattedMealPlan
        .filter((item) => weekdays.includes(item.weekday))
        .map((item) => (
          <MealPlanDay key={item.weekday} dayPayload={item} send={send} state={state} />
        ))}
    </>
  );
}

interface MealPlanDayListProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}
