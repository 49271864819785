import React from "react";
import CarbCircle from "../../../../../components/CarbCircle/CarbCircle";
import { LoadingIcon } from "../../../../../components/Icon/Icon";
import { ScreenSize, useScreenSize } from "../../../../../utils/screen-sizes";
import { RecipePage } from "../../../../../pages";
import { getSrc } from "../../../../../utils/image";
import { getRecipeTime } from "../../../../../utils/recipe";
import { upperFirst } from "../../../../../utils/string";
import { ClockIcon } from "../../icons/ClockIcon";
import { PlusIcon } from "../../icons/PlusIcon";
import { CopyToIcon } from "../../icons/RecipeMenu/CopyToIcon";
import { FavoriteIcon } from "../../icons/RecipeMenu/FavoriteIcon";
import { ShareRecipeIcon } from "../../icons/RecipeMenu/ShareRecipeIcon";
import { SwapIcon } from "../../icons/RecipeMenu/SwapIcon";
import { UnfavoriteIcon } from "../../icons/RecipeMenu/UnfavoriteIcon";
import { ViewRecipeIcon } from "../../icons/RecipeMenu/ViewRecipeIcon";
import { RemoveIcon } from "../../icons/RemoveIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getRecipeByIndex, isMealPlanModifiable } from "../../utils";
import { List, ListItem } from "../list";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader } from "./Dialog/DialogHeader";
import { SimpleMenuItem, SimpleMenuLink } from "./MealPlanMenu";
import { Popover } from "./Popover";
import { ServingsMenuItem } from "./ServingsMenuItem";
import { MealPlannerTranslationsContext } from "./translations";

export const RecipeMenu = React.memo(RecipeMenuComponent);
interface RecipeMenuProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function RecipeMenuComponent({ state, send }: RecipeMenuProps) {
  const isOpen =
    state.matches("planning.viewingRecipeMenu") &&
    !state.matches("planning.viewingRecipeMenu.viewingRecipePreview");

  const isFullScreen = useScreenSize() < ScreenSize.LG;

  const closeMenu = () => send({ type: "CLOSE_VIEW" });

  if (isFullScreen) {
    return (
      <Dialog open={isOpen} onClose={closeMenu}>
        <RecipeMenuContent state={state} send={send} className="z-20 fixed inset-0" />
      </Dialog>
    );
  } else {
    return (
      <Popover open={isOpen} onClose={closeMenu} className="z-20 absolute top-0 right-0 ">
        <RecipeMenuContent state={state} send={send} className="relative rounded-2xl" />
      </Popover>
    );
  }
}
interface RecipeMenuContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  className: string;
}

function RecipeMenuContent({ state, send, className = "" }: RecipeMenuContentProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const {
    mealPlan,
    userId,
    currentDay,
    currentMeal,
    currentRecipe,
    currentRecipeIndex,
    favoriteRecipes,
    isPremium,
    link,
  } = state.context;
  const canModifyMealPlan = isMealPlanModifiable(mealPlan!, userId);
  const isFavorited = favoriteRecipes.filter((recipe) => recipe!.id === currentRecipe!.recipe!.id).length > 0;
  const { recipe, servings } = getRecipeByIndex(state.context)!;
  const recipeLink = link({ to: RecipePage, params: { slug: currentRecipe!.recipe!.slug } });

  const handleSwapRecipe = () => send({ type: "CHANGE_RECIPE" });
  const handleRemoveRecipe = () => {
    send({
      type: "REMOVE_RECIPE",
      day: currentDay!,
      meal: currentMeal!,
      index: currentRecipeIndex!,
    });
  };
  const handleShareRecipe = () => send({ type: "SHARE_RECIPE" });
  const handleFavoriteRecipe = () => send({ type: "FAVORITE_RECIPE" });
  const handleUnfavoriteRecipe = () => send({ type: "UNFAVORITE_RECIPE" });
  const updateServings = (servings: number) =>
    send({
      type: "UPDATE_MEAL_PLAN_RECIPE_SERVINGS",
      day: currentDay!,
      meal: currentMeal!,
      recipe: currentRecipe!.recipe!,
      index: currentRecipeIndex!,
      servings,
    });

  const handleAddRecipeToShoppingList = () =>
    send({
      type: "ADD_RECIPES_TO_SHOPPING_LIST",
      recipeOrMealPlanDays: getRecipeByIndex(state.context)!,
    });

  const handleCopyRecipeTo = () => send({ type: "COPY_RECIPE_TO" });

  return (
    <div className={`bg-sand shadow-lg w-full ${className} overflow-y-scroll lg:overflow-auto`}>
      <Header state={state} send={send} />
      <div className="lg:pb-6 flex flex-col items-center">
        {canModifyMealPlan && (
          <List className="mb-4">
            <ServingsMenuItem
              key="servings"
              dataTestid="update-servings-recipe"
              servings={servings}
              allowedServings={recipe!.servings.allowed}
              updateServings={updateServings}
              loading={state.matches("planning.viewingRecipeMenu.updatingMealPlanRecipeServings")}
              canModify={canModifyMealPlan}
            />
            <SimpleMenuItem
              dataTestid="mealplanner-swap-recipe"
              key="swap"
              onClick={handleSwapRecipe}
              label={tt.recipeMenu.swapRecipeButtonLabel}
              icon={<SwapIcon />}
            />
            <SimpleMenuItem
              dataTestid="mealplanner-copy-recipe"
              key="copy"
              onClick={handleCopyRecipeTo}
              label={tt.recipeMenu.copyRecipeButtonLabel}
              icon={<CopyToIcon />}
            />
          </List>
        )}
        <List className="mb-4">
          <SimpleMenuLink
            key="recipe_preview"
            href={recipeLink}
            label={tt.recipeMenu.viewRecipeButtonLabel}
            icon={<ViewRecipeIcon />}
          />
          {isPremium && (
            <>
              <FavoriteRecipeMenuItem
                key="favorite"
                favoriteRecipe={handleFavoriteRecipe}
                unfavoriteRecipe={handleUnfavoriteRecipe}
                isFavorited={isFavorited}
                state={state}
                icon={isFavorited ? <UnfavoriteIcon /> : <FavoriteIcon />}
              />
              <SimpleMenuItem
                key="shoppingList"
                onClick={handleAddRecipeToShoppingList}
                label={tt.recipeMenu.addToShoppingListButtonLabel}
                icon={<PlusIcon disabled={false} />}
              />
            </>
          )}
          <SimpleMenuItem
            key="share"
            onClick={handleShareRecipe}
            label={tt.recipeMenu.shareRecipeButtonLabel}
            icon={<ShareRecipeIcon />}
          />
        </List>
        {canModifyMealPlan && (
          <List className="mb-4">
            <SimpleMenuItem
              key="remove"
              onClick={handleRemoveRecipe}
              label={tt.recipeMenu.removeRecipeButtonLabel}
              icon={<RemoveIcon />}
            />
          </List>
        )}
      </div>
    </div>
  );
}

function Header({ state, send }: RecipeMenuProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const recipe = getRecipeByIndex(state.context)!.recipe!;

  const title = upperFirst(recipe.title);
  const image = recipe.images.hz;
  const cookingTime = tt.quantities.timeInMinutes(getRecipeTime(recipe.time));

  const width = useScreenSize() >= ScreenSize.SM ? "250px" : "";

  return (
    <DialogHeader state={state} send={send} backButton={false} menuType="recipeMenu">
      <div className="flex flex-row">
        <div className="relative m-auto lg:hidden  bg-sand">
          <CarbCircle recipe={recipe} />
          <picture>
            <img
              style={{ height: "125px", width: "125px" }}
              className="rounded-2xl cover-fit"
              alt=""
              src={getSrc(image, { width: 200, height: 200 })}
            />
          </picture>
        </div>

        <div style={{ minWidth: width }} className="flex flex-col m-auto ml-5 lg:ml-0">
          <h3 style={{ fontSize: "18px" }} className="font-medium m-0">
            {title}
          </h3>
          <div className="flex flew-row text-sm items-center">
            <ClockIcon />
            <span className="ml-1">{cookingTime}</span>
          </div>
        </div>
      </div>
    </DialogHeader>
  );
}

function FavoriteRecipeMenuItem({
  isFavorited,
  favoriteRecipe,
  unfavoriteRecipe,
  state,
  icon,
}: FavoriteRecipeMenuItemProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const label = isFavorited ? tt.recipeMenu.unfavoriteRecipeButtonLabel : tt.recipeMenu.favoriteButtonLabel;
  const handleClick = isFavorited ? unfavoriteRecipe : favoriteRecipe;
  const loading =
    state.matches("planning.viewingRecipeMenu.favoritingRecipe.loading") ||
    state.matches("planning.viewingRecipeMenu.unfavoritingRecipe.loading");

  return (
    <ListItem button onClick={handleClick}>
      {loading ? (
        <div>
          <LoadingIcon />
        </div>
      ) : (
        <div>{label}</div>
      )}
      {icon}
    </ListItem>
  );
}

interface FavoriteRecipeMenuItemProps {
  isFavorited: boolean;
  favoriteRecipe: () => void;
  unfavoriteRecipe: () => void;
  state: MealPlanMachineState;
  icon?: JSX.Element;
}
