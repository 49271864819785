import React from "react";

export const Dialog = React.memo(DialogComponent);

function DialogComponent({ open, onClose, className = "justify-center", children }: DialogProps) {
  // Scroll lock
  // Adapted from https://github.com/tailwindlabs/headlessui/blob/%40headlessui/react%40v1.3.0/packages/@headlessui-react/src/components/dialog/dialog.tsx
  React.useEffect(() => {
    if (!open) return;

    const overflow = document.documentElement.style.overflow;
    const paddingRight = document.documentElement.style.paddingRight;
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.documentElement.style.overflow = overflow;
      document.documentElement.style.paddingRight = paddingRight;
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div className={`z-9999 fixed inset-0 flex flex-col items-center h-screen print:hidden ${className}`}>
      <div onClick={onClose} className="fixed inset-0 bg-black bg-opacity-60" />
      {children}
    </div>
  );
}

type DialogProps = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  className?: string;
}>;
