import React from "react";
import { IconProps } from "./types";

export const PrintIcon = React.memo(PrintIconComponent);

function PrintIconComponent({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.1794 8.20203H5.17944C3.51944 8.20203 2.17944 9.54203 2.17944 11.202V17.202H6.17944V21.202H18.1794V17.202H22.1794V11.202C22.1794 9.54203 20.8394 8.20203 19.1794 8.20203ZM16.1794 19.202H8.17944V14.202H16.1794V19.202ZM19.1794 12.202C18.6294 12.202 18.1794 11.752 18.1794 11.202C18.1794 10.652 18.6294 10.202 19.1794 10.202C19.7294 10.202 20.1794 10.652 20.1794 11.202C20.1794 11.752 19.7294 12.202 19.1794 12.202ZM18.1794 3.20203H6.17944V7.20203H18.1794V3.20203Z" />
    </svg>
  );
}
