import React from "react";
import { IconProps } from "./types";

export const SuccessIcon = React.memo(SuccessIconComponent);

function SuccessIconComponent({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M37.5558 0.456543C16.7866 0.456543 0.0557861 17.1873 0.0557861 37.9565C0.0557861 58.7258 16.7866 75.4565 37.5558 75.4565C58.325 75.4565 75.0558 58.7258 75.0558 37.9565C75.0558 17.1873 58.325 0.456543 37.5558 0.456543ZM58.6135 28.1488L32.9404 53.8219C32.075 54.6873 30.9212 54.9758 29.7673 54.9758C28.6135 54.9758 27.4596 54.6873 26.5942 53.8219L16.4981 43.7258C14.7673 41.995 14.7673 39.3988 16.4981 37.6681C18.2289 35.9373 20.825 35.9373 22.5558 37.6681L29.7673 44.8796L52.5558 22.0912C54.2866 20.3604 56.8827 20.3604 58.6135 22.0912C60.3442 23.5335 60.3442 26.4181 58.6135 28.1488Z" />
    </svg>
  );
}
