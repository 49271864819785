import React from "react";
import {
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients,
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_additionalIngredients,
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_alternateIngredients,
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_metric,
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_sv,
  MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_us,
} from "../../../../../../types/MealPlanner_GetRecipeQuery";
import { MealPlanMachineSend, MealPlanMachineState } from "../../../types";
import { Alert } from "../../Alert";
import { MealPlannerTranslationsContext } from "../translations";

interface IngredientsSectionProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  totalServings: number;
}

export const IngredientsSection = React.memo(IngredientsSectionComponent);

function IngredientsSectionComponent({ state, send, totalServings }: IngredientsSectionProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);

  return (
    <div style={{ height: "fit-content" }} className="px-4 sm:px-0 lg:border-sand lg:rounded-2xl lg:border-2">
      <div className="flex justify-between items-center">
        <h3 className="font-medium lg:m-0 lg:px-6 lg:pt-6">{tt.recipePreviewDialog.ingredientsSectionLabel}</h3>
      </div>
      <IngredientsContent state={state} send={send} totalServings={totalServings} />
    </div>
  );
}

function IngredientsContent({ state, totalServings }: IngredientsSectionProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const allowedServings = state.context.fullRecipe?.servings.allowed;
  const isMisMatchingServings = allowedServings?.find((size) => size === totalServings);

  const ingredientSections = state.context.fullRecipe?.ingredientSections;

  const unit = state.context.ingredientUnit;

  return (
    <div className="lg:px-12">
      {isMisMatchingServings === undefined && (
        <Alert severity="info">{tt.recipePreviewDialog.mismatchServingsMessage}</Alert>
      )}
      <div>
        {ingredientSections?.map((section, number) => (
          <div key={`${section.title}-${number}`}>
            <p className="font-medium" style={{ fontSize: "18px" }}>
              {section.title}
            </p>
            <div>
              {section.ingredients!.map(
                (
                  ingredient: MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients,
                  index: number
                ) => (
                  <p key={index}>{formatIngredient(ingredient, totalServings, unit)}</p>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

type unitType =
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_us
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_metric
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_sv;

function formatIngredient(
  data: MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients,
  servings: number,
  unit: "sv" | "us" | "metric"
) {
  //correct locale and serving size
  const valueForCorrectLocale = data.values![unit].filter(
    (servingSizeItem: unitType) => servingSizeItem.servingSize === servings
  )[0];

  //Due to using non mealPlanReady tag when selecting recipe
  if (valueForCorrectLocale !== undefined) {
    //weight and volume
    const dualValue =
      valueForCorrectLocale.dualValue.value && valueForCorrectLocale.dualValue.unit
        ? `(${valueForCorrectLocale.dualValue.value} ${valueForCorrectLocale.dualValue.unit})`
        : "";

    const optional = data.optional ? "(optional)" : "";

    const value = `${valueForCorrectLocale.value} ${valueForCorrectLocale.unit}`;

    const title = getCorrectSingularOrPluralTitle(valueForCorrectLocale, data);

    const beforeName = data.beforeName && data.beforeName.length > 0 && addingBeforeName(data);
    const afterName = data.afterName && data.afterName.length > 0 && addingAfterName(data);

    const extendedTitle = beforeName + title + afterName;

    const additionalIngredients =
      data.additionalIngredients && data.additionalIngredients.length > 0
        ? " and " +
          data.additionalIngredients
            .map((additionalIngredient) => {
              const additionalIngredientWithCorrectTitle = getCorrectSingularOrPluralTitle(
                valueForCorrectLocale,
                additionalIngredient
              );
              const beforeName =
                additionalIngredient.beforeName &&
                additionalIngredient.beforeName.length > 0 &&
                addingBeforeName(additionalIngredient);

              const afterName =
                additionalIngredient.afterName &&
                additionalIngredient.afterName?.length > 0 &&
                addingAfterName(additionalIngredient);

              return beforeName + additionalIngredientWithCorrectTitle + afterName;
            })
            .join(" ")
        : "";

    const alternateIngredients =
      data.alternateIngredients && data.alternateIngredients.length > 0
        ? " or " +
          data.alternateIngredients
            .map((alternateIngredient) => {
              const alternateIngredientWithCorrectTitle = getCorrectSingularOrPluralTitle(
                valueForCorrectLocale,
                alternateIngredient
              );
              const beforeName =
                alternateIngredient.beforeName &&
                alternateIngredient.beforeName.length > 0 &&
                addingBeforeName(alternateIngredient);

              const afterName =
                alternateIngredient.afterName &&
                alternateIngredient.afterName.length > 0 &&
                addingAfterName(alternateIngredient);

              return beforeName + alternateIngredientWithCorrectTitle + afterName;
            })
            .join(" ")
        : "";

    return `${value} ${dualValue} ${extendedTitle} ${additionalIngredients} ${alternateIngredients} ${optional}`;
  } else {
    return data.ingredient.titles.plural;
  }
}

function getCorrectSingularOrPluralTitle(
  valueForCorrectLocale: valueForCorrectLocalType,
  data: ingredientType
) {
  return parseInt(valueForCorrectLocale.value) <= 1
    ? data.ingredient.titles.singular
    : data.ingredient.titles.plural;
}

type valueForCorrectLocalType =
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_us
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_metric
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_values_sv;

type ingredientType =
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_alternateIngredients
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients_additionalIngredients
  | MealPlanner_GetRecipeQuery_recipe_ingredientSections_ingredients;

function addingBeforeName(ingredient: ingredientType) {
  return ingredient.beforeName + " ";
}

function addingAfterName(ingredient: ingredientType) {
  if (ingredient.afterName.startsWith(",")) {
    return ingredient.afterName;
  } else {
    return " " + ingredient.afterName;
  }
}
