import React from "react";
import CarbCircle from "../../../../../components/CarbCircle/CarbCircle";
import { ScreenSize, useScreenSize } from "../../../../../utils/screen-sizes";
import { RecipePage } from "../../../../../pages";
import { MealPlanner_RecipeWithServings_recipe } from "../../../../../types/MealPlanner_RecipeWithServings";
import { getSrc } from "../../../../../utils/image";
import { getRecipeTime } from "../../../../../utils/recipe";
import { ClockIcon } from "../../icons/ClockIcon";
import { MoreIcon } from "../../icons/MoreIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getServingSizeForRecipeSlot } from "../../utils";
import { IconButton } from "../buttons";
import { List } from "../list";
import { Dialog } from "../MealPlanner/Dialog/Dialog";
import { DialogHeader } from "../MealPlanner/Dialog/DialogHeader";
import { SimpleMenuItem, SimpleMenuLink } from "../MealPlanner/MealPlanMenu";
import { MealPlannerTranslationsContext } from "../MealPlanner/translations";

const RecipeCard = ({
  item,
  handleClickOnRecipe,
  state,
  send,
  history,
}: {
  item: MealPlanner_RecipeWithServings_recipe;
  handleClickOnRecipe: (id: string) => void;
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  history: "suggestions" | "favorites" | "search";
}) => {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const showRecipeCardMenu =
    (state.matches("planning.selectRecipe.suggestions.viewingRecipeCardMenu") ||
      state.matches("planning.selectRecipe.favorites.viewingRecipeCardMenu") ||
      state.matches("planning.selectRecipe.search.viewingRecipeCardMenu")) &&
    state.context.focusedRecipe?.slug === item.slug;

  const handleClickOnMenu = () => send({ type: "TOGGLE_RECIPE_CARD_MENU", recipe: item, history });

  const handleClickOnCarbCircle = () =>
    send({ type: "SHOW_RECIPE_CARD_NUTRITIONAL_INFO", recipe: item, history });

  return (
    <div
      className="flex border-grey-meal-planner border-b hover:bg-lightest-grey cursor-pointer p-4"
      key={item.id}
    >
      <div
        style={{
          height: "160px",
          width: "160px",
          aspectRatio: "1/1",
        }}
        className="relative"
      >
        <CarbCircle onClick={handleClickOnCarbCircle} recipe={item} />
        <picture>
          <img
            style={{
              height: "160px",
              width: "160px",
              aspectRatio: "1/1",
            }}
            className="rounded-2xl w-full object-cover"
            alt=""
            src={getSrc(item!.images.hz, { width: 1200, height: 1200 })}
          />
        </picture>
      </div>
      <div
        data-testid={`mealplanner-recipe-list-${history}-${item.id}`}
        className="flex flex-col justify-center ml-6 w-full"
        onClick={() => handleClickOnRecipe(item.id)}
      >
        <span className="font-medium text-sm sm:text-xl">{item.title}</span>
        <div className="flex items-center">
          <ClockIcon />
          <span className="text-sm ml-1">{tt.quantities.timeInMinutes(getRecipeTime(item.time))}</span>
        </div>
        <p style={{ maxWidth: "90%" }} className="hidden lg:block text-sm ">
          {item.description}
        </p>
      </div>
      <div className="relative">
        <IconButton
          data-testid="mealplanner-recipe-list-context-menu"
          style={{ width: "fit-content", height: "fit-content" }}
          kind="default"
          onClick={handleClickOnMenu}
          className="m-3"
        >
          <MoreIcon className="bg-lighter-grey rounded-3xl" />
        </IconButton>
        {showRecipeCardMenu && <RecipeCardMenu state={state} send={send} />}
      </div>
    </div>
  );
};

export default RecipeCard;

interface RecipeCardMenuProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

const RecipeCardMenu = ({ state, send }: RecipeCardMenuProps) => {
  const isOpen =
    state.matches("planning.selectRecipe.suggestions.viewingRecipeCardMenu") ||
    state.matches("planning.selectRecipe.favorites.viewingRecipeCardMenu") ||
    state.matches("planning.selectRecipe.search.viewingRecipeCardMenu");

  const isFullScreen = useScreenSize() < ScreenSize.LG;

  const closeMenu = () => send({ type: "TOGGLE_RECIPE_CARD_MENU" });

  if (isFullScreen) {
    return (
      <Dialog open={isOpen} onClose={closeMenu}>
        <RecipeCardMenuContent state={state} send={send} className="z-20 fixed inset-0" />
      </Dialog>
    );
  } else {
    return (
      <div className="z-20 absolute top-0 right-0 ">
        <div onClick={closeMenu} />
        <RecipeCardMenuContent state={state} send={send} className="relative rounded-2xl" />
      </div>
    );
  }
};

interface RecipeCardMenuContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  className: string;
}

const RecipeCardMenuContent = ({ state, send, className = "" }: RecipeCardMenuContentProps) => {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const { focusedRecipe, link, currentDay, currentMeal, currentRecipeIndex } = state.context;
  const servings = getServingSizeForRecipeSlot(state.context);

  const recipeLink = link({ to: RecipePage, params: { slug: focusedRecipe!.slug } });

  const handleClickOnNutrition = () => send({ type: "SHOW_RECIPE_CARD_NUTRITIONAL_INFO" });
  const handleSelect = (recipeId: string) => {
    send({
      type: "ADD_RECIPE",
      day: currentDay!,
      meal: currentMeal!,
      recipeId,
      servings,
      index: currentRecipeIndex,
    });
  };
  return (
    <div className={`bg-sand shadow-lg w-full ${className} overflow-y-scroll lg:overflow-auto`}>
      <DialogHeader
        state={state}
        send={send}
        backButton={false}
        onClose={() => send({ type: "TOGGLE_RECIPE_CARD_MENU" })}
      >
        <p style={{ width: "100px" }}></p>
      </DialogHeader>
      <div>
        <div className="sm:pb-6 flex flex-col items-center">
          <List className="mb-4">
            <SimpleMenuItem
              key="select"
              onClick={() => handleSelect(state.context.focusedRecipe!.id)}
              label={tt.recipeCardMenu.selectLabel}
            />
            <SimpleMenuLink
              openNewTab={true}
              key="recipe_preview"
              href={recipeLink}
              label={tt.recipeMenu.viewRecipeButtonLabel}
            />
            <SimpleMenuItem
              key="nutrition"
              onClick={handleClickOnNutrition}
              label={tt.mealPlanMenu.nutritionalInfoButtonLabel}
            />
          </List>
        </div>
      </div>
    </div>
  );
};
