import React from "react";
import styled from "@emotion/styled";
import { MealPlanner_Recipe } from "../../../../../types/MealPlanner_Recipe";
import { weekdays } from "../../../../../utils/date";
import { getSrc } from "../../../../../utils/image";
import { upperFirst } from "../../../../../utils/string";
import { FormattedMealPlanDay, MealPlanMachineState } from "../../types";
import { MealPlannerTranslationsContext } from "./translations";

export const PrintableMealPlanDayList = React.memo(PrintableMealPlanDayListComponent);

function PrintableMealPlanDayListComponent({ state }: PrintableMealPlanDayListProps) {
  const formattedMealPlan = state.context.formattedMealPlan!;

  return (
    <Container>
      {formattedMealPlan
        .filter((item) => weekdays.includes(item.weekday))
        .map((item) => (
          <PrintableMealPlanDay key={item.weekday} dayPayload={item} />
        ))}
    </Container>
  );
}
const Container = styled.div`
  @media print {
    page-break-after: always;
  }
`;
interface PrintableMealPlanDayListProps {
  state: MealPlanMachineState;
}

function PrintableMealPlanDay({ dayPayload }: { dayPayload: FormattedMealPlanDay }) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const dayTitle = upperFirst(tt.dates.weekdayLong(dayPayload.weekday));
  const breakfastRecipes = dayPayload.mealplanDay.breakfast.recipeWithServings;
  const lunchRecipes = dayPayload.mealplanDay.lunch.recipeWithServings;
  const dinnerRecipes = dayPayload.mealplanDay.dinner.recipeWithServings;

  const amountOfMeals =
    (breakfastRecipes.length > 0 ? 1 : 0) +
    (lunchRecipes.length > 0 ? 1 : 0) +
    (dinnerRecipes.length > 0 ? 1 : 0);

  const marginIfEmptyDay = amountOfMeals === 0 ? "mb-10" : "";

  return (
    <div className="px-6">
      <div className="relative">
        <div className="flex items-center justify-center">
          <div className="flex items-center px-8 bg-sand rounded-3xl">
            <h3 className={`m-0 pr-2 font-medium ${marginIfEmptyDay}`}>{dayTitle}</h3>
          </div>
        </div>
      </div>
      <div style={{ gridTemplateColumns: "repeat(3, 1fr)" }} className={`grid grid-rows-1 gap-x-4`}>
        <div className="col-span-1">
          {breakfastRecipes.map((recipeWithServings, index) => (
            <PrintableMealPlanRecipe
              key={`${recipeWithServings!.recipe!.id}-${index}`}
              recipe={recipeWithServings!.recipe!}
            />
          ))}
        </div>
        <div className="col-span-1">
          {lunchRecipes.map((recipeWithServings, index) => (
            <PrintableMealPlanRecipe
              key={`${recipeWithServings!.recipe!.id}-${index}`}
              recipe={recipeWithServings!.recipe!}
            />
          ))}
        </div>
        <div className="col-span-1">
          {dinnerRecipes.map((recipeWithServings, index) => (
            <PrintableMealPlanRecipe
              key={`${recipeWithServings!.recipe!.id}-${index}`}
              recipe={recipeWithServings!.recipe!}
            />
          ))}
        </div>
        {amountOfMeals === 0 && <div />}
      </div>
    </div>
  );
}

const PrintableMealPlanRecipe = ({ recipe }: { recipe: MealPlanner_Recipe }) => {
  const image = recipe.images.hz;
  const title = recipe.title;

  return (
    <div className={`flex mb-4 w-full pb-5`}>
      <div className="relative col-span-2 my-auto">
        <picture>
          <img
            className="rounded-xl w-16 h-16 object-cover"
            alt=""
            src={getSrc(image, { width: 1200, height: 1200 })}
          />
        </picture>
      </div>

      <div className="flex flex-row justify-between items-start mt-4 col-span-4 ml-2">
        <div className="flex flex-col my-auto h-auto">
          <h3 style={{ fontSize: "14px" }} className="m-0 font-medium pr-4">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};
