import React from "react";
import styled from "@emotion/styled";

export const HighlightedStar = styled.span`
  color: #fdd942;
`;

const DimmedStar = styled.span`
  color: #999999;
`;

const Rating = ({ rating, className }: { rating: number; className?: string }) => {
  const roundedRating = Math.round(rating);
  return (
    <div className={className}>
      {[...Array(5)].map((n, i) => {
        if (i <= roundedRating - 1) {
          return (
            <HighlightedStar key={i} data-testid="highlighted">
              ★
            </HighlightedStar>
          );
        }
        return <DimmedStar key={i}>★</DimmedStar>;
      })}
    </div>
  );
};

export default Rating;
