import React from "react";
import { ScreenSize, useScreenSize } from "../../../../../utils/screen-sizes";
import { MealType } from "../../../../../types/graphql-global-types";
import { DayKey } from "../../../../../types/MealPlan";
import { upperFirst } from "../../../../../utils/string";
import { PlusIcon } from "../../icons/DayMenu/PlusIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getMealPlanDay, isMealPlanModifiable } from "../../utils";
import { List } from "../list";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader } from "./Dialog/DialogHeader";
import { SimpleMenuItem } from "./MealPlanMenu";
import { Popover } from "./Popover";
import { ServingsMenuItem } from "./ServingsMenuItem";
import { MealPlannerTranslationsContext } from "./translations";

export const DayMenu = React.memo(DayMenuComponent);

function DayMenuComponent({ state, send, weekDay }: DayMenuProps) {
  const isOpen = state.matches("planning.viewingDayMenu");
  const isFullScreen = useScreenSize() < ScreenSize.LG;

  const closeMenu = () => send({ type: "CLOSE_VIEW" });

  if (isFullScreen) {
    return (
      <Dialog open={isOpen} onClose={closeMenu}>
        <DayMenuContent state={state} send={send} weekDay={weekDay} className="z-20 fixed inset-0" />
      </Dialog>
    );
  } else {
    return (
      <Popover open={isOpen} onClose={closeMenu} className="z-20 absolute top-0">
        <DayMenuContent state={state} send={send} weekDay={weekDay} className="relative rounded-2xl" />
      </Popover>
    );
  }
}

interface DayMenuProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  weekDay: DayKey;
}

function DayMenuContent({ state, send, weekDay, className = "" }: DayMenuContentProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { currentDay, mealPlan, userId, isPremium, allowedServings } = state.context;

  const handleClickOnAddBreakfast = () => send({ type: "ADD_BREAKFAST", meal: MealType.BREAKFAST });
  const handleClickOnAddLunch = () => send({ type: "ADD_LUNCH", meal: MealType.LUNCH });
  const handleClickOnAddDinner = () => send({ type: "ADD_DINNER", meal: MealType.DINNER });
  const handleNutrition = () => send({ type: "SHOW_DAY_NUTRITIONAL_INFO" });
  const handleAddDayRecipesToShoppingList = () =>
    send({ type: "ADD_RECIPES_TO_SHOPPING_LIST", recipeOrMealPlanDays: [currentDay!] });
  const updateServings = (servings: number) =>
    send({ type: "UPDATE_MEAL_PLAN_DAY_SERVINGS", day: weekDay, servings });

  const canModifyMealPlan = isMealPlanModifiable(mealPlan!, userId);

  const day = getMealPlanDay(state.context);

  const hasBreakfast = (day?.breakfast.recipeWithServings.length ?? 0) > 0;
  const hasLunch = (day?.lunch.recipeWithServings.length ?? 0) > 0;
  const hasDinner = (day?.dinner.recipeWithServings.length ?? 0) > 0;
  const dayHasRecipe = hasBreakfast || hasLunch || hasDinner;

  return (
    <div className={`bg-sand shadow-lg ${className} overflow-y-scroll lg:overflow-auto`}>
      <Header state={state} send={send} weekDay={weekDay} />
      <div className="lg:pb-6 flex flex-col items-center">
        {canModifyMealPlan && (
          <List className="mb-4">
            <SimpleMenuItem
              dataTestid="add-breakfast-button"
              key="breakfast"
              onClick={handleClickOnAddBreakfast}
              label={tt.meals.mealLabel(MealType.BREAKFAST, true)}
              icon={<PlusIcon />}
            />
            <SimpleMenuItem
              dataTestid="add-lunch-button"
              key="lunch"
              onClick={handleClickOnAddLunch}
              label={tt.meals.mealLabel(MealType.LUNCH, true)}
              icon={<PlusIcon />}
            />
            <SimpleMenuItem
              dataTestid="add-dinner-button"
              key="dinner"
              onClick={handleClickOnAddDinner}
              label={tt.meals.mealLabel(MealType.DINNER, true)}
              icon={<PlusIcon />}
            />
          </List>
        )}
        {dayHasRecipe && (
          <List className="mb-4">
            <ServingsMenuItem
              dataTestid="update-servings-day-button"
              key="servings"
              servings={day!.servings}
              allowedServings={allowedServings}
              updateServings={updateServings}
              loading={state.matches("planning.viewingDayMenu.updatingMealPlanDayServings.updating")}
              canModify={canModifyMealPlan}
            />
            {isPremium && (
              <SimpleMenuItem
                key="shoppingList"
                onClick={handleAddDayRecipesToShoppingList}
                label={tt.mealPlanMenu.addToShoppingListButtonLabel}
                icon={<PlusIcon />}
              />
            )}
            <SimpleMenuItem
              key="nutrition"
              onClick={handleNutrition}
              label={tt.mealPlanMenu.nutritionalInfoButtonLabel}
              icon={<InfoIcon />}
            />
          </List>
        )}
      </div>
    </div>
  );
}

interface DayMenuContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  weekDay: DayKey;
  className?: string;
}

function Header({ state, send, weekDay }: DayMenuProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const dayTitle = upperFirst(tt.dates.weekdayLong(weekDay));

  return (
    <DialogHeader state={state} send={send} backButton={false}>
      <div className="lg:mb-2 flex flex-col items-center px-10">
        <h2 className="font-medium text-center m-0">{dayTitle}</h2>
        <div className="bg-light-grey" style={{ height: "1px" }}></div>
        <h3 className="font-medium w-max px-4 mx-auto text-sm m-0">{tt.dayMenu.addRecipeLabel}</h3>
      </div>
    </DialogHeader>
  );
}
