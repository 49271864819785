import { gql } from "@apollo/client";
import { sha256 } from "js-sha256";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "../../../../../components/Link/Link";
import { useDirectus } from "../../../../../hooks/useDirectus/useDirectus";
import { useUser } from "../../../../../hooks/useUser/useUser";
import { sendEvent } from "../../../../../utils/gtm";
import { MealPlanPage } from "../../../pages";
import { Button } from "../buttons";
import { MealPlannerTranslationsContext } from "./translations";

type INextMealPlanQueryResult = {
  programStep: {
    nextMealPlanUUID: string;
    currentMealPlanUUID: string;
    program: { slug: string; id: string; title: string };
  }[];
};

export function NextMealPlanStep({ sourceSlug }: { sourceSlug?: string | null }) {
  const { id: userId, isPremium } = useUser();
  const tt = useContext(MealPlannerTranslationsContext);
  const { client } = useDirectus();
  const [nextMealPlanUUID, setNextMealPlanUUID] = useState<string>();

  useEffect(() => {
    if (typeof sourceSlug === "string") {
      client
        .query<INextMealPlanQueryResult>({
          variables: {
            slug: sourceSlug,
          },
          query: gql`
            query GetNextMealPlan($slug: String) {
              programStep(filter: { currentMealPlanUUID: { _eq: $slug } }) {
                currentMealPlanUUID
                nextMealPlanUUID
                program {
                  id
                  title
                  slug
                }
              }
            }
          `,
        })
        .then((res) => {
          const result: INextMealPlanQueryResult = res.data;
          const program = result?.programStep?.[0];
          const nextMealPlanUUID = program?.nextMealPlanUUID;
          setNextMealPlanUUID(nextMealPlanUUID);
        })
        .catch((err) => console.log(err));
    }
  }, [sourceSlug]);

  const onClickHandler = useCallback(() => {
    sendEvent({
      eventName: "meal_planner",
      action: "next_meal_plan",
      payload: {
        userId: userId ? sha256(userId) : "",
        isPremium,
      },
    });
  }, [userId, isPremium]);

  if (typeof nextMealPlanUUID === "string") {
    return (
      <div className="my-12 flex justify-center">
        <Link onClick={onClickHandler} to={MealPlanPage} params={{ slug: nextMealPlanUUID }} query={{}}>
          <Button kind="primary" disabled={false} style={{ paddingLeft: "42px", paddingRight: "42px" }}>
            {tt.nextMealPlan}
          </Button>
        </Link>
      </div>
    );
  }

  return null;
}
