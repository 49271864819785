import React from "react";

export function SwapIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M6.99659 11.0403L3.00659 15.0403L6.99659 19.0403V16.0403H14.0066V14.0403H6.99659V11.0403ZM21.0066 9.04028L17.0166 5.04028V8.04028H10.0066V10.0403H17.0166V13.0403L21.0066 9.04028Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
