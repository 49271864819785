import React from "react";
import { default as colors } from "../../../../utils/colors.json";

export const PlusIcon = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3979 9.99438H9.89795V14.4944H8.39795V9.99438H3.89795V8.49438H8.39795V3.99438H9.89795V8.49438H14.3979V9.99438Z"
        fill={disabled ? colors.GREY : "#404040"}
      />
    </svg>
  );
};
