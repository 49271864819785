import React from "react";
import { IconProps } from "./types";

const LoadingIconComponent = React.forwardRef((props: IconProps, ref: React.Ref<SVGSVGElement>) => (
  <svg width="107" height="107" viewBox="0 0 107 107" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M78.2686 28.5689C71.8644 22.1647 63.0752 18.1897 53.3144 18.1897C33.7927 18.1897 18.0252 34.0014 18.0252 53.523C18.0252 73.0447 33.7927 88.8564 53.3144 88.8564C69.7885 88.8564 83.5244 77.5939 87.4552 62.3564H78.2686C74.6469 72.6472 64.8419 80.023 53.3144 80.023C38.6952 80.023 26.8144 68.1422 26.8144 53.523C26.8144 38.9039 38.6952 27.023 53.3144 27.023C60.646 27.023 67.1827 30.0705 71.9527 34.8847L57.731 49.1064H88.6477V18.1897L78.2686 28.5689Z" />
  </svg>
));
LoadingIconComponent.displayName = "LoadingIconComponent";

export const LoadingIcon = React.memo(LoadingIconComponent);
