import React from "react";
import { t } from "@lingui/macro";
import styled from "@emotion/styled";
import { FaRegClock } from "@react-icons/all-files/fa/FaRegClock";
import { bindHover, bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import Popover from "material-ui-popup-state/HoverPopover";
import { default as colors } from "../../utils/colors.json";
import { RecipeDetails } from "../../types/MealPlan";
import { recipeTimeHasTotal } from "../../utils/recipe";

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 15px;
  line-height: 26px;
  color: ${colors.GREY};
`;

const ClockIcon = styled(FaRegClock)`
  width: 13px;
  height: 13px;
`;

const Duration = styled.span`
  margin-left: 5px;
  line-height: 22px;
`;

const Difficulty = styled.span`
  margin-left: 20px;
  line-height: 22px;

  &:before {
    content: "|";
    left: -10px;
    position: relative;
  }
`;

const TimeAndDifficultyPopover = styled(Popover)`
  .MuiPopover-paper {
    padding: 10px;
  }
`;

function useTranslations() {
  return {
    singleDurationLabel: (duration: number) =>
      t({
        id: "TimeAndDifficultyLabel.singleDurationLabel",
        message: `${duration} m`,
        comment: "Duration in minutes",
      }),

    doubleDurationLabel: (duration1: number, duration2: number) =>
      t({
        id: "TimeAndDifficultyLabel.doubleDurationLabel",
        message: `${duration1} + ${duration2} m`,
        comment: "Durations in minutes",
      }),

    // FIXME: the difficulty label isn't an enumeration. So this function can drift away from
    // reality.
    difficultyLabel: (difficulty: string) => {
      switch (difficulty) {
        case "beginner":
          return t({
            id: "TimeAndDifficultyLabel.beginnerDifficultyLabel",
            message: "beginner",
          });
        case "easy":
          return t({
            id: "TimeAndDifficultyLabel.easyDifficultyLabel",
            message: "easy",
          });
        case "medium":
          return t({
            id: "TimeAndDifficultyLabel.mediumDifficultyLabel",
            message: "medium",
          });
        case "hard":
          return t({
            id: "TimeAndDifficultyLabel.hardDifficultyLabel",
            message: "hard",
          });
      }
    },

    activeTimeMessage: (activeTime: number) =>
      t({
        id: "TimeAndDifficultyLabel.activeTimeMessage",
        message: `${activeTime} minutes active time`,
      }),

    totalTimeMessage: (totalTime: number) =>
      t({
        id: "TimeAndDifficultyLabel.totalTimeMessage",
        message: `${totalTime} minutes total time`,
      }),

    prepTimeMessage: (prepTime: number) =>
      t({
        id: "TimeAndDifficultyLabel.prepTimeMessage",
        message: `${prepTime} minutes preparation`,
      }),

    cookTimeMessage: (cookTime: number) =>
      t({
        id: "TimeAndDifficultyLabel.cookTimeMessage",
        message: `${cookTime} minutes cooking time`,
      }),
  };
}

const TimeAndDifficultyLabel = ({ recipe, className }: { recipe: RecipeDetails; className?: string }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `time-difficulty-${recipe.id}`,
  });
  const { preparation: prepTime, cook: cookTime, active, total } = recipe.time;
  const tt = useTranslations();

  const hasTotal = recipeTimeHasTotal();

  const duration = hasTotal
    ? tt.singleDurationLabel(total)
    : prepTime > 0 && cookTime > 0
    ? tt.doubleDurationLabel(prepTime, cookTime)
    : tt.singleDurationLabel(prepTime + cookTime);

  return (
    <>
      <Wrapper className={className} {...bindHover(popupState)}>
        <ClockIcon />
        <Duration>{duration}</Duration>
        <Difficulty>{tt.difficultyLabel(recipe.difficulty.rating)}</Difficulty>
      </Wrapper>
      <TimeAndDifficultyPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus={true}
      >
        {hasTotal ? (
          <>
            <p>{tt.activeTimeMessage(active)}</p>
            <p>{tt.totalTimeMessage(total)}</p>
          </>
        ) : (
          <>
            {prepTime > 0 && <p>{tt.prepTimeMessage(prepTime)}</p>}
            {cookTime > 0 && <p>{tt.cookTimeMessage(cookTime)}</p>}
          </>
        )}
      </TimeAndDifficultyPopover>
    </>
  );
};

export default TimeAndDifficultyLabel;
