import { t } from "@lingui/macro";
import React, { useContext } from "react";
import styled from "@emotion/styled";
import { RecipeDetails } from "../../../types/MealPlan";
import { default as colors } from "../../../utils/colors.json";
import Authors from "../../Authors/Authors";
import { i18nContext } from "../../I18nProvider/I18nProvider";
import TestKitchenLabel from "../TestKitchenLabel/TestKitchenLabel";

const Container = styled.div`
  margin: 30px 0 0;
  & > time {
    color: ${colors.GREY};
  }

  @media print {
    display: none;
  }
`;
const Time = styled.time`
  display: block;
`;

function useTranslations() {
  const { translations } = useContext(i18nContext);

  return {
    recipePublicationDateLabel: (date: string) => {
      const fmtDate = translations.dateLong(date);
      return t({
        id: "RecipeFooter.recipePublicationDateLabel",
        message: `- Posted ${fmtDate}`,
      });
    },
  };
}

const RecipeFooter = ({ recipe }: { recipe: RecipeDetails }) => {
  const tt = useTranslations();

  return (
    <Container>
      <Authors authors={recipe.authors} />
      <Time itemProp="dateModified" dateTime="">
        {tt.recipePublicationDateLabel(recipe.modifiedAt)}
      </Time>
      {recipe.testKitchen.isTested && <TestKitchenLabel id={recipe.id} />}
    </Container>
  );
};

export default RecipeFooter;
