import React from "react";
import { IconProps } from "./types";

export const MoreIcon = React.memo(MoreIconComponent);

function MoreIconComponent({ className }: IconProps) {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.86011 10.7919C5.76011 10.7919 4.86011 11.6919 4.86011 12.7919C4.86011 13.8919 5.76011 14.7919 6.86011 14.7919C7.96011 14.7919 8.86011 13.8919 8.86011 12.7919C8.86011 11.6919 7.96011 10.7919 6.86011 10.7919ZM18.8601 10.7919C17.7601 10.7919 16.8601 11.6919 16.8601 12.7919C16.8601 13.8919 17.7601 14.7919 18.8601 14.7919C19.9601 14.7919 20.8601 13.8919 20.8601 12.7919C20.8601 11.6919 19.9601 10.7919 18.8601 10.7919ZM12.8601 10.7919C11.7601 10.7919 10.8601 11.6919 10.8601 12.7919C10.8601 13.8919 11.7601 14.7919 12.8601 14.7919C13.9601 14.7919 14.8601 13.8919 14.8601 12.7919C14.8601 11.6919 13.9601 10.7919 12.8601 10.7919Z" />
    </svg>
  );
}
