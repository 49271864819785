import React from "react";
import { useLink } from "../../../../../components/Link/Link";
import { RecipePage } from "../../../../../pages";
import { MealPlanPage } from "../../../pages";
import { CopyIcon } from "../../icons/CopyIcon";
import { ShareIcon } from "../../icons/ShareIcon";
import { SuccessIcon } from "../../icons/SuccessIcon";
import {
  MealPlanMachineSend,
  MealPlanMachineState,
  MealPlannerConfirmationMessage,
  MealPlannerErrorCode,
  Message,
} from "../../types";
import { getRecipeByIndex } from "../../utils";
import { Alert } from "../Alert";
import { Button, IconButton } from "../buttons";
import { Dialog } from "./Dialog/Dialog";
import { MealPlannerTranslations, MealPlannerTranslationsContext } from "./translations";

export const ConfirmationDialog = React.memo(ConfirmationDialogComponent);

function ConfirmationDialogComponent({ state, send }: ConfirmationDialogProps) {
  const isOpen = state.matches("planning.confirmingMessage");
  const confirmMessage = () => send({ type: "CONFIRM_MESSAGE" });

  return (
    <Dialog open={isOpen} onClose={confirmMessage}>
      <ConfirmationDialogContent state={state} send={send} />
    </Dialog>
  );
}

interface ConfirmationDialogProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function ConfirmationDialogContent({ state, send }: ConfirmationDialogContentProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const messageId = state.context.confirmationMessage;
  const { title, description } = useConfirmationMessage(tt, messageId);

  const content =
    messageId === MealPlannerConfirmationMessage.MEAL_PLAN_SHARED ? (
      <MealPlanSharedContent state={state} send={send} />
    ) : messageId === MealPlannerConfirmationMessage.RECIPE_SHARED ? (
      <RecipeSharedContent state={state} send={send} />
    ) : (
      <p className="m-0">{description}</p>
    );

  const confirmMessage = () => send({ type: "CONFIRM_MESSAGE" });

  return (
    <div className="z-10 max-w-3/4 sm:max-w-sm rounded-xl shadow-lg bg-sand overflow-hidden">
      <div className="p-8 flex flex-col gap-y-4 justify-center items-center">
        <SuccessIcon className="h-16 w-16 text-blue fill-current opacity-40" />
        <h1 className="m-0 text-lg text-center">{title}</h1>
        {content}
      </div>
      <Button kind="base" onClick={confirmMessage} className="w-full py-4 bg-white text-light-blue font-medium">
        {tt.confirmationDialog.confirmMessageButtonLabel}
      </Button>
    </div>
  );
}

interface ConfirmationDialogContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function useConfirmationMessage(
  tt: MealPlannerTranslations,
  messageId?: MealPlannerConfirmationMessage
): Message {
  const message = messageId && tt.confirmationDialog.messages[messageId];

  return message ?? tt.confirmationDialog.defaultMessage;
}

function MealPlanSharedContent({ state, send }: ConfirmationDialogContentProps) {
  const { title, slug } = state.context.mealPlan!;

  const link = useLink();
  const url = link({
    to: MealPlanPage,
    params: { slug },
    full: true,
  });

  return (
    <SharedContent
      state={state}
      send={send}
      title={title}
      url={url}
      getErrorMessage={getShareMealPlanErrorMessage}
    />
  );
}

function RecipeSharedContent({ state, send }: ConfirmationDialogContentProps) {
  const { title, slug } = getRecipeByIndex(state.context)!.recipe!;

  const link = useLink();
  const url = link({
    to: RecipePage,
    params: { slug },
    full: true,
  });

  return (
    <SharedContent
      state={state}
      send={send}
      title={title}
      url={url}
      getErrorMessage={getShareRecipeErrorMessage}
    />
  );
}

function SharedContent({ state, send, title, url, getErrorMessage }: SharedContentProps) {
  const { canShare, canCopyToClipboard, error } = state.context;
  const errorMessage = getErrorMessage(error?.code);

  const showShareButton = canShare;
  const showCopyToClipboardButton = !canShare && canCopyToClipboard;
  const share = () => send({ type: "SHARE", title, url });
  const copyToClipboard = () => send({ type: "COPY_TO_CLIPBOARD", value: url });
  return (
    <div>
      <div className="flex items-center gap-x-2">
        <input type="text" value={url} disabled aria-disabled className="rounded-3xl bg-white px-4 py-2" />
        {showShareButton && (
          <IconButton kind="default" spacing="large" onClick={share}>
            <ShareIcon />
          </IconButton>
        )}
        {showCopyToClipboardButton && (
          <IconButton kind="default" spacing="large" onClick={copyToClipboard}>
            <CopyIcon />
          </IconButton>
        )}
      </div>
      {errorMessage && (
        <Alert severity="info" className="mt-4">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}

interface SharedContentProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  title: string;
  url: string;
  getErrorMessage: (errorCode?: MealPlannerErrorCode) => string | undefined;
}

function getShareMealPlanErrorMessage(errorCode?: MealPlannerErrorCode) {
  if (!errorCode) {
    return undefined;
  }

  switch (errorCode) {
    case MealPlannerErrorCode.CAN_NOT_SHARE:
      return `Could not share meal plan.`;
    case MealPlannerErrorCode.CAN_NOT_COPY_TO_CLIPBOARD:
      return `Could not copy meal plan URL to the clipboard.`;
    default:
      return `Something went wrong.`;
  }
}

function getShareRecipeErrorMessage(errorCode?: MealPlannerErrorCode) {
  if (!errorCode) {
    return undefined;
  }

  switch (errorCode) {
    case MealPlannerErrorCode.CAN_NOT_SHARE:
      return `Could not share recipe.`;
    case MealPlannerErrorCode.CAN_NOT_COPY_TO_CLIPBOARD:
      return `Could not copy recipe URL to the clipboard.`;
    default:
      return `Something went wrong.`;
  }
}
