import React from "react";

export function CopyToIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M16.9609 1.90112H4.96094C3.86094 1.90112 2.96094 2.80112 2.96094 3.90112V17.9011H4.96094V3.90112H16.9609V1.90112ZM19.9609 5.90112H8.96094C7.86094 5.90112 6.96094 6.80112 6.96094 7.90112V21.9011C6.96094 23.0011 7.86094 23.9011 8.96094 23.9011H19.9609C21.0609 23.9011 21.9609 23.0011 21.9609 21.9011V7.90112C21.9609 6.80112 21.0609 5.90112 19.9609 5.90112ZM19.9609 21.9011H8.96094V7.90112H19.9609V21.9011Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
