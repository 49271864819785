import { StateValue } from "xstate";
import { ErrorScreen } from "./ErrorScreen";
import { InitializingScreen } from "./InitializingScreen";
import { PlanningScreen } from "./PlanningScreen";

export function getScreen(state: StateValue) {
  // Grab the upmost state name. If the state is `{initializing: {innerA: 'foo', innerB: 'bar'}}`,
  // then `initializing` is returned.
  const stateName = typeof state === "string" ? state : Object.keys(state)[0];

  switch (stateName) {
    case "initializing":
      return InitializingScreen;
    case "planning":
      return PlanningScreen;
    case "error":
      return ErrorScreen;
    default:
      throw new Error(`Unexpected state: "${stateName}"`);
  }
}
