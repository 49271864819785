import { MealType } from "../../../types/graphql-global-types";
import { DayKey } from "../../../types/MealPlan";
import { MealPlanner_MealPlan } from "../../../types/MealPlanner_MealPlan";
import { MealPlanner_MealPlanDay } from "../../../types/MealPlanner_MealPlanDay";
import {
  MealPlanner_MergedIngredient,
  MealPlanner_MergedIngredient_values,
} from "../../../types/MealPlanner_MergedIngredient";
import { MealPlanner_MergedIngredient_IngredientValue } from "../../../types/MealPlanner_MergedIngredient_IngredientValue";
import { MealPlanner_NutritionalInfo } from "../../../types/MealPlanner_NutritionalInfo";
import { MealPlanner_RecipeWithServings } from "../../../types/MealPlanner_RecipeWithServings";
import { ALL_WEEKDAYS, weekdays } from "../../../utils/date";
import { getMealFieldName } from "../../../utils/mealplan";
import { MEALS } from "../../../utils/recipe";
import {
  AddRecipesToShoppingListEvent,
  Context,
  EnergyLevel,
  FirebaseShoppingListRecipe,
  FormattedMealPlan,
  IngredientsList,
  IngredientsListIngredient,
  IngredientsListIngredientValue,
  IngredientsListSection,
  NutritionalInfo,
  RecipeOccurrence,
} from "./types";
import { v4 as uuidv4 } from "uuid";

/**
 * Indicates if the given meal plan can be modified by the given user.
 *
 * @param mealPlan The meal plan in question.
 * @param userId The current user's ID or `undefined` if no user is logged in.
 */
export function isMealPlanModifiable(mealPlan: MealPlanner_MealPlan, userId?: string) {
  return mealPlan.type === "user" && typeof userId === "string" && mealPlan.owner === userId;
}

/**
 * Indicates if the given meal plan can be copied.
 *
 * @param mealPlan The meal plan in question.
 * @param hasPremiumMembership Flag indicating if the user has a premium membership.
 */
export function isMealPlanCopiable(mealPlan: MealPlanner_MealPlan, hasPremiumMembership: boolean): boolean {
  return mealPlan.type === "standard" && hasPremiumMembership;
}

export function getEnergyLevel(isHighProtein: boolean, strictness: string): EnergyLevel {
  if (isHighProtein) {
    switch (strictness) {
      case "strict":
      case "keto":
        return EnergyLevel.HIGH_PROTEIN_KETO;
      case "moderate":
        return EnergyLevel.HIGH_PROTEIN_LOW_CARB;
      default:
        return EnergyLevel.HIGH_PROTEIN_LIBERAL;
    }
  } else {
    switch (strictness) {
      case "strict":
      case "keto":
        return EnergyLevel.KETO;
      case "moderate":
        return EnergyLevel.LOW_CARB;
      case "liberal":
      default:
        return EnergyLevel.LIBERAL;
    }
  }
}

/**
 * Parses the API's nutritional information into a data structure that's easier to consume.
 */
export function parseNutritionalInfo(info: MealPlanner_NutritionalInfo): NutritionalInfo {
  const { values, percentages } = info;

  return {
    netCarbs: {
      amount: values.netCarbs,
      percentage: percentages.netCarbs,
    },
    fat: {
      amount: values.fat,
      percentage: percentages.fat,
    },
    protein: {
      amount: values.protein,
      percentage: percentages.protein,
    },

    fiber: values.fiber ?? undefined,
    totalCarbs: values.totalCarbs,
    calories: values.calories,
  };
}

/**
 * Parses the API's merged ingredients list into a data structure that's easier to consume.
 */
export function parseIngredientsList(ingredientsList: MealPlanner_MergedIngredient[]): IngredientsList {
  // Parses individual ingredients and returns a tuple [sectionName, IngredientListIngredient].
  const parsedIngredients = ingredientsList.map(
    (ingredient) =>
      [ingredient.ingredient.shoppingSection, parseIngredientsListIngredient(ingredient)] as [
        string,
        IngredientsListIngredient
      ]
  );

  // Reduces all ingredients, combining them into sections. The result is a mapping of
  // sectionName -> ShoppingListSection.
  const sectionsMap = parsedIngredients.reduce((sections, [sectionName, ingredient]) => {
    const section = sections[sectionName] ?? { name: sectionName, ingredients: [] };
    section.ingredients.push(ingredient);
    sections[sectionName] = section;
    return sections;
  }, {} as IngredientsListSectionsMap);

  // Gets a list of `ShoppingListSection`s. The type guard is only necessary to make TypeScript
  // stop complaining about possible undefined values, but at this stage all values are defined.
  const sections = Object.values(sectionsMap).filter((maybeSection): maybeSection is IngredientsListSection =>
    Boolean(maybeSection)
  );

  return {
    sections,
  };
}

interface IngredientsListSectionsMap {
  [section: string]: IngredientsListSection | undefined;
}

function parseIngredientsListIngredient(
  rawIngredient: MealPlanner_MergedIngredient
): IngredientsListIngredient {
  const { ingredient } = rawIngredient;

  const values = parseIngredientsListIngredientValues(rawIngredient.values);

  return {
    id: ingredient.id,
    name: ingredient.titles.shoppingList,
    values,
  };
}

function parseIngredientsListIngredientValues({
  metric,
  us,
  sv,
}: MealPlanner_MergedIngredient_values): IngredientsListIngredient["values"] {
  return {
    metric: metric ? parseIngredientsListIngredientValueForMeasurementSystem(metric) : undefined,
    us: us ? parseIngredientsListIngredientValueForMeasurementSystem(us) : undefined,
    sv: sv ? parseIngredientsListIngredientValueForMeasurementSystem(sv) : undefined,
  };
}

function parseIngredientsListIngredientValueForMeasurementSystem({
  unit,
  value,
}: MealPlanner_MergedIngredient_IngredientValue): IngredientsListIngredientValue {
  return {
    amount: value,
    unit,
  };
}

export function getMealPlanDay(
  { formattedMealPlan, currentDay }: Pick<Context, "formattedMealPlan" | "currentDay">,
  dayKey?: DayKey
): MealPlanner_MealPlanDay | undefined {
  const targetDay = dayKey ?? currentDay;
  return formattedMealPlan?.find(({ weekday }) => weekday === targetDay)?.mealplanDay;
}

export function getRecipeByIndex(context: Context): MealPlanner_RecipeWithServings | undefined {
  const { currentMeal, currentRecipeIndex } = context;

  const day = getMealPlanDay(context);

  const mealKey =
    currentMeal === MealType.BREAKFAST
      ? "breakfast"
      : currentMeal === MealType.LUNCH
      ? "lunch"
      : currentMeal === MealType.DINNER
      ? "dinner"
      : undefined;

  if (!mealKey || !day || typeof currentRecipeIndex === "undefined") {
    return undefined;
  }

  return day[mealKey].recipeWithServings[currentRecipeIndex] ?? undefined;
}

export function groupSameRecipesTogether(schedule?: FormattedMealPlan, isReadOnly = false): RecipeOccurrence[] {
  const recipes: RecipeOccurrence[] = [];

  if (!schedule) {
    return recipes;
  }

  ALL_WEEKDAYS.forEach((weekday) => {
    const day = schedule.find((day) => day.weekday === weekday.key);
    if (!day) {
      return;
    }

    MEALS.forEach((mealKey) => {
      const mealFieldName = getMealFieldName(mealKey);
      const meal = day.mealplanDay[mealFieldName];

      // Read only meal plans doesn't take `active` (skipped) field into account.
      if (isReadOnly || meal.active) {
        const mealRecipes = meal.recipeWithServings.filter(
          (r: MealPlanner_RecipeWithServings | null): r is MealPlanner_RecipeWithServings => Boolean(r)
        );

        mealRecipes.forEach(({ recipe, servings }) => {
          const existingRecipe = recipes.find(
            (existingRecipe) => existingRecipe.recipe.id === recipe!.id && existingRecipe.servings === servings
          );

          if (existingRecipe) {
            existingRecipe.meals.push({
              weekday,
              meal: mealKey,
            });
          } else {
            recipes.push({
              recipe: recipe!,
              servings,
              meals: [
                {
                  weekday,
                  meal: mealKey,
                },
              ],
            });
          }
        });
      }
    });
  });

  return recipes;
}

export function getServingSizeForRecipeSlot(context: Context): number {
  const currentRecipe = getRecipeByIndex(context);
  if (currentRecipe) {
    return currentRecipe.servings;
  }

  const currentDay = getMealPlanDay(context)!;
  if (currentDay.servings !== 0) {
    return currentDay.servings;
  }
  return context.mealPlan!.servings;
}

export function isMealPlanServingsUniform(context: Context): boolean {
  const mealPlanServings = context.mealPlan?.servings ?? 1;
  const schedule = context.formattedMealPlan ?? [];

  for (const { mealplanDay: day } of schedule) {
    const isDayUniform = isMealPlanDayServingsUniform(mealPlanServings, day);
    if (!isDayUniform) {
      return false;
    }
  }

  return true;
}

export function isMealPlanDayServingsUniform(mealPlanServings: number, day: MealPlanner_MealPlanDay): boolean {
  if (day.servings !== mealPlanServings) {
    return false;
  }

  for (const recipe of day.breakfast.recipeWithServings) {
    if (!recipe) {
      continue;
    }
    if (recipe.servings !== mealPlanServings) {
      return false;
    }
  }
  for (const recipe of day.lunch.recipeWithServings) {
    if (!recipe) {
      continue;
    }
    if (recipe.servings !== mealPlanServings) {
      return false;
    }
  }
  for (const recipe of day.dinner.recipeWithServings) {
    if (!recipe) {
      continue;
    }
    if (recipe.servings !== mealPlanServings) {
      return false;
    }
  }

  return true;
}

export function formatShoppingListDataForMealPlan(ctx: Context, e: AddRecipesToShoppingListEvent) {
  const toRecipeObject = (id: string, servings = 1, uuid = uuidv4()): FirebaseShoppingListRecipe => ({
    id,
    uuid,
    type: "recipe",
    servings,
  });
  const isRecipeWithServings = (
    recipeWithServings: MealPlanner_RecipeWithServings | null
  ): recipeWithServings is MealPlanner_RecipeWithServings => Boolean(recipeWithServings?.recipe);

  // all recipes in a Meal Plan
  const getRecipes = (days: DayKey[] | undefined) =>
    days
      ?.map((day) => getMealPlanDay(ctx, day)!)
      .flatMap((schedule) => [schedule.breakfast, schedule.dinner, schedule.lunch])
      .flatMap((meal) => meal.recipeWithServings)
      .filter(isRecipeWithServings)
      .map(({ recipe, servings }) => toRecipeObject(recipe!.id, servings));

  const isValidWeekday = (day: DayKey) => weekdays.includes(day);
  const filterInvalidWeekdays = (days: DayKey[]): DayKey[] => days.filter(isValidWeekday);

  // the event could contain a single recipe or an array with one or more meal plan days
  const recipes = Array.isArray(e.recipeOrMealPlanDays)
    ? getRecipes(filterInvalidWeekdays(e.recipeOrMealPlanDays))
    : [toRecipeObject(e.recipeOrMealPlanDays.recipe!.id, e.recipeOrMealPlanDays.servings)];
  return recipes;
}
