import React from "react";
import { IconProps } from "./types";

export function InfoIcon({ className }: IconProps) {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6675 7.29333H13.6675V9.29333H11.6675V7.29333ZM11.6675 11.2933H13.6675V17.2933H11.6675V11.2933ZM12.6675 2.29333C7.14748 2.29333 2.66748 6.77333 2.66748 12.2933C2.66748 17.8133 7.14748 22.2933 12.6675 22.2933C18.1875 22.2933 22.6675 17.8133 22.6675 12.2933C22.6675 6.77333 18.1875 2.29333 12.6675 2.29333ZM12.6675 20.2933C8.25748 20.2933 4.66748 16.7033 4.66748 12.2933C4.66748 7.88334 8.25748 4.29333 12.6675 4.29333C17.0775 4.29333 20.6675 7.88334 20.6675 12.2933C20.6675 16.7033 17.0775 20.2933 12.6675 20.2933Z" />
    </svg>
  );
}
