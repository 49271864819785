import classNames from "classnames";
import React from "react";
import { AspectRatio, getImageSrcSet } from "../../../../../../utils/images";
import { ScreenSize } from "../../../../../../utils/screen-sizes";
import { FormattedMealPlan, MealPlanMachineSend, MealPlanMachineState } from "../../../types";
import { getEnergyLevel } from "../../../utils";
import { MealPlanMenu } from "../MealPlanMenu";
import { MealPlannerTranslationsContext } from "../translations";
import { Toolbar } from "./Toolbar";

export const MealPlanHeader = React.memo(MealPlanHeaderComponent);

function MealPlanHeaderComponent({ state, send }: MealPlanHeaderProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const mealPlan = state.context.mealPlan!;
  const title = mealPlan.title || tt.defaultMealPlanTitle;
  const isStarted = state.context.mealPlan?.isActive ?? false;
  const energyLevelLabel = tt.energyLevel[getEnergyLevel(mealPlan.isHighProtein, mealPlan.strictness.rating)];

  return (
    <section
      className={`grid lg:grid-cols-2 grid-cols-1 lg:reverse bg-sand mb-10 lg:mb-12 lg:rounded-xl lg:mt-6`}
    >
      <HeroImage state={state} alt={tt.heroImageDescription} />
      <div className="relative flex flex-col w-full p-8 mx-auto lg:col-start-1 lg:row-start-1 lg:mt-8">
        <h2 className="text-center lg:text-left text-xs font-medium uppercase m-0 mb-1">
          {isStarted ? tt.currentMealPlanTitle : energyLevelLabel}
        </h2>

        <h1 className="text-center lg:text-left text-2xl lg:text-3xl font-medium m-0 mb-1">{title}</h1>

        <Toolbar state={state} send={send} />

        <MealPlanMenu state={state} send={send} />
      </div>
    </section>
  );
}

interface MealPlanHeaderProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
  className?: string;
}

function HeroImage({ state, alt }: HeroImageProps) {
  const [isLoaded, setLoaded] = React.useState(false);
  const heroImagePath = getHeroImagePath(state);
  const showPlaceholder = !heroImagePath || !isLoaded;

  const mobilePlaceholderClassName = classNames("sm:hidden bg-sand w-full", {
    hidden: !showPlaceholder,
  });
  const nonMobilePlaceholderClassName = classNames("hidden bg-sand w-full", {
    "sm:block": showPlaceholder,
  });

  const placeholder = (
    <>
      <div className={mobilePlaceholderClassName} style={mobilePlaceholderStyle} />
      <div className={nonMobilePlaceholderClassName} style={nonMobilePlaceholderStyle} />
    </>
  );

  if (!heroImagePath) {
    return placeholder;
  }

  const nonMobileSrcSet = getImageSrcSet(heroImagePath, {
    aspectRatio: nonMobileAR,
  });
  const mobileSrcSet = getImageSrcSet(heroImagePath, {
    aspectRatio: mobileAR,
  });

  const pictureClassName = classNames({
    hidden: showPlaceholder,
  });

  return (
    <div className="h-full">
      {placeholder}
      <picture onLoad={() => setLoaded(true)} className={pictureClassName}>
        <source media={nonMobileMedia} {...nonMobileSrcSet} className="w-full h-full" />
        <img
          {...mobileSrcSet}
          alt={alt}
          className="w-full h-full object-cover lg:rounded-br-xl lg:rounded-tr-xl"
        />
      </picture>
    </div>
  );
}

interface HeroImageProps {
  state: MealPlanMachineState;
  alt?: string;
}

const nonMobileMedia = `(min-width: ${ScreenSize.SM}px)`;
const mobileAR: AspectRatio = { width: 16, height: 9 };
const nonMobileAR: AspectRatio = { width: 120, height: 23 };

const mobilePlaceholderStyle: React.CSSProperties = {
  paddingBottom: `${(100 * mobileAR.height) / mobileAR.width}%`,
};
const nonMobilePlaceholderStyle: React.CSSProperties = {
  paddingBottom: `${(100 * nonMobileAR.height) / nonMobileAR.width}%`,
};

function getHeroImagePath(state: MealPlanMachineState): string | undefined {
  const { mealPlan, formattedMealPlan } = state.context;
  const heroImagePath = mealPlan?.images?.default;

  if (heroImagePath) {
    return heroImagePath;
  }

  if (!mealPlan?.schedule) {
    return undefined;
  }

  return findFirstRecipeImage(formattedMealPlan!);
}

function findFirstRecipeImage(schedule: FormattedMealPlan) {
  for (const daySchedule of schedule) {
    if (!daySchedule) {
      continue;
    }

    const {
      breakfast: { recipeWithServings: breakfastRecipes },
      lunch: { recipeWithServings: lunchRecipes },
      dinner: { recipeWithServings: dinnerRecipes },
    } = daySchedule.mealplanDay;

    if (breakfastRecipes && breakfastRecipes.length > 0 && breakfastRecipes[0]?.recipe) {
      return breakfastRecipes[0].recipe.images.hz;
    }
    if (lunchRecipes && lunchRecipes.length > 0 && lunchRecipes[0]?.recipe) {
      return lunchRecipes[0].recipe.images.hz;
    }
    if (dinnerRecipes && dinnerRecipes.length > 0 && dinnerRecipes[0]?.recipe) {
      return dinnerRecipes[0].recipe.images.hz;
    }
  }
}
