import React from "react";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader, HeaderTitle } from "./Dialog/DialogHeader";
import { IngredientsList } from "./IngredientsList";
import { MealPlannerTranslationsContext } from "./translations";

export const MealPlanIngredientsListDialog = React.memo(MealPlanIngredientsDialogComponent);

function MealPlanIngredientsDialogComponent({ state, send }: MealPlanIngredientsListProps) {
  const isDialogOpen = state.matches("planning.viewingMealPlanIngredientsList");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <MealPlanIngredientsList state={state} send={send} />
    </Dialog>
  );
}

interface MealPlanIngredientsListProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function MealPlanIngredientsList({ state, send }: MealPlanIngredientsListProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);

  return (
    <div className={viewClassName}>
      <DialogHeader state={state} send={send}>
        <HeaderTitle>{tt.mealPlanIngredientsList.dialogTitle}</HeaderTitle>
      </DialogHeader>
      <IngredientsList state={state} send={send} />
    </div>
  );
}

const viewClassName = [
  // Put the view in front of the overlay.
  "z-10",
  // Mobile screens: position the view to take the whole available space.
  "fixed inset-0",
  // Larger screens: the view should have minimum and maximum dimensions.
  "sm:static sm:inset-auto sm:min-w-1/2 sm:max-w-3/4 sm:h-3/4",
  // Larger screens: round the borders and add a drop shadow.
  "sm:rounded-xl sm:shadow-lg",
  // Vertically overflow if needed. This is also needed for the rounded corners.
  "overflow-y-auto",
  // Content will be layered out using flex.
  "flex flex-col",
  // White background.
  "bg-white",
].join(" ");
