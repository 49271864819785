import React from "react";
import { getImageSrcSet } from "../../../../../utils/images";
import { MealPlanner_MealPlan } from "../../../../../types/MealPlanner_MealPlan";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getEnergyLevel } from "../../utils";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader, HeaderTitle } from "./Dialog/DialogHeader";
import { DialogSeparator } from "./Dialog/DialogSeparator";
import { MealPlannerTranslationsContext } from "./translations";

export const MealPlanInfoDialog = React.memo(MealPlanInfoDialogComponent);

function MealPlanInfoDialogComponent({ state, send }: MealPlanInfoProps) {
  const isDialogOpen = state.matches("planning.viewingMealPlanInfo");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <MealPlanInfo state={state} send={send} />
    </Dialog>
  );
}

interface MealPlanInfoProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function MealPlanInfo({ state, send }: MealPlanInfoProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const mealPlan = state.context.mealPlan!;
  const energyLevelLabel = tt.energyLevel[getEnergyLevel(mealPlan.isHighProtein, mealPlan.strictness.rating)];

  return (
    <div className="z-10 fixed sm:static inset-0 sm:inset-auto sm:max-w-3/4 sm:max-h-3/4 sm:rounded-xl sm:shadow-lg bg-white overflow-y-auto">
      <DialogHeader state={state} send={send}>
        <HeaderTitle>{tt.mealPlanInfoDialog.dialogTitle}</HeaderTitle>
      </DialogHeader>
      <div className="p-4 pt-8 px-12">
        <h2 className="text-xs font-medium uppercase m-0 mb-4">{energyLevelLabel}</h2>
        <h1 className="text-3xl font-medium m-0 mb-4">{mealPlan.title}</h1>
        <Description mealPlan={mealPlan} />
        <AuthorList mealPlan={mealPlan} />
      </div>
    </div>
  );
}

function Description({ mealPlan }: DescriptionProps) {
  const { description, descriptionHtml } = mealPlan;

  if (descriptionHtml) {
    return <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />;
  }

  return <div>{description}</div>;
}

interface DescriptionProps {
  mealPlan: MealPlanner_MealPlan;
}

function AuthorList({ mealPlan }: AuthorListProps) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const authors = React.useMemo(
    () =>
      [...mealPlan.authors.main, ...mealPlan.authors.contributors]
        .map(({ fullName, image }) => ({
          name: fullName,
          avatarPath: image?.default,
        }))
        .filter(authorHasAvatar),
    [mealPlan]
  );

  if (authors.length === 0) {
    return null;
  }

  return (
    <>
      <DialogSeparator />
      <h2 className="mb-4 text-xs font-medium uppercase">{tt.mealPlanInfoDialog.createdByLabel}</h2>
      <ul className="list-none flex p-0">
        {authors.map((author) => (
          <AuthorListItem key={author.name} author={author} />
        ))}
      </ul>
    </>
  );
}

interface AuthorListProps {
  mealPlan: MealPlanner_MealPlan;
}

function AuthorListItem({ author }: AuthorListItemProps) {
  const { name, avatarPath } = author;
  // The avatar is `w-24` wide, which is 6rem.
  const avatarSrcSet = getImageSrcSet(avatarPath, { width: "6rem" });

  return (
    <li>
      <article className="flex flex-col items-center gap-y-2">
        <img {...avatarSrcSet} alt={name} className="w-24" />
        <span className="text-center text-xs text-light-blue">{name}</span>
      </article>
    </li>
  );
}

interface AuthorListItemProps {
  author: Author;
}

interface Author {
  name: string;
  avatarPath: string;
}

function authorHasAvatar(author: { name: string; avatarPath?: string }): author is Author {
  return Boolean(author.avatarPath);
}
