import React from "react";
import { MealPlanner_RecipeWithServings_recipe } from "../../../../../types/MealPlanner_RecipeWithServings";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getServingSizeForRecipeSlot } from "../../utils";
import { MealPlannerTranslationsContext } from "../MealPlanner/translations";
import RecipeCard from "./RecipeCard";

export default React.memo(FavoritesTab);

function FavoritesTab({ state, send }: { state: MealPlanMachineState; send: MealPlanMachineSend }) {
  const tt = React.useContext(MealPlannerTranslationsContext);

  const { favoriteRecipes, currentDay, currentMeal, currentRecipeIndex } = state.context;
  const servings = getServingSizeForRecipeSlot(state.context);

  const handleAddRecipe = (recipeId: string) => {
    send({
      type: "ADD_RECIPE",
      day: currentDay!,
      meal: currentMeal!,
      recipeId,
      servings,
      index: currentRecipeIndex,
    });
  };

  if (favoriteRecipes.length === 0) {
    return (
      <div className="flex justify-center mt-8">{tt.recipeSelectorDialog.noFavoriteRecipesFoundMessage}</div>
    );
  }

  return (
    <>
      {favoriteRecipes.map((item: MealPlanner_RecipeWithServings_recipe, index: number) => (
        <RecipeCard
          state={state}
          send={send}
          key={item.id + index}
          item={item}
          handleClickOnRecipe={() => handleAddRecipe(item.id)}
          history="favorites"
        />
      ))}
    </>
  );
}
