import React from "react";

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93352 8.74913C8.54299 9.13966 8.54299 9.77282 8.93352 10.1633L13.8015 15.0314L8.93357 19.8994C8.54304 20.2899 8.54304 20.923 8.93357 21.3136C9.32409 21.7041 9.95726 21.7041 10.3478 21.3136L15.2158 16.4456L20.0837 21.3135C20.4742 21.7041 21.1074 21.7041 21.4979 21.3135C21.8884 20.923 21.8884 20.2898 21.4979 19.8993L16.63 15.0314L21.498 10.1634C21.8885 9.77285 21.8885 9.13968 21.498 8.74916C21.1074 8.35864 20.4743 8.35864 20.0838 8.74916L15.2158 13.6172L10.3477 8.74913C9.95721 8.35861 9.32404 8.35861 8.93352 8.74913Z"
      />
    </svg>
  );
}
