import React from "react";
import { IconProps } from "./types";

export const ShareIcon = React.memo(ShareIconComponent);

function ShareIconComponent(props: IconProps) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.1368 5.50543L14.7168 6.92543L13.1268 5.33543V16.5054H11.1468V5.33543L9.55678 6.92543L8.13678 5.50543L12.1368 1.50543L16.1368 5.50543ZM20.1368 10.5054V21.5054C20.1368 22.6054 19.2368 23.5054 18.1368 23.5054H6.13678C5.02678 23.5054 4.13678 22.6054 4.13678 21.5054V10.5054C4.13678 9.39543 5.02678 8.50543 6.13678 8.50543H9.13678V10.5054H6.13678V21.5054H18.1368V10.5054H15.1368V8.50543H18.1368C19.2368 8.50543 20.1368 9.39543 20.1368 10.5054Z" />
    </svg>
  );
}
