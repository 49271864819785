import React from "react";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { Dialog } from "./Dialog/Dialog";
import { DialogHeader, HeaderTitle } from "./Dialog/DialogHeader";
import { MealPlannerTranslationsContext } from "./translations";
import { upperFirst } from "../../../../../utils/string";
import { getSrc } from "../../../../../utils/image";
import { ClockIcon } from "../../icons/ClockIcon";
import { MealType } from "../../../../../types/graphql-global-types";
import { getServingSizeForRecipeSlot } from "../../utils";
import { MealPlanner_RecipeWithServings } from "../../../../../types/MealPlanner_RecipeWithServings";
import { DayKey } from "../../../../../types/MealPlan";
import CarbCircle from "../../../../../components/CarbCircle/CarbCircle";
import { getRecipeTime } from "../../../../../utils/recipe";

export const CopyRecipeDialog = React.memo(CopyRecipeDialogComponent);

function CopyRecipeDialogComponent({ state, send }: CopyRecipeProps) {
  const isDialogOpen = state.matches("planning.viewingRecipeMenu.copyRecipeTo");
  const closeView = () => send({ type: "CLOSE_VIEW" });

  return (
    <Dialog open={isDialogOpen} onClose={closeView}>
      <CopyRecipeDialogContent state={state} send={send} />
    </Dialog>
  );
}

interface CopyRecipeProps {
  state: MealPlanMachineState;
  send: MealPlanMachineSend;
}

function CopyRecipeDialogContent({ state, send }: CopyRecipeProps) {
  const { copyToDay, copyToMeal, currentRecipe } = state.context;
  const tt = React.useContext(MealPlannerTranslationsContext);
  const formattedDay = state.context.formattedMealPlan!.find(
    (item) => item.weekday === state.context.currentDay
  );
  //@ts-ignore
  const formattedMeal = formattedDay!.mealplanDay![state.context.currentMeal?.toLowerCase()];
  const recipeWithServings: MealPlanner_RecipeWithServings = formattedMeal.recipeWithServings.find(
    (recipe: MealPlanner_RecipeWithServings) => recipe!.recipe!.id === state.context.currentRecipe!.recipe!.id
  );

  const { recipe } = recipeWithServings;
  const title = upperFirst(recipe!.title);
  const image = recipe!.images.hz;
  const cookingTime = tt.quantities.timeInMinutes(getRecipeTime(recipe!.time));

  const getDayButtonStyle = (day: string) => {
    return day === state.context.copyToDay
      ? "border border-green bg-white rounded-3xl w-10 h-10"
      : "bg-bluegrey rounded-3xl w-10 h-10";
  };
  const getMealButtonStyle = (meal: MealType) =>
    meal === state.context.copyToMeal
      ? "border border-green bg-white rounded-3xl mx-1 w-full"
      : "bg-bluegrey rounded-3xl w-full border-bluegrey border mx-1 py-1";

  const days = state.context.formattedMealPlan?.filter((day) => day.weekday !== "monday2");

  const handleDaySelection = (day: DayKey) => send({ type: "SELECT_COPY_TO_DAY", day });
  const handleMealSelection = (meal: MealType) => send({ type: "SELECT_COPY_TO_MEAL", meal });

  const handleSave = () => {
    const servings = getServingSizeForRecipeSlot(state.context);
    send({
      type: "ADD_RECIPE",
      day: copyToDay!,
      meal: copyToMeal!,
      recipeId: currentRecipe!.recipe!.id,
      servings,
    });
  };

  return (
    <div className="z-10 fixed sm:static inset-0 sm:inset-auto sm:max-w-3/4 sm:max-h-3/4 sm:rounded-xl sm:shadow-lg bg-white overflow-y-auto m-auto">
      <DialogHeader state={state} send={send}>
        <HeaderTitle>Copy to</HeaderTitle>
      </DialogHeader>
      <div className="p-4 flex">
        <div className="relative">
          <CarbCircle recipe={recipe!} />
          <picture>
            <img
              style={{ height: "125px", width: "125px" }}
              className="rounded-2xl cover-fit"
              alt=""
              src={getSrc(image, { width: 200, height: 200 })}
            />
          </picture>
        </div>

        <div className="pl-4 my-auto">
          <h3 style={{ fontSize: "18px" }} className="font-medium m-0">
            {title}
          </h3>
          <div className="flex flew-row text-sm items-center">
            <ClockIcon />
            <span className="ml-1">{cookingTime}</span>
          </div>
        </div>
      </div>
      <div className="mx-4">
        <div className="bg-sand pt-0.5" />

        <p className="text-green uppercase font-medium text-center">day and meal</p>
        <div className="flex justify-between">
          {days!.map((item, index) => (
            <button
              key={index}
              className={getDayButtonStyle(item.weekday)}
              onClick={() => handleDaySelection(item.weekday)}
            >
              {upperFirst(tt.dates.weekdayLong(item.weekday))[0]}
            </button>
          ))}
        </div>
        <div className="flex justify-between m-auto my-4">
          <button
            className={getMealButtonStyle(MealType.BREAKFAST)}
            onClick={() => handleMealSelection(MealType.BREAKFAST)}
          >
            {tt.meals.mealLabel(MealType.BREAKFAST, true)}
          </button>
          <button
            className={getMealButtonStyle(MealType.LUNCH)}
            onClick={() => handleMealSelection(MealType.LUNCH)}
          >
            {tt.meals.mealLabel(MealType.LUNCH, true)}
          </button>
          <button
            className={getMealButtonStyle(MealType.DINNER)}
            onClick={() => handleMealSelection(MealType.DINNER)}
          >
            {tt.meals.mealLabel(MealType.DINNER, true)}
          </button>
        </div>
        <div className="bg-sand pt-0.5" />

        <div className="my-4 w-6/12 m-auto">
          <button className="bg-green w-full rounded-3xl text-white my-2 py-2" onClick={handleSave}>
            Save
          </button>
          <button className="w-full rounded-3xl bg-sand my-2 py-2">Cancel</button>
        </div>
      </div>
    </div>
  );
}
