import { t } from "@lingui/macro";
import flatten from "lodash/flatten";
import React from "react";
import styled from "@emotion/styled";
import {
  GetMealplanBySlug_mealplanBySlug_authors,
  GetMealplanBySlug_mealplanBySlug_authors_contributors,
  GetMealplanBySlug_mealplanBySlug_authors_main,
} from "../../types/GetMealplanBySlug";
import { intersperse } from "../../utils/array";
import { getSrc } from "../../utils/image";
import { upperFirst } from "../../utils/string";
import { AuthorPopover, useAuthorPopover } from "./AuthorPopover";

const ThumbnailImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 3px 0 6px;
`;

type MainAuthorOrContributor =
  | GetMealplanBySlug_mealplanBySlug_authors_main
  | GetMealplanBySlug_mealplanBySlug_authors_contributors;

const isContributor = (
  author: MainAuthorOrContributor
): author is GetMealplanBySlug_mealplanBySlug_authors_contributors => {
  return (author as GetMealplanBySlug_mealplanBySlug_authors_contributors).role !== undefined;
};

const Author = ({ author }: { author: MainAuthorOrContributor }) => {
  const { hoverAttrs, popoverAttrs } = useAuthorPopover(author);

  const content = (
    <>
      {author.fullName}
      {author.image?.default && (
        <ThumbnailImage
          data-testid="author-image"
          src={getSrc(author.image.default, { width: 150, height: 150 })}
        />
      )}
    </>
  );
  const authorComponent =
    author.profileUrl.length > 0 ? (
      <a href={author.profileUrl} rel="author">
        {content}
      </a>
    ) : (
      <span>{content}</span>
    );

  return (
    <>
      <span {...hoverAttrs}>{authorComponent}</span>
      <AuthorPopover author={author} {...popoverAttrs} />
    </>
  );
};

const AuthorGroup: React.FunctionComponent<{
  authors: MainAuthorOrContributor[];
  byLabel: string;
  main?: boolean;
  capitalize?: boolean;
}> = ({ authors, byLabel, main, capitalize }) => {
  if (authors.length === 0) {
    return null;
  }

  const components: React.ReactNode[] = flatten(
    intersperse(
      authors.map((author) => {
        const component = <Author key={author.id} author={author} />;
        if (isContributor(author)) {
          return [`${author.role} ${byLabel}`, component];
        } else {
          return component;
        }
      }),
      ", "
    )
  );

  if (main) {
    components.unshift(byLabel);
  }

  if (capitalize || main) {
    components[0] = upperFirst(components[0] as string);
  }

  return <>{components}</>;
};

/**
 * By <main>, photo by <photo>
 * Recipe by <recipe>
 * Recipe by <recipe>, photo by <photo>
 * Recipe & photo by <contributor>
 */
const Authors: React.FunctionComponent<{ authors: GetMealplanBySlug_mealplanBySlug_authors }> = ({
  authors,
}) => {
  const byLabel = t({
    id: "Authors.byLabel",
    message: "by ",
  });

  const hasMainAuthors = authors.main.length > 0;
  const hasContributors = authors.contributors.length > 0;

  return (
    <span data-testid="authors-container">
      <AuthorGroup authors={authors.main} byLabel={byLabel} main={true} />
      {hasMainAuthors && hasContributors && ", "}
      <AuthorGroup authors={authors.contributors} byLabel={byLabel} capitalize={!hasMainAuthors} />
    </span>
  );
};

export default Authors;
