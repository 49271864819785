import React, { useEffect } from "react";
import { MealPlanMachineState } from "../../types";
import { PrintableMealPlanDayList } from "./PrintableMealPlanDayList";
import { PrintableRecipes } from "./PrintableRecipes";

export const PrintableMealPlan = React.memo(PrintableMealPlanComponent);

function PrintableMealPlanComponent({ state }: PrintableMealPlanProps) {
  const containerElement = React.useRef<HTMLDivElement | null>(null);
  const isVisibleForPrinting = typeof state.context.printTarget === "undefined";

  useEffect(() => {
    if (containerElement.current) {
      if (isVisibleForPrinting) {
        containerElement.current.classList.add("print:block");
      } else {
        containerElement.current.classList.remove("print:block");
      }
    }
  }, [isVisibleForPrinting]);

  return (
    <div ref={containerElement} className="hidden">
      <PrintableMealPlanDayList state={state} />
      <PrintableRecipes state={state} />
    </div>
  );
}

interface PrintableMealPlanProps {
  state: MealPlanMachineState;
}
