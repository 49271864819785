import { t } from "@lingui/macro";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { FullRecipe } from "../../../types/FullRecipe";
import { default as colors } from "../../../utils/colors.json";

const Title = styled.h3`
  font-size: 40px;
  font-weight: 600;
  margin: 54px 0 4px;
  line-height: 1.125;
  letter-spacing: -0.7px;

  @media print {
    font-size: 18px;
    line-height: 20px;
  }
`;

const ServingsNote = styled.p`
  margin: 0 0 30px;
  font-size: 18px;
  line-height: 26px;

  @media print {
    font-size: 14px;
    line-height: 20px;
  }
`;

const InstructionsStepList = styled.ol`
  counter-reset: li;
  margin: 0;
  padding: 0;

  @media print {
    font-size: 14px;
    line-height: 20px;
  }
`;

const InstructionsStepItem = styled.li<{ selected: boolean }>`
  position: relative;
  list-style: none;
  margin-top: 27px;
  padding: 0 0 27px 45px;
  color: ${({ selected }) => (selected ? colors.GREY_LIGHT : colors.GREY_DARK)};

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.GREY_LIGHTER};
  }

  &:before {
    content: counter(li);
    counter-increment: li;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: 0;
    top: 0;
    height: 22px;
    width: 22px;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid ${({ selected }) => (selected ? colors.BEIGE : colors.GREEN)};
    padding: 3px;
    background-color: ${({ selected }) => (selected ? "transparent" : colors.GREEN)};
    color: ${({ selected }) => (selected ? colors.BEIGE : colors.WHITE)};

    @media print {
      border-color: ${colors.BLACK};
      color: ${colors.BLACK};
      padding: 0;
    }
  }
`;

function useTranslations() {
  return {
    instructionsHeading: t({
      id: "MealPlanPage.RecipesTab.instrutionsHeading",
      message: "Instructions",
    }),
    servingsNote: (servings: number) =>
      t({
        id: "MealPlanPage.RecipesTab.servingsNote",
        message: `Instructions are for ${servings} servings. Please modify as needed.`,
      }),
  };
}

const Instructions = ({
  recipe,
  servingSize,
  className,
  adjustInstructionTagId,
}: {
  recipe: FullRecipe;
  servingSize: number;
  className?: string;
  adjustInstructionTagId?: string;
}) => {
  const tt = useTranslations();
  const [selectedSteps, setSelectedSteps] = useState<Set<number>>(new Set());
  const toggleInstructionStep = (index: number) => () => {
    if (selectedSteps.has(index)) {
      selectedSteps.delete(index);
    } else {
      selectedSteps.add(index);
    }
    setSelectedSteps(new Set(selectedSteps));
  };

  const showAdjustInstructionDisaclaimer =
    recipe.tags.find((tag) => tag.id === adjustInstructionTagId) && recipe.servings.default !== servingSize;

  return (
    <div className={className}>
      <Title>{tt.instructionsHeading}</Title>

      {showAdjustInstructionDisaclaimer && (
        <ServingsNote>{tt.servingsNote(recipe.servings.default)}</ServingsNote>
      )}
      {recipe.instructionSections.map((instructionSection, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {instructionSection.title && <Title>{instructionSection.title}</Title>}

          <InstructionsStepList>
            {instructionSection.steps.map((step, stepIndex) => (
              <InstructionsStepItem
                key={stepIndex}
                selected={selectedSteps.has(stepIndex)}
                onClick={toggleInstructionStep(stepIndex)}
              >
                {step}
              </InstructionsStepItem>
            ))}
          </InstructionsStepList>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Instructions;
