import React from "react";
import { IconProps } from "./types";

export function IngredientsListIcon({ className }: IconProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <rect width="18" height="18.9" transform="translate(3.20752 2.8656)" fill="transparent" />
        <path
          d="M12.3086 3.54608V7.14608"
          stroke="#404040"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4085 18.5676C20.4085 19.7223 19.4556 20.6461 18.2644 20.6461H6.35262C5.16144 20.6461 4.2085 19.7223 4.2085 18.5676V7.4246C4.2085 6.26988 5.16144 5.3461 6.35262 5.3461H18.2644C19.4556 5.3461 20.4085 6.26988 20.4085 7.4246V18.5676Z"
          stroke="#404040"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.90869 9.8461H17.7087"
          stroke="#404040"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.90869 13.4461H17.7087"
          stroke="#404040"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.90869 17.0461H17.7087"
          stroke="#404040"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18.9" fill="transparent" transform="translate(3.20752 2.8656)" />
        </clipPath>
      </defs>
    </svg>
  );
}
