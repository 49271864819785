import React, { useEffect } from "react";
import { MealPlanMachineState, PrintTarget } from "../../types";
import { IngredientsList } from "./IngredientsList";

export const PrintableIngredientsList = React.memo(PrintableIngredientsListComponent);

function PrintableIngredientsListComponent({ state }: PrintableIngredientsListProps) {
  const containerElement = React.useRef<HTMLDivElement | null>(null);
  const isVisibleForPrinting = state.context.printTarget === PrintTarget.INGREDIENTS_LIST;

  useEffect(() => {
    if (containerElement.current) {
      if (isVisibleForPrinting) {
        containerElement.current.classList.add("print:block");
      } else {
        containerElement.current.classList.remove("print:block");
      }
    }
  }, [isVisibleForPrinting]);

  return (
    <div ref={containerElement} className="hidden">
      <IngredientsList state={state} />
    </div>
  );
}

interface PrintableIngredientsListProps {
  state: MealPlanMachineState;
}
