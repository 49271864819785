import React from "react";

const FRACTION_CHARS: { [key: string]: string | undefined } = {
  "1/4": "\u00BC",
  "1/2": "\u00BD",
  "3/4": "\u00BE",
  "1/7": "\u2150",
  "1/9": "\u2151",
  "1/10": "\u2152",
  "1/3": "\u2153",
  "2/3": "\u2154",
};

const IngredientLabel = ({ label }: { label: string }) => {
  const parts = label.split(/(\d+\/\d+)/).map((labelPart, index) => {
    if (labelPart.match(/^\d+\/\d+$/)) {
      if (FRACTION_CHARS[labelPart]) {
        return <React.Fragment key={index}>{FRACTION_CHARS[labelPart]}</React.Fragment>;
      }
      const fractionParts = labelPart.split("/");
      return (
        <React.Fragment key={index}>
          <sup>{fractionParts[0]}</sup>⁄<sub>{fractionParts[1]}</sub>
        </React.Fragment>
      );
    }
    return <React.Fragment key={index}>{labelPart}</React.Fragment>;
  });
  return <>{parts}</>;
};

export default IngredientLabel;
