import React from "react";

export function PlusIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3979 10.4211H9.89795V14.9211H8.39795V10.4211H3.89795V8.92114H8.39795V4.42114H9.89795V8.92114H14.3979V10.4211Z"
        fill="#404040"
      />
    </svg>
  );
}
