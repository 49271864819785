/**
 * Return an array with the separator interspersed between each element of the input array.
 *
 * > intersperse([1,2,3], 0)
 * [1,0,2,0,3]
 */
export const intersperse = (arr: any[], sep: any) => {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    (acc, item) => {
      return acc.concat([sep, item]);
    },
    [arr[0]]
  );
};

/**
 * Filter predicate that keeps only unique items in an array.
 * > [1, 1, 2, 1, 3].filter(onlyUnique)
 * [1, 2, 3]
 */
export const onlyUnique = <T>(value: T, index: number, array: T[]) => {
  return array.indexOf(value) === index;
};
