import { t } from "@lingui/macro";
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "../../../../../../components/Link/Link";
import { AllMealPlansPage, MyMealPlansPage, NewMemberMealPlanPage } from "../../../../../../pages";
import { ErrorIcon } from "../../../icons/ErrorIcon";
import { MealPlannerErrorCode, ScreenProps } from "../../../types";

export const ErrorScreen = React.memo(ErrorScreenComponent);

function ErrorScreenComponent({ state }: ScreenProps) {
  const ErrorComponent = getErrorComponent(state.context.error?.code);

  return (
    <div className="mx-auto mt-8 pt-8 w-10/12 max-w-sm bg-lighter-blue rounded-lg flex flex-col items-center text-center">
      <ErrorComponent />
    </div>
  );
}

function getErrorComponent(code?: MealPlannerErrorCode) {
  switch (code) {
    case MealPlannerErrorCode.PREMIUM_MEMBERSHIP_REQUIRED:
      return PremiumMembershipRequiredError;
    case MealPlannerErrorCode.MEAL_PLAN_NOT_FOUND:
      return MealPlanNotFoundError;
    default:
      return GenericError;
  }
}

function PremiumMembershipRequiredError() {
  const navigate = useNavigate();
  const tt = useTranslations();

  useEffect(() => {
    navigate({ to: NewMemberMealPlanPage, query: { redirectTo: window.location.href } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-8 pb-8">
      {errorIcon}
      <ErrorTitle>{tt.premiumMembershipRequiredErrorTitle}</ErrorTitle>

      <Link to={NewMemberMealPlanPage} query={{ redirectTo: window.location.href }}>
        <div className="bg-blue text-white py-2 px-4 rounded-3xl max-w-max mx-auto">
          {tt.premiumMembershipRequiredErrorLink}
        </div>
      </Link>
    </div>
  );
}

function MealPlanNotFoundError() {
  const tt = useTranslations();

  return (
    <>
      {errorIcon}
      <ErrorTitle>{tt.mealPlanNotFoundErrorTitle}</ErrorTitle>
      <nav className="flex flex-col w-full pt-8">
        <Link className="border-t border-light-grey h-14 items-center flex justify-center" to={MyMealPlansPage}>
          {tt.mealPlanNotFoundErrorLinkToMyMealPlans}
        </Link>
        <Link className="border-t border-light-grey	h-14 items-center flex justify-center" to={AllMealPlansPage}>
          {tt.mealPlanNotFoundErrorLinkToAllMealPlans}
        </Link>
      </nav>
    </>
  );
}
function GenericError() {
  const tt = useTranslations();

  return (
    <>
      {errorIcon}
      <ErrorTitle>{tt.genericErrorTitle}</ErrorTitle>
      <ErrorMessage>{tt.genericErrorSubtitle}</ErrorMessage>
    </>
  );
}

const errorIcon = <ErrorIcon className="mb-4 h-16 w-16 text-blue fill-current opacity-40" />;

function ErrorTitle({ children }: React.PropsWithChildren<{}>) {
  return <h1 className="m-0 mb-8 text-xl">{children}</h1>;
}

function ErrorMessage({ children }: React.PropsWithChildren<{}>) {
  return <p className="m-0">{children}</p>;
}

function useTranslations() {
  return {
    premiumMembershipRequiredErrorTitle: t({
      id: "PremiumMembershipRequiredError.title",
      message: "You need to be a member to access this meal plan",
    }),
    premiumMembershipRequiredErrorLink: t({
      id: "PremiumMembershipRequiredError.startFreeTrial.link",
      message: "Start free trial",
    }),
    mealPlanNotFoundErrorTitle: t({
      id: "MealPlanNotFoundError.title",
      message: "We can not find your meal plan",
    }),
    mealPlanNotFoundErrorLinkToMyMealPlans: t({
      id: "MealPlanNotFoundError.LinkToMyMealPlans",
      message: "My meal plans",
    }),
    mealPlanNotFoundErrorLinkToAllMealPlans: t({
      id: "MealPlanNotFoundError.LinkToAllMealPlans",
      message: "All meal plans",
    }),
    genericErrorTitle: t({
      id: "GenericError.title",
      message: "Something went wrong",
    }),
    genericErrorSubtitle: t({
      id: "GenericError.subtitle",
      message: "Please try again later",
    }),
  };
}
