import React from "react";

export const Popover = React.memo(PopoverComponent);

function PopoverComponent({ open, onClose, className, children }: PopoverProps) {
  if (!open) {
    return null;
  }

  return (
    <div className={className}>
      <div onClick={onClose} className="fixed inset-0" />
      {children}
    </div>
  );
}

type PopoverProps = React.PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  className?: string;
}>;
