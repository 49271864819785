import React from "react";
import { LoadingIcon } from "../../icons/LoadingIcon";
import { MealPlanMachineState } from "../../types";
import { Dialog } from "./Dialog/Dialog";
import { MealPlannerTranslationsContext } from "./translations";

export const LoadingDialog = React.memo(LoadingDialogComponent);

function LoadingDialogComponent({ state }: LoadingDialogProps) {
  const message = useLoadingMessage(state);
  const isOpen = typeof message === "string";

  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <div className="z-10 m-10 p-10 max-w-sm rounded-xl shadow-lg bg-sand flex flex-col justify-center items-center">
        <LoadingIcon className="animate-spin text-blue fill-current opacity-40 outline-none" />
        {message && <span className="text-center">{message}</span>}
      </div>
    </Dialog>
  );
}

interface LoadingDialogProps {
  state: MealPlanMachineState;
}

function useLoadingMessage(state: MealPlanMachineState) {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const messages = React.useMemo(
    () =>
      Object.entries({
        "planning.startingMealPlan": tt.loadingDialog.startingMealPlanMessage,
        "planning.stoppingMealPlan": tt.loadingDialog.stoppingMealPlanMessage,
        "planning.copyingMealPlan.loading": tt.loadingDialog.copyingMealPlanMessage,
        "planning.editingMealPlan.loading": tt.loadingDialog.editingMealPlanMessage,
        "planning.sharingMealPlan": tt.loadingDialog.sharingMealPlanMessage,
        "planning.unsharingMealPlan": tt.loadingDialog.unsharingMealPlanMessage,
        "planning.addingRecipe": tt.loadingDialog.addingRecipeToMealPlanMessage,
        "planning.removingRecipe": tt.loadingDialog.removingRecipeToMealPlan,
        "planning.deletingMealPlan": tt.loadingDialog.deletingMealPlanMessage,
        "planning.addRecipesToShoppingList": tt.loadingDialog.addingRecipesToShoppingListMessage,
      }),
    [tt]
  );

  const message = React.useMemo(() => {
    for (const [stateName, message] of messages) {
      if (state.matches(stateName)) {
        return message;
      }
    }

    return undefined;
  }, [state, messages]);

  return message;
}
