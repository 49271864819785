import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { MealPlanner_GetRecipeSuggestionQuery_recipeSuggestions } from "../../../../../types/MealPlanner_GetRecipeSuggestionQuery";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { getServingSizeForRecipeSlot } from "../../utils";
import { Button } from "../buttons";
import { MealPlannerTranslationsContext } from "../MealPlanner/translations";
import RecipeCard from "./RecipeCard";

export default React.memo(SuggestionsTab);

function SuggestionsTab({ state, send }: { state: MealPlanMachineState; send: MealPlanMachineSend }) {
  const { currentDay, currentMeal, currentRecipeIndex, recipeSuggestion } = state.context;
  const tt = React.useContext(MealPlannerTranslationsContext);

  const servings = getServingSizeForRecipeSlot(state.context);

  const handleAddRecipe = (recipeId: string) => {
    send({
      type: "ADD_RECIPE",
      day: currentDay!,
      meal: currentMeal!,
      recipeId,
      servings,
      index: currentRecipeIndex,
    });
  };
  const handleNewSuggestion = () => {
    send({ type: "GENERATE_NEW_SUGGESTION" });
  };

  return (
    <div className="flex flex-col relative">
      {recipeSuggestion &&
        recipeSuggestion!.map((item: MealPlanner_GetRecipeSuggestionQuery_recipeSuggestions, index: number) => (
          <RecipeCard
            state={state}
            send={send}
            key={item.id + index}
            item={item}
            handleClickOnRecipe={() => handleAddRecipe(item.id)}
            history="suggestions"
          />
        ))}
      {state.matches("planning.selectRecipe.suggestions.loading") ? (
        <div className="flex justify-center pt-10">
          <CircularProgress />
        </div>
      ) : (
        <Button onClick={handleNewSuggestion} className="m-auto flex my-2" kind="primary">
          {tt.recipeSelectorDialog.loadMoreButtonTitle}
        </Button>
      )}
    </div>
  );
}
