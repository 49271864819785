import React from "react";
import { SEOReactHelmet } from "../../../../../components/SEO/SEOReactHelmet";
import { useMealPlanMachine } from "../../useMealPlanMachine";
import { getScreen } from "./screens";
import { MealPlannerTranslationsContext, useMealPlannerTranslations } from "./translations";

export const MealPlanner = React.memo(MealPlannerComponent);

function MealPlannerComponent({ slug, edit }: MealPlannerProps) {
  const [state, send] = useMealPlanMachine(slug, edit);
  const tt = useMealPlannerTranslations();
  const Screen = getScreen(state.value);

  return (
    <MealPlannerTranslationsContext.Provider value={tt}>
      <SEOReactHelmet title={tt.pageTitle} noindex />
      <Screen state={state} send={send} />
    </MealPlannerTranslationsContext.Provider>
  );
}

interface MealPlannerProps {
  slug: string;
  edit: boolean;
}
