import React from "react";

export function UnfavoriteIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.0886L10.55 20.7686C5.4 16.0986 2 13.0186 2 9.23865C2 6.15865 4.42 3.73865 7.5 3.73865C9.24 3.73865 10.91 4.54865 12 5.82865C13.09 4.54865 14.76 3.73865 16.5 3.73865C19.58 3.73865 22 6.15865 22 9.23865C22 13.0186 18.6 16.0986 13.45 20.7786L12 22.0886Z"
        fill="#666666"
      />
    </svg>
  );
}
