import React from "react";
import { MealType } from "../../../../../types/graphql-global-types";
import { DayKey } from "../../../../../types/MealPlan";
import { MealPlanner_RecipeWithServings } from "../../../../../types/MealPlanner_RecipeWithServings";
import { getSrc } from "../../../../../utils/image";
import { MealPlanMachineSend, MealPlanMachineState } from "../../types";
import { RecipeMenu } from "./RecipeMenu";
import { ClockIcon } from "../../icons/ClockIcon";
import { MoreIcon } from "../../icons/MoreIcon";
import { IconButton } from "../buttons";
import { MealPlannerTranslationsContext } from "./translations";
import CarbCircle from "../../../../../components/CarbCircle/CarbCircle";
import { getRecipeTime } from "../../../../../utils/recipe";

export const MealPlanRecipe = ({
  recipeWithServings,
  send,
  state,
  meal,
  day,
  index,
}: {
  recipeWithServings: MealPlanner_RecipeWithServings;
  send: MealPlanMachineSend;
  state: MealPlanMachineState;
  meal?: MealType;
  day: DayKey;
  index: number;
}) => {
  const tt = React.useContext(MealPlannerTranslationsContext);
  const { recipe, servings } = recipeWithServings;
  const image = recipe!.images.hz;
  const title = recipe!.title;
  const cookingTime = tt.quantities.timeInMinutes(getRecipeTime(recipe!.time));
  const servingsLabel = tt.quantities.servings(servings);

  const handleClickOnRecipeMenu = () =>
    send({
      type: "TOGGLE_RECIPE_MENU",
      recipe: recipeWithServings,
      meal: meal,
      day: day,
      history: "recipeMenu",
      index: index,
    });

  const handleClickOnRecipeImage = () => {
    send({
      type: "SHOW_RECIPE_PREVIEW",
      recipe: recipeWithServings,
      meal: meal,
      day: day,
      index: index,
    });
  };

  const showRecipeMenu =
    state.matches("planning.viewingRecipeMenu") &&
    state.context.currentDay! === day &&
    state.context.currentMeal === meal &&
    state.context.currentRecipeIndex === index &&
    state.context.currentRecipe!.recipe!.id === recipe!.id;

  const handleClickOnCarbCircle = () =>
    send({ type: "SHOW_RECIPE_CARD_NUTRITIONAL_INFO", recipe: recipeWithServings.recipe! });

  return (
    <div className="grid grid-rows-1 grid-cols-8 w-full lg:border-opacity-20 lg:border lg:border-black rounded-xl h-full">
      <div className="relative col-span-3">
        <CarbCircle onClick={handleClickOnCarbCircle} recipe={recipe!} />

        <picture>
          <img
            style={{ objectFit: "cover" }}
            className="lg:rounded-tr-none lg:rounded-br-none rounded-xl w-full"
            alt=""
            src={getSrc(image, { width: 1200, height: 1200 })}
          />
        </picture>
      </div>

      <div className="flex flex-row justify-between col-span-5 ml-4">
        <div
          data-testid="mealplanner-recipe-day"
          onClick={handleClickOnRecipeImage}
          className="flex flex-col my-auto h-auto cursor-pointer"
        >
          <h3 style={{ fontSize: "18px" }} className="font-medium leading-none m-0">
            {title}
          </h3>
          <div className="flex flex-row">
            <div className="flex items-center">
              <ClockIcon />
              <span className="text-sm ml-1">{cookingTime}</span>
            </div>
            <span className="px-0.5 text-grey">|</span>
            <span data-testid="servings-value" className="text-sm flex items-center ">
              {servingsLabel}
            </span>
          </div>
        </div>
        <div className="relative">
          <IconButton
            style={{ width: "fit-content", height: "fit-content" }}
            kind="default"
            onClick={handleClickOnRecipeMenu}
            className="m-3"
            data-testid="context-menu-recipe"
          >
            <MoreIcon className="bg-lighter-grey rounded-3xl" />
          </IconButton>
          {showRecipeMenu && <RecipeMenu state={state} send={send} />}
        </div>
      </div>
    </div>
  );
};
